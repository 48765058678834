import { useEffect, useState } from 'react'

import { Box, Button, Grid, CircularProgress /*, circularProgressClasses*/ } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import ReactPlayer from 'react-player/lazy'

import poweredByGiphyImage from 'assets/images/giphy/powered-by-giphy.png'
import { t } from 'i18n'
import { giphy } from 'constants/giphy'
import { MediaType } from './MediaPicker'

type Props = {
  media: any
  onError?(): void
  setShowDropzone?(value: boolean): void
  isPreview?: boolean
}

export default function MediaDisplay(props: Props) {
  const { onError, setShowDropzone, isPreview = false } = props

  const { enqueueSnackbar } = useSnackbar()

  const [media, setMedia] = useState<any>(null)
  const [selectedMediaType, setSelectedMediaType] = useState('image')
  const [displayMedia, setDisplayMedia] = useState(false)
  // const [progress, setProgress] = useState(0);

  const onCancel = () => {
    if (onError) onError()
    if (setShowDropzone) setShowDropzone(true)
  }

  useEffect(() => {
    const fetchGif = async () => {
      const { data } = await giphy.gif(props.media?.id)

      setMedia({ ...props.media, src: data?.images?.original?.url })
    }

    if (props.media?.type === MediaType.Gif && props.media?.id) {
      fetchGif()
    } else {
      setMedia({ ...props.media })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.media])

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 1));
  //   }, 500);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [displayMedia]);

  return (
    <Grid container sx={{ height: '100%' }} mb={2}>
      <Grid item xs={12} sx={{ height: '100%' }}>
        {media && (
          [
            <Box width="100%" style={{ textAlign: "center", alignItems: "center" }} display={displayMedia ? 'none' : 'block'} key={"loading-image"}>
              <CircularProgress
                variant="indeterminate"
                sx={{
                  height: "100%",
                  width: "100%",
                  maxHeight: 760,
                  objectFit: "contain",
                  color: () =>
                    "#1EB3AB",
                }}
              />
              {/* <CircularProgress
                variant="determinate"
                sx={{
                  color: (theme) =>
                  theme.palette.grey[300] ,
                }}
                size={40}
                thickness={4}
                {...props}
                value={100}
              />
              <CircularProgress
                variant="determinate"
                disableShrink
                sx={{
                  color: (theme) =>
                    "#1EB3AB",
                  animationDuration: "550ms",
                  position: "relative",
                  left: "-40px",
                  [`& .${circularProgressClasses.circle}`]: {
                    strokeLinecap: "round"
                  }
                }}
                size={40}
                thickness={4}
                {...props}
                value={progress}
              /> */}
            </Box>,
            <Box
              position='relative'
              display={displayMedia ? 'block' : 'none'}
              width={1}
              height={1}
              key={"image"}
            >
              {selectedMediaType === 'image' ? (
                <Box
                  component='img'
                  sx={{
                    display: 'block',
                    height: '100%',
                    width: '100%',
                    maxHeight: 760,
                    objectFit: 'contain',
                    backgroundColor: '#333',
                  }}
                  src={media.src}
                  onLoad={() => setDisplayMedia(true)}
                  onError={() => {
                    setSelectedMediaType('video')
                  }}
                  alt={`${media.name}`}
                />
              ) : (
                <ReactPlayer
                  url={media.src}
                  width='100%'
                  height='100%'
                  style={{
                    aspectRatio: '16/9',
                  }}
                  onReady={() => setDisplayMedia(true)}
                  onError={() => {
                    if (isPreview) {
                      enqueueSnackbar(
                        t('components.mediaDisplay.snackbar.warning'),
                        { variant: 'warning' },
                      )

                      onCancel()
                    }
                  }}
                  controls
                />
              )}
              {isPreview && (
                <Button
                  variant='contained'
                  onClick={onCancel}
                  sx={{
                    position: 'absolute',
                    top: 16,
                    right: 16,
                    backgroundColor: '#fff',
                    color: '#888',
                    borderRadius: '100%',
                    minHeight: '32px !important',
                    minWidth: '32px !important',
                    height: 32,
                    width: 32,
                    ':hover': {
                      backgroundColor: '#d5d5d5',
                    },
                  }}
                >
                  <CloseIcon />
                </Button>
              )}
              {media.type === MediaType.Gif && (
                <Grid container justifyContent='flex-end'>
                  <Box p={1}>
                    <Box
                      component='img'
                      src={poweredByGiphyImage}
                      alt='Powered by GIPHY'
                      sx={{ maxWidth: 120 }}
                    />
                  </Box>
                </Grid>
              )}
            </Box>
          ]
        )}
      </Grid>
    </Grid>
  )
}
