import { useEffect } from 'react'
import { Checkbox, FormControlLabel, Grid, TextField } from '@mui/material'
import { Autocomplete } from '@mui/material'
import { get } from 'lodash'
import { t } from 'i18n'
import { MoneyInput } from 'components';

export default function ManageRewardForm({ formik, suggestions, isSuggestionsLoading, customRewardOption }: any) {

  useEffect(()=>{
    get(formik.values, 'suggestion.id') && formik.setValues({
      ...formik.values,
      name: get(formik.values, 'suggestion.name') && get(formik.values, 'suggestion.name')
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formik.values.suggestion]);
  return <Grid container spacing={2}>
    <Grid item xs={12} sm={6}>
      <Autocomplete
        id='suggestion'
        value={formik.values.suggestion}
        onChange={(event, newRewardSuggestion: any) => {
          formik.setFieldValue('suggestion', newRewardSuggestion)
          formik.setFieldValue('suggestion_id', newRewardSuggestion.id)
        }}
        loading={isSuggestionsLoading}
        loadingText={t('loading')}
        options={suggestions ? [customRewardOption, ...suggestions] : []}
        getOptionLabel={option => option.name}
        disableClearable
        renderInput={params => (
          <TextField
            {...params}
            label={t('reward')}
            variant='outlined'
            data-cy='reward_suggestion'
            onBlur={formik.handleBlur}
            error={
              formik.touched.suggestion &&
              Boolean(formik.errors.suggestion)
            }
            helperText={
              formik.touched.suggestion && formik.errors.suggestion
            }
          />
        )}
      />
    </Grid>

    <Grid item xs={12} sm={6}>
      {formik.values.suggestion &&
        formik.values.suggestion.id === customRewardOption.id && (
          <TextField
            id='name'
            name='name'
            data-cy='name'
            label={t('rewards.fields.rewardName')}
            variant='outlined'
            value={formik.values.name}
            required
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            fullWidth
          />
        )}
    </Grid>

    <Grid item xs={12}>
      <TextField
        id='description'
        name='description'
        data-cy='description'
        label={t('description')}
        variant='outlined'
        value={formik.values.description}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={
          formik.touched.description && Boolean(formik.errors.description)
        }
        helperText={
          formik.touched.description && formik.errors.description
        }
        fullWidth
      />
    </Grid>

    <Grid item xs={12} sm={6}>
      <TextField
        id='amount'
        name='amount'
        data-cy='amount'
        label={t('rewards.fields.monetaryValue')}
        variant='outlined'
        value={formik.values.amount}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.amount && Boolean(formik.errors.amount)}
        fullWidth
        InputProps={{
          inputComponent: MoneyInput,
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Grid>

    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Checkbox
                data-cy='has_inventory'
                checked={formik.values.has_inventory}
                onChange={() => {
                  formik.setFieldValue(
                    'has_inventory',
                    !formik.values.has_inventory,
                  )
                }}
                name='has_inventory'
              />
            }
            label={t('rewards.fields.limitedQuantityReward')}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          {formik.values.has_inventory && (
            <TextField
              type='number'
              InputProps={{ inputProps: { min: 0 } }}
              id='inventory_quantity'
              name='inventory_quantity'
              data-cy='inventory_quantity'
              label={t('rewards.fields.inventoryQuantity')}
              variant='outlined'
              value={formik.values.inventory_quantity}
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.inventory_quantity &&
                Boolean(formik.errors.inventory_quantity)
              }
              helperText={
                formik.touched.inventory_quantity &&
                formik.errors.inventory_quantity
              }
              fullWidth
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  </Grid>
}

