export default {
  onboarding: {
    ratingLabels: {
      notApplicable: 'Not Applicable',
      notAtAllImportant: 'Not at all Important',
      slightlyImportant: 'Slightly Important',
      important: 'Important',
      veryImportant: 'Very Important',
      extremelyImportant: 'Extremely Important',
    },
    header: {
      profile_questions: {
        title: 'You are uniquely special!',
        description:
          'We would love to learn more about the things that make you tick. Below are a few questions to get you started. If you change your mind, you can always update your answers on your personal profile page. Let’s dive in!',
      },
      rating_and_simple_select: {
        title: 'We want to know what makes YOU smile!',
        description:
          'Let’s start with the different forms of recognition that mean the most to you following a job well done. Then we’ll quickly look at your communication preferences with your manager.',
      },
      reward: {
        title: 'Time to reward!',
        description:
          'We’re halfway there. Ready for some fun? Let’s take a look at some of your organization rewards. Which ones are important to you?',
      },
      event: {
        title: 'What makes you feel appreciated at work?',
        description1:
          'Rewards are great, but we know the recognition experience is just as important. Every person is unique in how they feel appreciated. We want to know what that looks like for you using the recognition areas below.',
        description2:
          'To get started, follow the column sequence from left to right and select your preferences. Don’t worry! You can always make edits later if your preferences change. Have fun!',
      },
      choice_group: {
        title: "It's all about style, baby!",
        description:
          'Here’s the final step! As you probably just experienced on the last page, recognition preferences change based on what you are being recognized for in the workplace. However, do you know what your primary appreciation style is? Most don’t. If you don’t know, your manager and colleagues won’t know either. Take this quick assessment to determine what type of appreciation fits you best. Drum roll please...let’s end with a bang and uncover your Sparck Appreciation Style!',
      },
    },
    onboardingProgress: 'Your Onboarding Progress:',
    landing: {
      welcomeAboard: 'Welcome Aboard!',
      paragraph1:
        'Hello, {name}. Welcome to the Sparck onboarding experience! This won’t take long, and the results will lead to some pretty cool stuff on the other end.',
      paragraph2:
        'The purpose of this process is to determine how you - yes, you as an individual - like to be recognized for your amazing accomplishments and hard work. We can’t wait to learn about what’s most important to you in the workplace.',
      paragraph3:
        'It should only take about 5 – 10 minutes (unless you want it to take longer!) Not too bad, right? Just remember, you need to finish the survey in one go. But don’t worry, you can always change your answers later on.',
      paragraph4:
        'Thank you so much for taking the time. We can’t wait to hear what makes you smile!',
    },
    pleaseMakeSelection: 'Please make a selection...',
    recognitionColumns: {
      recognitionAreas: 'Recognition Areas',
      recognitionMeaningful: 'Would recognition be meaningful?',
      publicSetting: 'In a public setting?',
      byWho: 'By who?',
      how: 'How?',
    },
    pagination: {
      previous: 'Previous',
      imReady: "I'm ready!",
      backToProfile: 'Back to Profile',
      finish: 'Finish',
      next: 'Next',
    },
    finish: {
      congratulations: 'CONGRATULATIONS!',
      completedOnboarding: "You've completed the onboarding process!",
      thankYou: 'Thank you so much for taking the time. We hope you had fun!',
      revealRecognitionStyle:
        "Now we'll take you to your profile page to reveal your Sparck Appreciation Style!",
      takeMeToMyDashboard: 'Take Me To My Dashboard!',
    },
  },
  feed: {
    commentManagement: {
      edit: 'Editar',
      save: 'Guardar',
      delete: 'Eliminar',
    },
    tabLabels: { post: 'Post', recognize: 'Recognize!' },
    messagePlaceholder: 'Write your message here...',
    postButtonText: 'Post',
    like: 'Like',
    likes: 'Likes',
    comment: 'Comment',
    comments: 'Comments',
    commentPlaceholder: 'Write your comment here...',
    hasBeen: 'ha sido',
    haveBeen: 'han sido',
    recognized: 'Reconocido(a)!',
    multipleUsersRecognized: 'Reconocidos(as)',
    reportPost: 'Report this post',
    suggestion: {
      placeholder: 'Type your suggestion here...',
      reviewMessage:
        'This suggestion will be sent to the organization administrator for review.',
      makePrivate: 'Make this suggestion anonymous',
      submitIdeas: 'Submit your ideas',
    },
    feedValueLabels: {
      purpose: 'Our Purpose',
      values: 'Our Core Values',
      recognitionAreas: 'Our Recognition Areas',
      suggestionBox: 'Suggestion Box',
    },
    notDefined: 'This organization has no {value} defined yet.',
    loadAllPosts: 'Load All Posts',
    endOfPosts: "That's impressive! You've reached the end",
    removePost: 'Remove Post',
    thisPost: 'this post',
    commentInfo: { updated: 'Updated' },
    noPermissionToViewPosts: "You don't have permission to view posts.",
    noPosts:
      'There are no posts yet. Be the first person to recognize someone!',
    snackbar: {
      success: {
        suggestionSubmitted: 'Your suggestion was successfully submitted!',
      },
    },
  },
  giphy: {
    placeholder: 'What do you want to search?',
    dialog: { title: 'Selecciona tu Giphy!' },
  },
  profile: {
    tabs: {
      labels: {
        about: 'Sobre {userName}',
        personal: 'Personal',
        professional: 'Professional',
        myRewards: 'Recompensas',
        me: 'Mí',
      },
    },
    profileItemLabels: {
      position: 'Position',
      generalSettings: 'General Settings',
      visibilitySettings: 'Visibility Settings',
      personalInterests: 'Personal Interests',
      hireDate: 'Hire Date',
      location: 'Location',
      reportsTo: 'Reports To',
      professionalInterests: 'Professional Interests',
      dateOfBirth: 'Date of Birth',
      positionPlaceholder: 'e.g. Senior Engineer',
      preferredPronouns: 'Preferred Pronouns',
      preferredPronounsPlaceholder: 'e.g. He/Him',
      howShouldRefer: 'How should we refer to you?',
      customPreferredPronouns: 'Custom Preferred Pronouns',
      preferredNotification: 'Notification Delivery Preference',
      sms: 'SMS',
      all: 'Both (Email/SMS)',
      mfa: 'Multi-Factor Authentication',
    },
    pronouns: { he: 'He/Him', she: 'She/Her', they: 'They/Them' },
    profileVisibilityLabels: {
      everyone: 'Everyone',
      managersOnly: 'Managers Only',
    },
    profileStrength: 'Profile Strength',
    impact: {
      myImpact: 'Mi Impacto',
      userImpact: 'El Impacto de {userName}',
      totalRecognitions: { total: 'Total', recognitions: 'Recognitions' },
      progressBarLegend: { sent: 'Sent', received: 'Received' },
    },
    profileRecognitionStyle: {
      title: 'Estilo de Apreciación Sparck',
      takeSurvey: 'Tomar Encuesta',
      showMe: {
        title: 'Show Me',
        descriptionSeeLess:
          'You appreciate it when your hard work is acknowledged with a meaningful, thoughtful gift or organization reward that aligns with your hobbies, interests, or goals. For you, it’s important to know that the work you do brings value to your employer and colleagues.',
        descriptionSeeMore:
          'You find joy in knowing your dedication and commitment is being recognized.  It goes beyond just your work, though.  You appreciate when people take the time to get to know you as a person and not just an employee. It means more to you to receive a gift reflecting your personal interests after achieving a quarterly goal than hearing verbal recognition. After a late night working on a project, you’d love to have your colleague or manager surprise you with your favorite beverage as a “pick me up”. It makes you feel valued for your efforts. This doesn’t mean you want gifts piled up at your desk.  It’s the genuine gifts that reflect your career goals or compliment your personal hobbies that make you feel best. It means your colleagues and manager have taken the time to get to know you personally',
      },
      celebrateWithMe: {
        title: 'Celebrate With Me',
        descriptionSeeLess:
          'Gifts, although we all love them, may mean less to you than the environment you work in.  You enjoy working with people who are caring and compassionate, who enjoy showing their appreciation in a way that you may see on a sports team.',
        descriptionSeeMore:
          'You love to give and receive virtual fist bumps and high fives while social distancing for a job well done! When greeted in the morning, an elbow tap “hello” goes a long way rather than a quick greeting in the hallway when passing by. When you hit a large goal individually or as a team, you find meaningful connections with your colleagues when they take the time to post zoom high-five icons all around to celebrate the success. You know that you are making an impact when your manager gives you a thumbs up for your accomplishments.  This makes you feel seen and you know your efforts are making a difference.',
      },
      connectWithMe: {
        title: 'Connect With Me',
        descriptionSeeLess:
          'You’re a “people person” to your core. Relationships matter to you! Working with others makes you happy, and you find tremendous satisfaction when bouncing ideas off others.  You find that your enjoyment at work is elevated when you build meaningful connections in the workplace and have a social outlet.',
        descriptionSeeMore:
          "It isn’t just about having people around.  Sure you may be social but it comes down to the quality of the time that you spend together. Someone present and in the moment during your conversations, instead of  looking at email or their phone, tells you that they genuinely care. During meetings with your manager you know that you are an important part of the team when they understand what's important to you, are actively listening, and give you one-on-one time. The best way to reward your hard work is to be a part of a team project and collaborate with your peers. And of course, all work and no play is boring - a celebratory team outing or team building activity are the events that create an organization culture that brings you the greatest happiness.",
      },
      tellMe: {
        title: 'Tell Me',
        descriptionSeeLess:
          'You’re someone who values words of appreciation in the workplace. Praise is important to you. You like to know that your efforts and your accomplishments have been recognized. Hearing a sincere “thank you” or “well done!” means more to you than a reward or high-five.',
        descriptionSeeMore:
          'These affirmations from management and your peers prove to you that your hard work and time is making a difference and contributing to the purpose of the organization. It isn’t just about words, but the sincerity behind the words. It is most meaningful to you when you understand the “why” behind the recognition, as this shows they really know you and understand the time and commitment you have put in. After completing a big project or going above and beyond for an important organization initiative, it means the most when a peer or your manager stops by your desk or sends an email to thank you personally. When you’re recognized unexpectedly, it reinforces you are in a workplace that truly values you. Meaningful words in a variety of forms from people throughout the organization equate to an organization culture that shows you are appreciated and a valuable part of the team!',
      },
      supportMe: {
        title: 'Support Me',
        descriptionSeeLess:
          'You are a team player and know that you can accomplish more together than alone. That doesn’t necessarily mean that you are more drawn to collaborative projects, but working alongside colleagues that are willing to lend a helping hand is important to you. You feel valued when others offer their support when you have a heavy workload, tight deadlines to meet, or are falling behind.',
        descriptionSeeMore:
          'This shows you that they appreciate you as more than just an employee but as a person - they care about you and what you need to be successful.  A work environment that not only recognizes accomplishments but provides the support to hit those accomplishments is one that you value most. When people go above and beyond their daily tasks to jump on a project with you or just check in on you during a stressful time you know your contributions matter. For you, it goes beyond the workplace.  You appreciate it when people notice that you may be having a rough day or dealing with a personal matter outside of work and offer help, whether that be taking a bit of your workload off your hands or simply being there to talk. These acts of kindness show you that you are appreciated and a valuable part of the team!',
      },
      trustMe: {
        title: 'Trust Me',
        descriptionSeeLess:
          'You are a natural-born leader and thrive when you are trusted with increased responsibilities. While some may enjoy the comfort of their daily tasks, you really hit your stride when allowed to spearhead new initiatives. You feel most appreciated when those around you notice your hard work and reward you with a chance to showcase more of your strengths. You consider it a great honor to have others trust you with high profile projects and much of your workplace confidence stems from being given these opportunities.',
        descriptionSeeMore:
          'It’s important to note that your personal drivers are geared toward growth opportunities, so the adage of tasks without the increased responsibility is not what you are looking for. You want to sharpen your skills and expand your abilities so an investment in your personal and professional development is always a treat! Nothing makes you happier at work than knowing people trust you to lead important strategies for the organization. An environment that allows you to stretch your wings is ideal. These experiences show you that you are appreciated and a valuable part of the team.',
      },
      undefined: {
        title: '¿Cuál es tu Estilo de Reconocimiento?',
        descriptionSeeLess:
          '¡Queremos conocerte mejor! Realice nuestra encuesta y compruebe su estilo de reconocimiento.',
        noStyleYet: '{userName} aún no tiene un Estilo de Reconocimiento.',
      },
      seeOnboarding: 'See Onboarding',
      editPreferences: 'Edit Preferences',
      seeLess: 'Read Less',
      seeMore: 'Read More',
      seeAllMyStyles: 'See all my styles',
    },
    recognitionMemories: {
      title: 'Memorias de Reconocimiento',
      titleSingleMemory: 'Memoria de Reconocimiento',
      noRecognitionMemories: {
        youDontHave: 'Aún no tienes {title}',
        userDoesntHave: '{userName} aún no tiene {title}',
      },
      received: 'Received',
      sent: 'Sent',
      viewAll: 'View All',
      viewGivenUserRecognitions: 'Ver los Reconocimientos de {userName}',
    },
    changeProfilePicture: {
      dialogTitle: 'Change your profile picture!',
      dragAndDrop:
        'Drag and drop your beautiful profile picture or just click here!',
      selectPicture: 'Select your profile picture',
      savePicture: 'Save Profile Picture',
    },
    snackbar: {
      success: {
        informationUpdated: 'Your information was updated successfully!',
        profilePictureUpdated: 'Your profile picture was successfully updated!',
      },
      error: { userNotFound: 'User not found' },
    },
  },
  sparcks: {
    sparckMemories: {
      header: {
        firstLine: "In case you've forgotten...",
        secondLine: "You're amazing!",
      },
      card: { privateSparck: 'This is a private Sparck' },
      viewSparck: 'View Sparck',
      sent: 'Enviados',
      received: 'Recibidos',
      noSparckMemories: 'Todavía no hay recognitions.',
      onlyPublicSparcksMessage:
        "You're only being shown the public recognitions.",
      public: 'Públicos',
      private: 'Privados',
    },
    sparck: {
      header: '¿A quién vamos a reconocer hoy?',
      selectPeopleToRecognize: 'Select the people you want to recognize below',
      viewAll: 'View All',
      usersToRecognize: 'Users to Recognize',
      formLabels: {
        name: 'Quiero Reconocer...',
        event: '¿Qué estamos celebrando?',
      },
      advancedSearchOptions: 'Opciones de Búsqueda Avanzada',
      selectUsersFirst: 'Seleccionar usuarios para reconocer primero',
      noRecognitionEventFound:
        'No se encontró ningún evento de reconocimiento para los usuarios seleccionados',
      advancedSearchDialogTitle: 'Búsqueda Avanzada',
      makeMeaningfull: '¿Qué hará que esto sea más significativo para',
      personalize: 'Personaliza Tu Reconocimiento',
      needSomeIdeas: '¿Necesita Algunas Ideas?',
      sparckMessagePlaceholder: 'Escribe un mensaje a {userName}...',
      sparckMentionWarning: {
        title: 'Privacy concern, be careful!',
        description:
          'You are @ mentioning someone in a private message. Doing this will give the @ mentioned person the ability to read the private message.  If you DO NOT want them to view the message, please remove the @ mentioned person from the message.',
        actionLabel: 'Got it!',
      },
      selectedUsers: 'selected users',
      andMore: '{users} y {selectedCount} más...',
      makePrivate: 'Hacer Esto Privado',
      preview: 'Vista Previa',
      send: 'Enviar',
      prefers: 'prefiere ser reconocido(a)',
      prefersNot: { prefers: 'prefiere', notToBe: 'no ser reconocido' },
      inPublic: 'en público',
      inPrivate: 'en privado',
      by: 'por',
      in: 'en un',
      noPreferences: 'no ha especificado preferencias para',
      include: 'Incluir',
      rewardOptions: {
        reward: 'Recompensa de la Organización',
        rewardNGC: 'Recompensa Minorista',
      },
      selectedReward: 'Selected Reward',
      chooseAnother: 'Choose Another',
      selectedRecognitionEventAutomaticBankWarning:
        "The Recognition Event selected has a reward amount that will be automatically banked to the recipient's Reward Bank.",
      giftCardRewardDetails: 'Gift Card Reward Details',
      virginExperiencesRewardDetails: 'Virgin Experiences Reward Details',
      selectOption: 'Select a',
      rewardFilterLabels: { all: 'All' },
      noRewards: 'There are no',
      sparckIdeas: {
        header: 'Here are some Sparck Ideas!',
        fieldLabel: 'Message Category',
      },
      sparckRewardsListFilterInfo:
        'Los resultados se filtran según el máximo importe del evento de reconocimiento seleccionado: {maxAmount}',
      allRewards: 'All Rewards',
      favoritesOnly: 'Favorites Only',
      rewardsSearch: 'Rewards Search',
      searchForAReward: 'Search for a reward...',
      noRewardsFound: 'No rewards found.',
      sparckConfirmGiftCardRewardDetails: {
        selectGiftCardAmount: 'Select a Gift Card amount',
        recommendedGiftCardAmount: 'Recommended Gift Card amount',
        selectADifferentValueAmount: 'or select a different value amount',
      },
      sparckConfirmVirginExperiencesrewardDetails: {
        chooseARewardOptionBellow: 'Choose a reward option below:',
        confirmSelectedReward: 'Confirm selected reward',
      },
    },
    sparckDisplayAnswer: {
      yourResponseWas: 'Your response was:',
      clickAnyMessage: 'Click on any message below to send a quick answer',
      thankYou: 'Thank you!',
      youAreTooKind: "You're too kind!",
      wow: 'Wow!',
      typeCustomResponse: 'Or type a custom response',
      placeholder: 'Thank you so much for your recognition!',
      sendYourAnswer: 'Send your answer!',
    },
    sparckDisplayComponent: {
      goBack: 'Go back',
      noAuthorizationToViewSparck:
        'You do not have authorization to view this Sparck.',
      returnToDashboard: 'Return to Dashboard',
      youHave: 'Usted',
      has: 'ha',
      beenRecognized: 'sido reconocido!',
      weAreSoHappy: 'Nosotros estamos tan felices',
      forYou: 'para ti',
      congratulations: 'Felicidades!',
      private: 'privado',
      messageFrom: 'Un mensaje {private} de',
      revealYourGift: 'Click below and reveal your gift!',
    },
    snackbar: {
      success: {
        youSparcked: 'Sparck sent successfully!',
        responseSent: 'Your response was sent successfully!',
      },
    },
  },
  people: {
    connect: 'Connect with your Colleagues',
    searchFieldPlaceholder:
      'Search for names, emails, departments, business units and so on...',
    favoritesLabel: 'My Favorites only',
    tooltip: { add: 'Add to favorites', remove: 'Remove from favorites' },
    viewProfile: 'View profile',
    search: 'Search',
    infoButtons: { recognize: 'Recognize' },
  },
  language:{
    chooselanguage:'Elige tu idioma'
  },
  insights: {
    company: {
      title: 'Organization Insights',
      link: 'Go to Team Insights',
      recognitionActivity: 'Our Recognition Activity',
      recognitionByProgram: 'Reconocimiento por Evento',
      pulseSurveys: 'Pulse Surveys',
      engagementScore: 'Engagement Score',
      engagementDrivers: 'Engagement Drivers',
      topFiveAreas: 'Top 5 Areas',
    },
    team: {
      title: 'Team Insights',
      link: 'Go to Organization Insights',
      recognitionByProgram: 'Reconocimiento por Evento',
      engagementScore: 'Engagement Score',
      engagementDrivers: 'Engagement Drivers',
      topFiveAreas: 'Top 5 Areas',
      rewardBudget: "My Team's Reward Budget",
      recognition: 'Team Recognition',
      pulseSurveyResults: 'Pulse Survey Results',
      rewardBudgetProgressLabels: { spent: 'Spent', total: 'Total' },
      sparckTable: {
        titles: {
          myRecognitionReport: 'My Recognition Activity',
          myTeamRecognitionReport: "My Team's Recognition Activity",
        },
        picture: 'Picture',
        recognitionsSent: 'Recognitions Sent',
        rewardsSent: 'Rewards Sent',
        lastRecognitionSent: 'Last Recognition Sent',
        never: 'Nunca',
        action: 'Acción',
        recognizeNow: 'Recognize Now',
        recognitionsReceived: 'Recognitions Received',
        recognitionReadiness: 'Recognition Readiness',
      },
    },
    filters: {
      labels: {
        period: 'Period',
        beheardSurvey: '#BeHeardSurvey',
        pulseSurveys: 'Pulse Surveys',
        managers: 'Managers',
        loadingText: 'Loading...',
      },
    },
    noData: 'No data to display.',
    managerSelectionError:
      'Make sure you selected a Manager in the filters above.',
    sparcksProgressLabels: { sent: 'Sent', goal: 'Goal' },
  },
  periodLabels: {
    allTime: 'All time',
    thisYear: 'This year',
    thisQuarter: 'This Quarter',
    thisMonth: 'This Month',
  },
  coreValues: {
    title: 'Core Values',
    headerDescription:
      'These are the key behaviors that will bring your culture to life! Learn more {link}.',
    descriptionCreateMode:
      'Let’s add a core value to your main newsfeed! Get started below. Check out an example {link}',
    example: 'Example',
    validations: { descriptionIsRequired: 'Description is required' },
  },
  externalRewardsUpload: {
    title: 'Cargue su Información de Recompensa Externa',
    description:
      'Las recompensas externas son recompensas proporcionadas a los empleados fuera del sistema Spark. Si desea realizar un seguimiento de sus recompensas externas como parte del presupuesto de Sparck, puede usar nuestra plantilla de Excel para cargar sus recompensas externas. La plantilla se genera dinámicamente específicamente para su organización e incluye sus eventos de reconocimiento específicos.',
    selectAppropriateEvents:
      'Asegúrese de seleccionar los eventos de reconocimiento apropiados para que podamos realizar un seguimiento de su presupuesto con precisión.',
    downloadExcelTemplate: 'Descargar plantilla de Excel',
  },
  inappropriateContent: {
    title: 'Inappropriate Content',
    description:
      'Below you will find all reported instances of inappropriate content and reported by your employees and different action you can take on each one.',
    contentAccepted: 'This content was accepted!',
    contentRemoved: 'This content was removed!',
    contentPrivate: 'This content is now private!',
    reporter: 'Reporter',
    contentCreator: 'Content Creator',
    hasInappropriateText: 'Has Inappropriate Text',
    hasInappropriateImage: 'Has Inappropriate Image',
    hasInappropriateVideo: 'Has Inappropriate Video',
    createdAt: 'Created At',
    goToPage: 'Go to Page',
    allowContent: 'Allow Content',
    removeContent: 'Remove Content',
    makeContentPrivate: 'Make Content Private (only for Sparcks)',
    thisContent: 'this content',
  },
  onboardingQuestions: {
    types: {
      choiceGroup: 'Choice Group',
      event: 'Event',
      rating: 'Rating',
      simpleSelect: 'Simple Select',
      multipleSelect: 'Multiple Select',
    },
    title: 'Onboarding Questions',
    description:
      'Your employees just entered Sparck. These are the questions they will see first in our Onboarding section!',
    questionStatement: 'Question Statement',
    type: 'Type of Question',
    displayToAllCompanies: 'Display this question to all organizations',
    alternatives: 'Alternatives',
    alternative: 'Alternative',
    actions: 'Actions',
    validations: {
      statementIsRequired: 'Question statement is required',
      typeIsRequired: 'Type of question is required',
    },
  },
  rewards: {
    title: 'Rewards',
    description:
      'A variety of reward types based on your unique culture. Get creative and have some fun!',
    fields: {
      rewardName: 'Reward Name',
      monetaryValue: 'Monetary Value',
      limitedQuantityReward: 'There is a limited quantity for this reward',
      inventoryQuantity: 'Inventory Quantity',
    },
    manageReward: {
      validations: {
        suggestionRequired: 'Suggestion is required',
        rewardNameRequired: 'Reward Name is required',
        monetaryValueRequired: 'Monetary Value is required',
        minimumInventoryQuantity:
          'Inventory Quantity must be greater than zero',
        maximumInventoryQuantity: 'Inventory Quantity must be lesser than 9999',
        inventoryQuantityRequired: 'Inventory Quantity is required',
      },
    },
  },
  profileQuestions: {
    title: 'Profile Questions',
    description:
      'They are personal and professional questions to include in your employees’ profiles to uncover how each person ticks.',
    questionsSelectedFromLibrary: 'Questions selected from library!',
    selectFromLibrary: 'Select from Questions Library',
    questions: 'Questions',
    fields: {
      statement: 'Statement',
      personal: 'Personal',
      professional: 'Professional',
    },
    validations: {
      statementRequired: 'Statement is required',
      questionTypeRequired: 'Question Type is required',
    },
  },
  recognitions: {
    title: 'Eventos de Reconocimiento',
    description:
      'Es un logro y/o comportamiento que desea reforzar y reconocer para elevar el desempeño y su cultura única.',
    types: {
      customRecognitionProgram: 'Evento de Reconocimiento Personalizado',
      workiversary: 'Workiversary',
      lifeEvent: 'Life Event',
      coreValues: 'Core Values',
      peerToPeer: 'Peer-to-Peer',
      spotBonus: 'Spot Bonus',
      peerNominatedAward: 'Peer Nominated Award',
      managerNominatedAward: 'Manager Nominated Award',
      employeeOfTheMonth: 'Employee of the Month',
      employeeOfTheYear: 'Employee of the Year',
    },
    frequencies: {
      monthly: 'Monthly',
      quarterly: 'Quarterly',
      semiAnnually: 'Semi-Annually',
      annually: 'Annually',
      lifetime: 'Lifetime',
    },
    approved: {
      directManager: 'Direct Manager',
      companyAdministrator: 'Organization Administrator',
    },
    milestones: {
      everyYear: 'Every Year',
      fixed: 'Fixed',
      customMilestone: 'Custom Milestone',
    },
    groups: {
      peers: 'Peers',
      manager: 'Manager',
      executiveTeam: 'Executive Team',
      ceo: 'CEO',
    },
    settings: {
      aPersonalNote: 'A Personal Note',
      aPersonalPhoneCall: 'A Personal Phone Call',
      aPersonalText: 'A Personal Text',
      inPerson: 'In Person',
      staffMeeting: 'Staff Meeting',
      companyEvent: 'Organization Event',
      groupEmail: 'Group Email',
      companyDashboard: 'Organization Dashboard',
      socialMediaShoutout: 'Social media shoutout',
    },
    attributes: {
      workiversaryYear: 'Workiversary Year',
      lifeEvent: 'Life Event',
      coreValue: 'Core Value',
    },
    icons: {
      anniversary: 'Anniversary',
      teamWork: 'Team Work',
      innovation: 'Innovation',
      strategic: 'Strategic',
      mentorTeach: 'Mentor/Teach',
      training: 'Training',
      creative: 'Creative',
      newHome: 'New Home',
      havingABaby: 'Having a Baby',
      graduation: 'Graduation',
      retirement: 'Retirement',
      technicalDevelopment: 'Technical Development',
      exceededGoals: 'Exceeded Goals',
      fun: 'Fun',
      celebration: 'Celebration',
      bugFix: 'Bug Fix',
      newFamilyMember: 'New Family Member',
    },
    welcome: {
      title: '¡Bienvenido(a)! ¿Necesita la ayuda de nuestro asistente?',
      description:
        'Está aquí porque desea crear la experiencia perfecta para sus empleados con un evento de reconocimiento personalizado. Ofrecemos una gran cantidad de configuraciones básicas y avanzadas. ¿Quieres que te guiemos en este proceso?',
      dontAskAgain: 'No me preguntes de nuevo',
      noThanks: 'No, gracias. Yo me ocupo.',
      showMeTheRopes: '¡Sí, muéstrame, por favor!',
    },
    fields: {
      program: 'Evento',
      programIcon: 'Icono del Evento',
      programIconPlaceholder: 'Mi Reconocimiento Muy Especial',
      setCustomTimeframe: 'Establecer un Marco de Tiempo Personalizado',
      setPeriod: 'Establecer período',
      startDate: 'Fecha de Inicio',
      endDate: 'Fecha Final',
      period: 'Período',
      howManyTimesPerPeriod: '¿Cuántas veces por periodo?',
      tooltips: {
        recurrenceLimit:
          'This is the maximum number of times this recognition event can be used per employee in the selected period.',
        recurrenceLimitPerEmployee:
          'What is the limit that a same employee can receive this recognition event?',
        maximumRewardAmountPerRecognition:
          'If there are multiple reward amounts, include the maximum budget per reward so you can reward up to that dollar amount.',
      },
      limitPerPeriodCheckbox:
        'Limite el número de empleados que pueden ser reconocidos en un solo período',
      limitPerEmployee: 'Límite de empleados por período',
      allowRewards: 'Permitir Recompensas para este Evento de Reconocimiento',
      allowOrganizationalRewards: 'Allow Organizational Rewards',
      allowRetailerRewards: 'Allow Retailer Rewards',
      canAcceptOnly: 'Can Accept Only',
      canAcceptToBankOnly: 'Can Accept to Bank Only',
      canAcceptAndBank: 'Can Accept and Bank',
      canAcceptOnlyTooltip:
        'Recipient of reward can only accept the reward. They can’t save the value of the reward to their Sparck bank.',
      canAcceptToBankOnlyTooltip:
        'Recipient of reward can only add the value of the reward to their Sparck bank.',
      canAcceptAndBankTooltip:
        'Recipient of reward can either accept the reward or add the value of the reward to their Sparck bank.',
      budgetPerReward: 'Presupuesto por Recompensa',
      setMilestoneBonus:
        'Establecer Bonificación por Hito (por ocurrencia de evento)',
      setMilestoneBonusTooltip:
        'Asegúrese de haber seleccionado la disponibilidad Anual para desbloquear la configuración de Hito',
      milestoneBonusValue: 'Valor de Bonificación por Hito',
      howManyEmployeesEligible:
        '¿Cuántos empleados son elegibles para ser reconocidos (si no es ilimitado)?',
      wayToGoTeam: '¡Bien hecho, equipo!',
      extraMile: 'Cuando nuestro equipo hace un esfuerzo adicional',
      whoCanRecognize: 'Quien puede Reconocer',
      whoCanBeRecognized: 'Quien puede ser Reconocido',
      hasReward: 'Offers Reward',
      rewardAmount: 'Reward Amount',
      timeframe: 'Timeframe',
      hasRequiresApprovalBy: 'Requires Approval?',
      requiresApprovalBy: 'Approval From',
      allowGroupRecognition: 'Allow Group Recognition',
      whoIsThisEventFor: 'Who is this Event for?',
      whichRolesCanSend:
        '¿Qué roles pueden enviar reconocimientos para este evento?',
      whichDemographicsCanReceive:
        '¿Qué grupos demográficos pueden recibir reconocimientos para este evento?',
    },
    groupRecognitionsBudgetImpact:
      "Group recognitions will not impact an individual recipient's budget.",
    groupRecognitionsBudgetImpactTooltip:
      "Group recognitions will not impact an individual recipient's budget. So if a recipient has $10 remaining in their budget for the period and receive a $50 group reward, they will still have $10 remaining in their individual budget for the period.",
    rewardConfigurations: 'CONFIGURACIONES DE RECOMPENSAS',
    budgetImpact: 'IMPACTO PRESUPUESTARIO',
    budgetPerReward: 'Presupuesto por recompensa',
    frequency: 'Frecuencia',
    progressBarLabels: {
      used: 'Usado',
      event: 'Evento',
      remaining: 'Restante',
    },
    employeesTargeted: 'Público Objetivo',
    total: 'TOTAL',
    year: 'año',
    tabLabels: {
      general: 'General',
      advanced: 'Avanzado',
      tooltip: 'Hay campos obligatorios para esta sección',
    },
    formSteps: {
      general: {
        title: 'Información General',
        description:
          'Establezca el nombre de su evento de reconocimiento, el ícono con el que desea asociarse (visible en publicaciones de reconocimiento en Feed, por ejemplo) y una descripción para ilustrar mejor por qué este evento de reconocimiento es tan increíble.',
      },
      whoCanRecognize: {
        title: '¿Quién puede enviar Reconocimiento por este Evento?',
        description:
          'Seleccione los datos demográficos y los roles que desea poder enviar reconocimientos para este evento. Todos podrán ver este Evento de Reconocimiento en el Feed, pero solo los grupos demográficos seleccionados y los roles seleccionados podrán enviar reconocimientos para este Evento de Reconocimiento.',
      },
      whoCanReward: {
        title: 'Who can Reward for this Event?',
        description:
          'Select the Demographics and Roles you want to be able to reward for this event. Everyone selected that can recognize for this event will also be allowed, by default, to offer rewards.',
      },
      whoCanBeRecognized: {
        title: '¿Quién puede recibir Reconocimiento por este Evento?',
        description:
          'Seleccione los datos demográficos y los roles que desea que puedan recibir reconocimientos para este evento. Todos podrán ver este Evento de Reconocimiento en el Feed, pero solo los grupos demográficos seleccionados y los roles seleccionados podrán recibir reconocimientos por este Evento de Reconocimiento.',
      },
      timeframe: {
        title: 'Periodo de tiempo',
        description:
          '¿Es este un evento de reconocimiento fijo o tiene una vida útil? Aquí es posible establecer un tiempo limitado para que exista este evento de reconocimiento.',
      },
      availability: {
        title: 'Disponibilidad',
        description:
          '¿Cuántas veces debe estar disponible este evento de reconocimiento por cierto período? 10 veces cada mes? 4 veces trimestrales? ¿Una vez al año?',
      },
      nomination: {
        title: 'Nominations',
        description:
          'This screen allows you to set whether an employee should be nominated for this Recognition Event and what the nomination period will be.',
      },
      rewards: {
        title: 'Recompensas',
        description: 'Detalles de las recompensas',
      },
      milestones: { title: 'Hitos', description: 'Detalles de Hitos' },
      approval: {
        title: 'Flujo de Aprobación',
        description: 'Detalles del Flujo de Aprobación',
      },
    },
    next: 'Siguiente',
    back: 'Back',
    finish: 'Finish',
    milestonesTable: {
      columns: {
        yearNumber: 'Número del Año',
        rewardAmount: 'Monto de la Recompensa',
      },
    },
    validations: {
      iconIsRequired: 'El ícono es requerido',
      startDateIsRequired: 'La Fecha de Inicio es obligatoria',
      minimumEndDate:
        'La Fecha de Finalización no debe ser anterior a la Fecha de Fnicio',
      recurrenceIsRequired: 'Se requiere recurrencia',
      limitIsRequired: 'Se requiere límite',
      endDateIsRequired: 'La Fecha de Finalización es obligatoria',
      budgetPerRewardIsRequired: 'Se requiere Presupuesto por Recompensa',
    },
    manageRecognitionsTitle: 'Recognition Events',
    whatSpecialEvent:
      '¿Qué Evento de Reconocimiento especial desea llevar a su organización?',
    recognitionEvents: 'Eventos de Reconocimiento',
  },
  suggestions: {
    title: 'Suggestions',
    description:
      'Here are the opinions and contributions of your employees for your organization.',
    fields: {
      date: 'Date',
      from: 'From',
      suggestion: 'Suggestion',
      haveYouReplied: 'Have you replied?',
    },
    settings: { reviewed: 'Reviewed', implemented: 'Implemented' },
    manageSuggestion: 'Manage Suggestion',
    replyWasSent: 'Your reply was sent!',
    accept: 'Accept',
    decline: 'Decline',
    confirm: 'Confirm',
    suggestion: 'Suggestion',
    answer: 'Answer',
    typeReplyForSuggestion: 'Type your reply for this suggestion',
    sendReply: 'Send reply',
    moveForwardWithSuggestion:
      'Are you going to move forward with this suggestion?',
    areYouSure: 'Are you sure?',
    whereSuggestionChatBegins:
      'This is where this suggestion chat begins. The first message below is the suggestion.',
    typeYourReplyHere: 'Type your reply here...',
  },
  takeAction: {
    title: 'Take Action',
    description:
      "It's time to personalize your recognition! These people were recognized yet it would mean the most to hear from you. View recognition details and how to take action below.",
    filters: { open: 'Open', complete: 'Complete' },
    setAReminder: 'Employee Recognition Reminder',
    setReminder: 'Set a Reminder',
    fields: {
      whoWasRecognized: 'Who Was Recognized',
      byWhom: 'By Whom',
      recognitionEvent: 'Recognition Event',
      details: 'Details',
      viewMessage: 'View Message',
      recognizeThemThisWay: 'Recognize Them This Way',
      reminderDate: 'Reminder Date',
    },
    recognize: 'Recognize!',
    addReminder: 'Add Reminder',
    postComment: 'Post a Comment',
    markAsComplete: 'Mark as Complete',
    delete: 'Delete',
    snackbar: {
      success: {
        actionCompleted: 'This action was completed!',
        actionDisregarded: 'This action was disregarded',
      },
    },
  },
  budgets: {
    companyRewardBudgetBreakdown: {
      budgetBreakdownFilters: {
        businessUnit: 'Business Unit',
        employee: 'Employee',
        region: 'Region',
        territory: 'Territory',
      },
    },
  },
  budget: {
    budgetReports: {
      rewardsReport: {
        title: 'Informe de Recompensas',
        description:
          'Este informe proporciona una lista de todas las recompensas otorgadas a los empleados desde el primer día del año, el monto de la recompensa (valor) y de quién provienen. Haga clic en el botón Descargar informe a continuación para descargar una versión de Excel de este informe.',
      },
      budgetActivityReport: {
        title: 'Informe de Actividad Presupuestaria',
        description:
          'Este informe identifica el monto del presupuesto gastado y el monto restante para el período actual para cada empleado de la organización. Haga clic en el botón Descargar informe a continuación para descargar una versión de Excel de este informe.',
      },
      downloadReport: 'Descargar Informe',
    },
    budgetDetails: {
      tabLabels: {
        general: 'General',
        externalRewardsUpload: 'Carga de Recompensas Externas',
        reports: 'Informes',
      },
    },
    budgetDetailsBody: {
      budgetSettings: 'Ajustes de Presupuesto de {limitType}',
      labels: {
        distributionType: 'Tipo de Distribución',
        proRate: 'Prorrateo',
        rolloverPerPeriod: 'Transferencia por Período',
        rolloverAnnually: 'Transferencia del Presupuesto Anual',
        amountForEachEmployee: 'Cantidad por cada Empleado',
      },
      breakdownBy: 'Desglose por',
      noSettings:
        'Actualmente no hay configuraciones para este tipo de distribución.',
    },
    budgetDetailsHeader: {
      budgetSettings: 'Ajustes de Presupuesto',
      yearTypeHeaderCard: {
        title: 'Tipo de Calendario de Presupuesto',
        description:
          'Puede configurar el Calendario de Presupuesto en un calendario tradicional (Enero - Diciembre) o un calendario de Año Fiscal donde establece la fecha de inicio de su Año Fiscal',
      },
      periodHeaderCard: {
        title: 'Período',
        description:
          'Su presupuesto se reiniciará al comienzo de cada período.',
        period: 'Período',
      },
      budgetForNewEmployeesHeaderCard: {
        title: 'Presupuesto para Nuevos(as) Empleados(as)',
        description:
          'Monto en dólares que recibirán los nuevos empleados, si se establece en automático.',
      },
      budgetType: 'Tipo de Presupuesto',
      amount: 'Cantidad',
    },
    manageBudgetDistributionDemographics: {
      breakdownBy: 'Desglose por {selectedDemographic}',
      selectedDemographic: 'Demografía',
      labels: {
        distributeEvenly: 'Distribuir Uniformemente',
        amount: 'Cantidad',
      },
      byDemographic: 'Por Demografía',
      byEmployee: 'Por empleado(a)',
      formHelperText:
        'Use esta sección para calcular automáticamente las cantidades para los campos de cantidad a continuación',
    },
    manageBudgetDistributionFlat: {
      amountFieldLabel:
        '¿Qué cantidad desea asignar a cada empleado para un límite de gasto?',
    },
    manageBudgetDistributionIndividual: {
      amountFieldLabel: 'Cantidad',
      selectIndividuals: 'Seleccionar Individuos',
      dialogTitle: 'Seleccionar Individuos',
      noEmployeesFound:
        'No employees were found with the current search criteria.',
      labels: {
        nameOrTitle: 'Nombre/Título',
        demographics: 'Demografía',
        roles: 'Roles',
      },
      search: 'Búsqueda',
    },
    manageBudgetForm: {
      title: 'Bienvenido a la Pantalla de Configuración del Presupuesto',
      description:
        'Empecemos por determinar cómo implementará su organización su presupuesto de reconocimiento. Puede elegir un presupuesto basado en el límite de gasto, un presupuesto basado en el límite de recepción o ambos',
    },
    manageBudgetGeneralForm: {
      title: 'Configuración General',
      labels: {
        yearType: 'Tipo de Calendario de Presupuesto',
        startDate: 'Fecha de Inicio',
        endDate: 'Fecha Final',
        budgetPeriod: 'Período de Presupuesto',
        budgetTypeForNewEmployees:
          'Tipo de presupuesto para Nuevos(as) Empleados(as)',
        amountPerNewEmployee: 'Cantidad por Nuevo(a) Empleado(a)',
      },
      invalidDate: 'Fecha Inválida',
    },
    manageBudgetLimitTypes: {
      setRules: 'Establecer reglas de {title}',
      spendingLimitSection: {
        title: 'Límite Basado en Gastos',
        description:
          'Puede seleccionar qué empleados (todos o solo algunos) tienen un presupuesto para gastar en empleados de la organización y cuánto es ese presupuesto.',
      },
      receivingLimitSection: {
        title: 'Límite Basado en la Recepción',
        description:
          'Puede seleccionar qué empleados (todos o solo algunos) pueden recibir recompensas y cuál es el presupuesto total para esas recompensas.',
      },
    },
    manageBudgetProrate: { prorateType: 'Tipo de Prorrateo' },
    manageBudgetReceivingForm: { title: 'Reglas de Recepción' },
    manageBudgetRollover: {
      labels: {
        allowRolloverForUnusedRewardBudget:
          'Permitir que los empleados transfieran el presupuesto de recompensas no utilizado de un período al siguiente en el mismo año calendario o fiscal.',
        allowRolloverFromOneYearToAnother:
          'Permitir que los empleados pasen de un año presupuestario al siguiente',
      },
    },
    manageBudgetSpendingForm: { title: 'Reglas de Gasto' },
    manageBudget: {
      formHandlerTitle: 'Configuración del Presupuesto',
      description: 'Descripción del Presupuesto',
      budgetFormTitle: 'Presupuesto',
      validations: {
        periodRequired: 'Se requiere un Período',
        startDate: {
          required: 'La Fecha de Inicio es obligatoria',
          invalidDate: 'Fecha Inválida',
        },
      },
    },
    budgetPeriods: {
      monthly: 'Mensual',
      quarterly: 'Trimestral',
      semiAnnually: 'Semestral',
      annually: 'Anual',
    },
    budgetProrateTypes: {
      noBudgetInPeriod:
        'El empleado no recibe presupuesto en el período actual',
      remainingFullMonths:
        'Prorrateo basado en los meses completos restantes en el período',
      remainingMonthsWithCurrent:
        'Prorrateo basado en los meses restantes del período (incluido el actual)',
    },
    budgetDistributionTypes: {
      flat: {
        label: 'Plano',
        descriptionSpending:
          'Todos los empleados reciben la misma cantidad para gastar en otros empleados.',
        descriptionReceiving:
          'Todos los empleados reciben exactamente la misma cantidad por período.',
      },
      individual: {
        label: 'Individual',
        descriptionSpending:
          'Establecer reglas de gasto por individuos (p. ej., John Doe, Jane Doe)',
      },
      demographics: {
        label: 'Demografía',
        descriptionReceiving:
          'Establecer reglas de recepción por datos demográficos (p. ej., departamento, ubicación)',
      },
    },
    budgetNewEmployeesBudgetTypes: {
      automatic: 'Automático',
      manual: 'Manual',
    },
    budgetYearTypes: { fiscal: 'Fiscal', calendar: 'Calendario' },
    updateBudgetSettings: 'Actualizar la Configuración del Presupuesto',
    updateBudgetSettingsSnackbarSuccess:
      'La Configuración del Presupuesto se actualizó correctamente.',
  },
  companyReporting: {
    sectionDataTitles: {
      recognitionsSent: 'Recognitions Sent',
      unusedRewards: 'Recompensas No Utilizadas',
      feedMessagesCount: 'Feed Messages Count',
      numberOfComments: 'Number of Comments',
    },
    reportSectionLabels: {
      topActiveUsers: 'Top Active Users',
      noRecognitionsPerPeriod: 'No Recognitions Per Period',
      financialReport: 'Financial Report',
    },
    formLabels: { section: 'Section' },
    filters: { period: 'Period' },
    financialReportColumnHeaders: {
      recognitionDate: 'Recognition Date',
      recipientName: 'Recipient Name',
      recognitionEvent: 'Recognition Event',
      rewardType: 'Reward Type',
      location: 'Location',
      giverName: 'Giver Name',
    },
    noRecognitionsByPeriod: {
      header: 'Users not recognized in this period:',
      columnHeaders: {
        role: 'Role',
        manager: 'Manager',
        allTimeRecognitions: 'All Time Recognitions',
        lastRecognition: 'Last Recognition',
      },
    },
    topActiveUsersColumnHeaders: {
      recognitionsSent: 'Recognitions Sent',
      messagesSent: 'Messages Sent',
      commentsPosted: 'Comments Posted',
      total: 'Total',
    },
  },
  sparckPreview: {
    title: 'This is what {userName} will see',
    theSparckedPerson: 'the Sparcked Person',
  },
  sparckReward: {
    fixed: 'Fixed',
    range: 'Range',
    options: '{name} options',
    giftCardMustBeWithin:
      'Gift Card must be within range and increment of {value}',
    giftCardCantBeLess: "Gift Card value can't be less than {value}",
    giftCardCantBeGreater: "Gift Card value can't be greater than {value}",
    selectGiftCardBetween:
      'Select a Gift Card amount between {minimumValue} and {maximumValue}',
    recognitionProgramMaxBudget:
      'The max. budget for this recognition event is {value}',
    confirmAmount: 'Confirm {amount}',
  },
  myRewards: {
    walletDescription:
      'When you reject a reward and bank it, you are putting the value of that reward into your wallet for later use. When you are ready to use those funds, simply click the Purchase Gift button below to view the rewards catalog and select the item you want to purchase. Remember you can select any amount up to the available balance of your wallet for your purchase. Have fun shopping!',
    wishlistDescription:
      "Like certain rewards more than others? Add them to your wishlist so they are easily accessible to you as well as people recognizing you. To add items to your wishlist, click the Add Items To My Wishlist button below, identify the items you like and click the heart icon. It's as simple as that!",
    tabLabels: {
      home: 'Home',
      myRewards: 'My Rewards',
      myPurchases: 'My Purchases',
    },
    youHaveNewRewards: 'You have {count} new rewards!',
    total: 'Total {name}',
    cardHeaders: {
      rewardsFrom: '{name} Rewards',
      availableToUse: 'available to use',
      myWallet: 'My Reward Bank',
      myWishlist: 'My Wishlist',
    },
    addMore: 'Add More',
    addItemsToWishlist: 'Add items to my wishlist',
    list: {
      title: 'My Rewards',
      visualizationModes: { cards: 'Cards', list: 'List' },
    },
    redeem: 'Redeem {name}',
    catalog: {
      title: 'Catálogo de Recompensas',
      noItems: 'No hay',
      tabLabels: {
        rewards: 'Recompensas de la Organización',
        rewardsNGC: 'Recompensas Minoristas',
      },
      makeWishListPublic:
        'Hacer pública mi lista de deseos (otros usuarios pueden ver mi lista de deseos)',
    },
    columns: {
      received: 'Recibido',
      details: 'Detalles',
      from: 'Desde',
      actions: 'Acciones',
      bank: 'Bank',
      accept: 'Aceptar',
      clickToRedeem: 'Haga clic para canjear este beneficio',
    },
    purchaseGift: 'Comprar Regalo',
    myGiftCardPurchases: 'Mis compras de Tarjetas de Regalo',
    redeemYourGiftCard: 'Redimir su Tarjeta de Regalo',
    myRewardsPurchasesTable: {
      columnTitles: {
        giftCard: 'Tarjeta de Regalo',
        brand: 'Marca',
        amount: 'Cantidad',
        acceptedAt: 'Aceptado en',
      },
      noPurchases: 'Todavía no hay tarjetas de regalo compradas.',
    },
    myRewardStorePurchasesTable: {
      columnTitles: {
        orderNumber: 'Order ID',
        orderItem: 'Tarjeta de Regalo',
        itemName: 'Marca',
        amount: 'Cantidad',
        acceptedAt: 'Aceptado en',
      },
      noPurchases: 'Todavía no hay tarjetas de regalo compradas.',
    },
    snackbar: {
      success: {
        acceptedReward:
          'You have successfully accepted your reward.  Please check your email for instructions on collecting your reward.',
        bankedReward: 'You banked this reward!',
      },
    },
  },
  sparckAppreciationAssessment: {
    landing: {
      title: 'Bienvenido(a) a la Evaluación de Apreciación de Sparck',
      description:
        'Estamos muy emocionados de trabajar con usted para descubrir su estilo de apreciación principal en el lugar de trabajo. Tarda unos 60 segundos y obtendrá acceso a sus resultados de inmediato. Este es solo el comienzo para comprender mejor cómo se siente valorado en el trabajo. Comience con solo su correo electrónico para que podamos enviarle su estilo Sparck. El resto es opcional. ¡Divertirse!',
    },
    questions: {
      title: 'Uncover Your Sparck Appreciation Style',
      description:
        'This quick quiz will help you determine your primary appreciation style in the workplace. Don’t overthink it, pick the option that fits you best. Let the fun begin!',
      questionTitle: 'It is more meaningful to me at work when...',
    },
    results: {
      title: 'Your Sparck Appreciation Style Results',
      sparckAppreciationStyle: 'Sparck Appreciation Style',
      checkYourEmail:
        'Check your email for the full results! Don’t forget to check your spam folder if you don’t see it.',
    },
    labels: {
      companyName: 'Nombre de la Organización',
      gender: 'Género',
      generation: 'Generación',
      currentPosition: 'Posición actual',
      email: 'Email (requerido)',
      getStarted: 'Empezar',
      preferredLanguage: 'Idioma Preferido',
      send: 'Muéstrame mis resultados!',
      contactInfo: 'Empecemos!',
      demographicInfo: '¿Quieres compartir más?',
      demographicInfoTooltip:
        'La información recopilada es confidencial y se utiliza únicamente con fines estadísticos',
    },
    currentPositions: {
      entryLevel: 'Nivel Básico',
      midLevel: 'Nivel Medio',
      seniorLevel: 'Nivel Superior',
      executive: 'Ejecutivo',
    },
    generations: {
      babyBoomer: 'Baby Boomer (Nacidos 1946 - 1964)',
      generationX: 'Generación X (Nacidos 1965 - 1980)',
      generationY: 'Generación Y (Nacidos 1981 - 1996)',
      generationZ: 'Generación Z (Nacidos 1997 - 2012)',
      generationAlpha: 'Generación Alpha (Nacidos 2013 - 2025)',
    },
    validations: {
      requiredEmail: 'Email es requerido',
      validEmail: 'Introduzca un email válido',
    },
  },
}
