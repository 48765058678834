import { Badge, Box, Button, Fade, Grid, IconButton, Typography, } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { Bolt } from '@mui/icons-material'

import { ROUTES_PATHS } from 'core/routes'
import { t } from 'i18n'

type Props = {
  takeActionsCount: any
  isMobile?: boolean
}

export default function TopbarTakeAction({ takeActionsCount, isMobile }: Props) {
  const history = useHistory()

  if (!takeActionsCount) return null

  return (
    <Grid item>
      <Fade in={!!takeActionsCount}>
        {isMobile ? (
          <IconButton
            color="warning"
            onClick={() => history.push(ROUTES_PATHS.takeAction)}
            sx={{
              background: theme =>
                `linear-gradient(90deg, ${theme.palette.warning.main} 0%, ${theme.palette.secondary.dark} 100%)`,
              boxShadow: '0px 4px 12px rgba(110, 120, 130, 0.1)',
              borderRadius: '50%',
              width: 30,
              height: 30,
              '&:hover': {
                background: theme =>
                  `linear-gradient(90deg, ${theme.palette.warning.dark} 0%, ${theme.palette.secondary.main} 100%)`,
              },
            }}
          >
            <Badge
              badgeContent={takeActionsCount}
              color="error"
              sx={{
                "& .MuiBadge-badge": {
                  top: "-16px",
                  left: "12px",
                  transform: "scale(1)",
                },
              }}
            >
              <Bolt sx={{ color: "#FFF", fontSize: 18 }} />
            </Badge>

          </IconButton>
        ) : (
          <Button
            variant='contained'
            onClick={() => history.push(ROUTES_PATHS.takeAction)}
            sx={{
              background: theme =>
                `linear-gradient(90deg, ${theme.palette.warning.main} 0%, ${theme.palette.secondary.dark} 100%)`,
              boxShadow: '0px 4px 12px rgba(110, 120, 130, 0.1)',
              borderRadius: 40,
              '&:hover': {
                background: theme =>
                  `linear-gradient(90deg, ${theme.palette.warning.dark} 0%, ${theme.palette.secondary.main} 100%)`,
              },
            }}
          >
            {t('routes.takeAction')}{' '}
            <Box
              component='span'
              sx={{
                borderRadius: '100%',
                outline: '2px solid #FFF',
                ml: 2,
                px: 1,
              }}
            >
              <Typography variant='body2' fontWeight={600}>
                {takeActionsCount}
              </Typography>
            </Box>
          </Button>
        )}
      </Fade>
    </Grid>
  )
}
