import { HelpOutline as HelperIcon } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material'
import { isEmpty } from 'lodash'

import {
  SettingsFeed,
  SettingsGeneral,
  SettingsInsightsPermissions,
  SettingsIntegrations,
  SettingsMFA,
  //SettingsNotifications, It will be available when the backend is finished.
  // SettingsNominations,
  SettingsProfanityFilter,
  SettingsRecognitionFrequency,
  SettingsSocialMedia,
} from '../../components'
import { useUser } from 'contexts/UserContext'
import { t } from 'i18n'
import { userHasBeseenPermissions } from 'utils/user'

export default function SettingsComponent(props) {
  const { companyState, handleSaveSettings, saveSettingsIsLoading } = props

  const { user } = useUser()!

  if (isEmpty(companyState)) {
    return (
      <Grid container justifyContent='center' alignItems='center'>
        <Box pt={2}>
          <CircularProgress size={40} />
        </Box>
      </Grid>
    )
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SettingsCard
          {...props}
          key='general'
          title={'General'}
          description='Enter your organization’s purpose statement which will be displayed on your organization’s feed screen so it is front and center for your employees.  You can also select the main timezone for your organization.'
          Content={SettingsGeneral}
        />
      </Grid>

      {userHasBeseenPermissions(user) && (
        <Grid item xs={12} md={6}>
          <SettingsCard
            {...props}
            key='recognitionFrequency'
            title={t('settings.sectionTitles.recognitionFrequency')}
            description='How often should managers be recognizing their employees?'
            Content={SettingsRecognitionFrequency}
          />
        </Grid>
      )}

      {userHasBeseenPermissions(user) && (
        <Grid item xs={12} md={6}>
          <SettingsCard
            {...props}
            key='socialMedia'
            title={t('settings.sectionTitles.socialMedia')}
            description='Do you want to allow your employees to share their public recognition with the world? Simply allow Social Media sharing below and select the sites you allow them to share to and they will be able to share their recognition with friends and family.'
            Content={SettingsSocialMedia}
          />
        </Grid>
      )}

      {/* {userHasBeseenPermissions(user) && (
        <Grid item xs={12} md={4}>
          <SettingsCard
            {...props}
            key='nominations'
            title={t('settings.sectionTitles.nominations')}
            description='Give your employees the option to nominate their peers for specific recognition events and then select the winner(s). Enable it here and create nominations in Recognition Events, located on the left menu bar.'
            Content={SettingsNominations}
          />
        </Grid>
      )} */}

      {/* It will be available when the backend is finished. */}
      {/* <Grid item xs={12} md={6}>
        <SettingsCard
          {...props}
          key='notifications'
          title={t('settings.sectionTitles.notifications')}
          description="Manage your notification preferences. By default, all notifications are enabled for all users. You can customize this by toggling email and SMS notifications on or off."
          Content={//SettingsNotifications}
        />
      </Grid> */}

      {userHasBeseenPermissions(user) && (
        <Grid item xs={12} md={6}>
          <SettingsCard
            {...props}
            key='profanityFilter'
            title={t('settings.sectionTitles.profanityFilter')}
            description='By default we process all images and messages through a profanity filter and disallow profanity from being posted on Recognition and Feed posts. If you would like to turn this off, please select below.'
            Content={SettingsProfanityFilter}
          />
        </Grid>
      )}

      {userHasBeseenPermissions(user) && (
        <Grid item xs={12} md={6}>
          <SettingsCard
            {...props}
            key='mfa'
            title={t('settings.sectionTitles.mfa')}
            description='Sparck provides employees the ability to setup MFA for their accounts on an individual basis. If you would like to require all employees to setup MFA, you can turn that on below.'
            Content={SettingsMFA}
          />
        </Grid>
      )}

      {userHasBeseenPermissions(user) && (
        <Grid item xs={12} lg={6}>
          <SettingsCard
            {...props}
            key='feed'
            title={t('settings.sectionTitles.feed')}
            description='Below you can configure how your Feed screen functions for your organization.'
            Content={SettingsFeed}
          />
        </Grid>
      )}

      {userHasBeseenPermissions(user) && (
        <Grid item xs={12} lg={6}>
          <SettingsCard
            {...props}
            key='insights'
            title={t('settings.sectionTitles.insightsPermissions')}
            description='Below you can determine if BeHeard and Pulse survey results can be viewed on your Organizational Insights and Team Insights screens.'
            Content={SettingsInsightsPermissions}
          />
        </Grid>
      )}

      {userHasBeseenPermissions(user) && companyState.bot_engine && (
        <Grid item xs={12}>
          <SettingsCard
            {...props}
            key='integrations'
            title={t('settings.sectionTitles.integrations')}
            description='Sparck has created integrations with Communications tools Slack and MS Teams.  If your organization uses one of these tools, you can integrate it with your Sparck account, which will allow your employees to receive recognitions and notifications directly in the tool they use on a daily basis.'
            Content={SettingsIntegrations}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <Box mt={1} width={1}>
          <Button
            data-cy='save_settings'
            color='primary'
            variant='contained'
            size='large'
            onClick={handleSaveSettings}
            disabled={saveSettingsIsLoading}
            fullWidth
          >
            {saveSettingsIsLoading ? (
              <CircularProgress size={24} />
            ) : (
              t('settings.saveChanges')
            )}
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}

const SettingsCard = props => {
  const { title, description, Content, ...rest } = props

  return (
    <Paper sx={{ py: 2, px: 3, height: 1 }}>
      <Typography variant='h6' color='text.primary' fontWeight={500} paragraph>
        <Grid container component='span' alignItems='center'>
          {title}{' '}
          <Tooltip title={description}>
            <HelperIcon sx={{ color: 'text.secondary', ml: 1 }} />
          </Tooltip>
        </Grid>
      </Typography>

      <Content {...rest} />
    </Paper>
  )
}

export const SettingsControlSection = props => {
  const { id, label, ControlComponent, companyState, setCompanyState } = props

  const handleCheckChange = event => {
    setCompanyState({
      ...companyState,
      [event.target.name]: event.target.checked,
    })
  }

  return (
    <Grid
      container
      justifyContent={'flex-start'}
      alignItems='center'
      mb={ControlComponent === Switch ? 1 : 0}
    >
      <FormControlLabel
        control={
          <ControlComponent
            name={id}
            checked={companyState[id]}
            onChange={handleCheckChange}
            color='primary'
          />
        }
        label={label}
      />
    </Grid>
  )
}
