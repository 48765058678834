export default {
  actions: {
    create: 'Create {name}',
    edit: 'Edit {name}',
    save: 'Save {name}',
  },
  routes: {
    approvals: 'Approvals',
    beheardReport: '#BeHeard Report',
    beheardSurvey: '#BeHeard Enquête',
    budget: 'Budget',
    changePassword: 'Change Password',
    companies: 'Organizations',
    companyInsights: 'Organization Insights',
    companyReporting: 'Reporting',
    coreValues: 'Core Values',
    dashboard: 'Dashboard',
    demographics: 'Demographics',
    employees: 'Employees',
    feed: 'Feed',
    inappropriateContent: 'Inappropriate Content',
    insights: 'Insights',
    invoices: 'Invoices',
    login: 'Login',
    logout: 'Logout',
    marketing: 'Upgrade',
    managePulseSurvey: 'Manage Pulse Survey',
    managerCertification: 'Employee Certification',
    managerCertificationTask: 'Employee Recognition Certification Task',
    nominationsManagement: 'Nominations',
    pulseSurveyInsights: 'Insights',
    pulseSurveyOverview: 'Pulse Surveys',
    manageSurvey: ' ',
    surveyOverview: '#BeHeard Enquête',
    company: 'My Organization',
    myRewards: 'My Rewards',
    mySettings: 'My Settings',
    notifications: 'Notifications',
    onboarding: 'Onboarding',
    onboardingQuestions: 'Onboarding Questions',
    people: 'People',
    rewards: 'Rewards',
    profile: 'Profile',
    profileQuestions: 'Profile Questions',
    pulseSurveys: 'Pulse Surveys',
    recognitionPrograms: 'Recognition Events',
    roles: 'Roles',
    settings: 'Settings',
    shareReport: 'Share Report',
    shoppingCart: 'Shopping Cart',
    signup: 'Signup',
    recognize: 'Recognize!',
    sparckDisplay: 'Sparck Display',
    sparckMemories: 'Sparck Memories',
    suggestionChat: 'Suggestion Chat',
    suggestions: 'Suggestions',
    survey: 'Enquête',
    surveys: 'Surveys',
    takeAction: 'Take Action',
    teamInsights: 'Team Insights',
    virginExperiencesSettings: 'Virgin Experiences',
  },
  industries: {
    accounting: 'Accounting',
    airlinesAviation: 'Airlines/Aviation',
    alternativeDisputeResolution: 'Alternative Dispute Resolution',
    alternativeMedicine: 'Alternative Medicine',
    animation: 'Animation',
    apparelFashion: 'Apparel & Fashion',
    architecturePlanning: 'Architecture & Planning',
    artsCrafts: 'Arts & Crafts',
    automotive: 'Automotive',
    aviationAerospace: 'Aviation & Aerospace',
    banking: 'Banking',
    biotechnology: 'Biotechnology',
    broadcastMedia: 'Broadcast Media',
    buildingMaterials: 'Building Materials',
    businessSuppliesEquipment: 'Business Supplies & Equipment',
    cannabis: 'Cannabis',
    capitalMarkets: 'Capital Markets',
    chemicals: 'Chemicals',
    civicSocialOrganization: 'Civic & Social Organization',
    civilEngineering: 'Civil Engineering',
    commercialRealEstate: 'Commercial Real Estate',
    computerNetworkSecurity: 'Computer & Network Security',
    computerGames: 'Computer Games',
    computerHardware: 'Computer Hardware',
    computerNetworking: 'Computer Networking',
    computerSoftware: 'Computer Software',
    construction: 'Construction',
    consumerElectronics: 'Consumer Electronics',
    consumerGoods: 'Consumer Goods',
    consumerServices: 'Consumer Services',
    cosmetics: 'Cosmetics',
    dairy: 'Dairy',
    defenseSpace: 'Defense & Space',
    design: 'Design',
    eLearning: 'E-learning',
    educationManagement: 'Education Management',
    electricalElectronicManufacturing: 'Electrical & Electronic Manufacturing',
    entertainment: 'Entertainment',
    environmentalServices: 'Environmental Services',
    eventServices: 'Event Services',
    executiveOffice: 'Executive Office',
    facilitiesServices: 'Facilities Services',
    farming: 'Farming',
    financialServices: 'Financial Services',
    fineArt: 'Fine Art',
    fishery: 'Fishery',
    foodBeverages: 'Food & Beverages',
    foodProduction: 'Food Production',
    fundraising: 'Fundraising',
    furniture: 'Furniture',
    gamblingCasinos: 'Gambling & Casinos',
    glassCeramicsConcrete: 'Glass, Ceramics & Concrete',
    governmentAdministration: 'Government Administration',
    governmentRelations: 'Government Relations',
    graphicDesign: 'Graphic Design',
    healthWellnessFitness: 'Health, Wellness & Fitness',
    higherEducation: 'Higher Education',
    hospitalHealthCare: 'Hospital & Health Care',
    hospitality: 'Hospitality',
    humanResources: 'Human Resources',
    importExport: 'Import & Export',
    individualFamilyServices: 'Individual & Family Services',
    industrialAutomation: 'Industrial Automation',
    informationServices: 'Information Services',
    informationTechnologyServices: 'Information & Technology Services',
    insurance: 'Insurance',
    internationalAffairs: 'International Affairs',
    internationalTradeDevelopment: 'International Trade & Development',
    internet: 'Internet',
    investmentManagement: 'Investment Management',
    investmentBanking: 'Investment Banking',
    judiciary: 'Judiciary',
    lawEnforcement: 'Law Enforcement',
    lawPractice: 'Law Practice',
    legalServices: 'Legal Services',
    legislativeOffice: 'Legislative Office',
    leisureTravelTourism: 'Leisure, Travel & Tourism',
    libraries: 'Libraries',
    logisticsSupplyChain: 'Logistics & Supply Chain',
    luxuryGoodsJewelry: 'Luxury Goods & Jewelry',
    machinery: 'Machinery',
    managementConsulting: 'Management Consulting',
    maritime: 'Maritime',
    marketResearch: 'Market Research',
    marketingAdvertising: 'Marketing & Advertising',
    mechanicalOrIndustrialEngineering: 'Mechanical or Industrial Engineering',
    mediaProduction: 'Media Production',
    medicalDevice: 'Medical Device',
    medicalPractice: 'Medical Practice',
    mentalHealthCare: 'Mental Health Care',
    military: 'Military',
    miningMetals: 'Mining & Metals',
    motionPicturesFilm: 'Motion Pictures & Film',
    museumsInstitutions: 'Museums & Institutions',
    music: 'Music',
    nanotechnology: 'Nanotechnology',
    newspapers: 'Newspapers',
    nonProfitManagement: 'Non-Profit Management',
    oilEnergy: 'Oil & Energy',
    onlineMedia: 'Online Media',
    outsourcingOffshoring: 'Outsourcing/Offshoring',
    packageFreightDelivery: 'Package/Freight Delivery',
    packagingContainers: 'Packaging & Containers',
    paperForestProducts: 'Paper & Forest Products',
    performingArts: 'Performing Arts',
    pharmaceuticals: 'Pharmaceuticals',
    philanthropy: 'Philanthropy',
    photography: 'Photography',
    plastics: 'Plastics',
    politicalOrganization: 'Political Organization',
    primarySecondaryEducation: 'Primary/Secondary Education',
    printing: 'Printing',
    professionalTrainingCoaching: 'Professional Training & Coaching',
    programDevelopment: 'Program Development',
    publicPolicy: 'Public Policy',
    publicRelationsCommunications: 'Public Relations & Communications',
    publicSafety: 'Public Safety',
    publishing: 'Publishing',
    railroadManufacturer: 'Railroad Manufacturer',
    ranching: 'Ranching',
    realEstate: 'Real Estate',
    recreationalFacilitiesServices: 'Recreational Facilities & Services',
    religiousInstitutions: 'Religious Institutions',
    renewablesEnvironment: 'Renewables & Environment',
    research: 'Research',
    restaurants: 'Restaurants',
    retail: 'Retail',
    securityInvestigations: 'Security & Investigations',
    semiconductors: 'Semiconductors',
    shipbuilding: 'Shipbuilding',
    sportingGoods: 'Sporting Goods',
    staffingRecruiting: 'Staffing & Recruiting',
    supermarkets: 'Supermarkets',
    telecommunications: 'Telecommunications',
    textiles: 'Textiles',
    thinkTanks: 'Think Tanks',
    tobacco: 'Tobacco',
    translationLocalization: 'Translation & Localization',
    transportationTruckingRailroad: 'Transportation/Trucking/Railroad',
    utilities: 'Utilities',
    ventureCapitalPrivateEquity: 'Venture Capital & Private Equity',
    veterinary: 'Veterinary',
    warehousing: 'Warehousing',
    wholesale: 'Wholesale',
    wineSpirits: 'Wine & Spirits',
    wireless: 'Wireless',
    writingEditing: 'Writing & Editing',
  },
  topbar: {
    viewReleaseNotes: 'View Release Notes',
    termsOfService: 'View Terms of Service',
    privacyPolicy: 'View Privacy Policy',
  },
  footer: { weLove: 'We', slogan: 'Recognition as unique as you are!' },
  formHandler: {
    create: 'Create',
    edit: 'Edit',
    update: 'Update',
    updated: 'updated',
    created: 'created',
    successWithTitle: 'successfully',
    success: 'Success!',
  },
  confirmDelete: {
    confirmation: 'Are you sure you want to',
    deleteLabel: 'delete',
    delete: 'Delete Item',
  },
  table: {
    body: {
      emptyDataSourceMessage: 'No records to display',
      addTooltip: 'Add',
      deleteTooltip: 'Delete',
      editTooltip: 'Edit',
      filterRow: { filterPlaceHolder: 'Filter', filterTooltip: 'Filter' },
      editRow: {
        deleteText: 'Are you sure you want to delete this row?',
        cancelTooltip: 'Cancel',
        saveTooltip: 'Save',
      },
    },
    grouping: { placeholder: 'Drag headers...', groupedBy: 'Grouped By:' },
    header: { actions: 'Actions' },
    pagination: {
      labelDisplayedRows: '{from}-{to} of {count}',
      labelRowsSelect: 'rows',
      labelRowsPerPage: 'Rows per page:',
      firstAriaLabel: 'First Page',
      firstTooltip: 'First Page',
      previousAriaLabel: 'Previous Page',
      previousTooltip: 'Previous Page',
      nextAriaLabel: 'Next Page',
      nextTooltip: 'Next Page',
      lastAriaLabel: 'Last Page',
      lastTooltip: 'Last Page',
    },
    toolbar: {
      addRemoveColumns: 'Add or remove columns',
      nRowsSelected: '{0} row(s) selected',
      showColumnsTitle: 'Show Columns',
      showColumnsAriaLabel: 'Show Columns',
      exportTitle: 'Export',
      exportAriaLabel: 'Export',
      exportPDFName: 'Export as PDF',
      exportCSVName: 'Export as CSV',
      searchTooltip: 'Search',
      searchPlaceholder: 'Search',
    },
  },
  simpleCrudTable: {
    actions: 'Actions',
    limit: { firstLine: 'You can only add up to', secondLine: 'items.' },
    rows: 'rows',
  },
  simpleLibrarySelection: {
    selected: { singular: 'Selected', plural: 'Selected' },
    sortedAscending: 'sorted ascending',
    sortedDescending: 'sorted descending',
  },
  companyAdminLayout: {
    add: 'Add',
    remove: 'Remove',
    thisItem: 'this item',
    successfullyUpdated: 'successfully updated!',
    successfullyDeleted: 'successfully deleted!',
    success: 'Success!',
    all: 'All',
  },
  welcome: {
    welcomeCompany: {
      title: 'Send Welcome',
      description:
        'Send this notification if the organization has not yet been notified about its organization creation',
    },
    welcomeEmployee: {
      title: 'Send Welcome',
      description:
        'Send this message if you want to welcome an employee to our platform',
    },
    welcomeAllEmployees: {
      title: 'Send Welcome to All Employees',
      description:
        'Send this message if you want to welcome all employees at once',
    },
    welcomeNewEmployees: {
      title: 'Send Welcome to New Employees Only',
      description:
        'Send this message if you want to welcome only employees who have not yet received this welcome message',
    },
    betaTeamInviteEmail: {
      title: 'Beta Team Invite',
      description:
        'Send this message if you want to invite an employee to Sparck Beta',
    },
    resendSurvey: {
      title: 'Resend Survey',
      description:
        'Send this message if you want to invite an employee to active survey again',
    },
    resendPulseSurvey: {
      title: 'Resend Pulse Survey',
      description:
      'Send this message if you want to invite an employee to active pulse survey again'
    },
  },
  linkHere: 'HERE',
  loading: 'Loading...',
  yes: 'Yes',
  no: 'No',
  thereAreNo: 'There are no {name}',
  name: 'Name',
  nameIsRequired: 'Name is required',
  description: 'Description',
  firstName: 'First Name',
  lastName: 'Last Name',
  phoneNumber: 'Phone Number',
  cellNumber: 'Cell Number',
  cancel: 'Cancel',
  department: 'Department',
  email: 'Email',
  status: 'Status',
  birthday: 'Birthday',
  for: 'for',
  invalidDateFormat: 'Invalid Date',
  participation: 'Participation',
  reward: 'Reward',
  type: 'Type',
  value: 'Value',
  select: 'Select',
  back: 'Back',
  statuses: {
    accepted: 'Accepted',
    approved: 'Approved',
    declined: 'Declined',
    pending: 'Pending',
  },
  beheardSurveyDrivers: {
    alignment: 'Alignment',
    fit: 'Fit',
    growth: 'Growth',
    team: 'Team',
    valuing: 'Valuing',
    turnover: 'Turnover',
  },
  dashboard: {
    welcome: {
      title: 'Welcome to Sparck!',
      description:
        'Congratulations on setting up your organization account. To get started, please follow the steps in this Dashboard to successfully launch your free #BeHeard engagement survey!',
    },
    getStarted: 'Get Started',
    greetings: 'ハッピー {day}, {userName}!',
    noReminders: 'You currently have no reminders.',
    reminders: {
      year: 'year',
      years: 'years',
      workiversaryOn: '{userName} {years} workiversary on {date}',
      birthdays: 'Birthdays',
      workiversaries: 'Workiversaries',
      showToday: "Show Today's {sectionName} only",
      showUpcoming: 'Show Upcoming {sectionName}',
      today: "Today's {sectionName}",
      upcoming: 'Upcoming {sectionName}',
      noRemindersToday: 'No {reminderSectionName} today',
    },
    profileProgress: 'Your organization profile is {percentage} complete.',
    tasks: 'Tasks',
    noTasks: 'You are up to date on your tasks!',
  },
  pulseSurvey: {
    header: 'Your voice matters',
    responsesAreAnonymous: 'All responses are anonymous.',
    needHelp: 'Need help?',
    clickHere: 'Click here',
    changeButtonText: 'Change',
    previous: 'Previous',
    next: 'Next',
    finish: 'Finish',
    openEndedLabel: 'Your Answer',
    openEndedPlaceholder: 'Type your answer here...',
    openEndedHelperText:
      'To ensure your confidentiality, do not put any personally identifiable information.',
    noPulseSurveys:
      'There are no Pulse Surveys right now but we’ll ping you when there are.',
    statistics: {
      tableHeaders: {
        satisfaction: 'Satisfaction',
        importance: 'Importance',
        openEnded: 'Open Ended',
        statement: 'Statement',
        agree: 'agree',
        neutral: 'neutral',
        disagree: 'disagree',
        important: 'important',
        indifferent: 'indifferent',
        not_important: 'not important',
      },
      answers: 'Answers',
    },
  },
  pulseSurveyOverview: {
    header: 'Pulse Surveys',
    textFieldLabel: 'Pulse Survey',
    snackbarSuccess: {
      openedPulseSurvey: 'The Pulse Survey has been opened',
      closedPulseSurvey: 'The Pulse Survey has been closed',
      extendedPulseSurvey: 'The Pulse Survey has been extended',
      deletedPulseSurvey: 'Pulse Survey has been deleted!',
      addedToCart: 'Pulse Survey added to your cart successfully',
    },
    newPulseSurvey: 'New Pulse Survey',
    launchPulseSurvey: 'Launch Pulse Survey',
    editPulseSurvey: 'Edit Pulse Survey',
    deletePulseSurvey: 'Delete Pulse Survey',
    closePulseSurvey: 'Close Pulse Survey',
    extendPulseSurvey: 'Extend Pulse Survey',
    pulseReport: 'Pulse Report',
    dialogs: {
      title: 'Pulse Surveys',
      create: {
        descriptionFirstLine:
          'It’s time to make the Pulse Survey your own. You can make changes before the Pulse Survey opens and extend the Pulse Survey before it closes. Click',
        link: 'HERE',
        descriptionSecondLine: 'for best practices around Pulse Surveys.',
      },
      edit: {
        descriptionFirstLine:
          'Need to edit your pulse survey? No problem. You can do that here. Please make your changes below. Click',
        link: 'HERE',
        descriptionSecondLine: 'for best practices around Pulse Surveys.',
      },
      confirmClose: {
        title: 'Close Pulse Survey',
        actionLabel: 'Close Pulse Survey',
        deleteItemLabel: 'close',
      },
      confirmDelete: {
        title: 'Delete Pulse Survey',
        actionLabel: 'Delete Pulse Survey',
        alternateConfirmDeleteLabel:
          'This pulse survey has answers associated with it. Are you sure you want to delete it? This cannot be undone.',
      },
      demographicsPickerLabel:
        'Which Demographics will receive this Pulse Survey?',
      rolesPicker: {
        label: 'Which Roles will receive this Pulse Survey?',
        selectLabel: 'Roles',
      },
      formLabels: {
        pulseSurveyName: 'Pulse Survey Name',
        launchDate: 'Launch Date',
        closeDate: 'Close Date',
      },
      questionsTable: {
        header: 'Questions',
        tableHeaders: {
          statement: 'Statement',
          showImportance: 'Show Importance Scale?',
          isOpenEnded: 'Is Open Ended?',
        },
        statementPlaceholder: 'Type your question/statement...',
        statementHelperText:
          'Please type your question and click the "+" button to add it.',
        librarySelection: { headers: { statement: 'Statement' } },
      },
    },
    validations: {
      pulseSurveyNameIsRequired: 'Pulse Survey Name is required',
      minimumLaunchDate: 'You cannot select a past date',
      maximumLaunchDate: 'Launch Date cannot be after the Close Date',
      launchDateIsRequired: 'Launch date is required',
      minimumCloseDate: 'Close Date cannot be before Launch Date',
      closeDateIsRequired: 'Close date is required',
    },
  },
  surveyLaunchMode: {
    label: 'How would you like to launch your survey?',
    manual: {
      label: 'Launch Manually',
      description:
        "By selecting Launch Manually, your survey won't launch until you click the {launchButtonLabel} button on the Manage Surveys screen. You will also be required to enter a Close Date before you are able to launch your survey which will automatically close the survey on the date you selected.",
    },
    scheduled: {
      label: 'Schedule Launch',
      description:
        'By selecting Schedule Launch, your survey will automatically launch on the launch date you specified and will automatically close on the close date specified. You can always launch your survey earlier by clicking the {launchButtonLabel} button from the Manage Survey screen.',
    },
    drivers: {
      label: 'Which engagement drivers you want to include',
      description: 'TODO By selecting {launchButtonLabel} button from the Manage Survey screen.',
      fit: 'Fit',
      fitToolTipText: 'Fit',
      growth: 'Growth',
      growthToolTipText: 'Growth',
      alignment: 'Alignment',
      alignmentToolTipText: 'Alignment',
      value: 'Value',
      valueToolTipText: 'Value',
      team: 'Team',
      teamToolTipText: 'Team',
    },
    changeManagerText: {
      label: 'Use this text in place of Manager',
      toolTipDescription:
        'You can write text like: Managers, Director so it will be shown in your survey page',
    },
  },
  myCompany: {
    greetings: { good: 'Good', welcomeBack: 'Welcome back!' },
    companyOverview: {
      cardTitle: 'Organization Overview',
      sectionLabels: {
        name: 'Organization Name',
        numberOfEmployees: 'Number of Employees',
        companyAdministrators: 'Organization Administrators',
      },
      sendBulkMessage: 'Send Welcome',
      textFieldLabel: 'Organization Name',
      seeLess: 'See Less',
      seeMore: 'See More',
      dialogTitle: 'Send Welcome',
    },
    companyBeheard: {
      cardTitle: 'Upgrade Report',
      getMoreFromResults:
        'Get more from your results. Learn more about the #BeHeard Premium Report.',
      learnMore: 'Learn More',
    },
    companyGotQuestions: {
      cardTitle: 'Got Questions?',
      weAreHereToHelp: 'We are here to help you!',
      sectionLabels: {
        customerExperience: 'Customer Experience Partner',
        technicalSupport: 'Technical Support',
      },
    },
    companySurveyUpdates: {
      cardTitle: 'Survey Updates',
      emptyMessage: 'There are currently no surveys.',
      seeAllSurveys: 'See all Surveys',
      columnLabels: {
        surveyType: 'Survey Type',
        openDate: 'Open Date',
        closeDate: 'Close Date',
        invites: 'Invites',
      },
      linkLabels: {
        manageBeheardSurvey: 'Manage #BeHeard Survey',
        beheardPremiumReport: '#BeHeard Premium Report',
        shareReport: 'Share Report',
        upgradeToBeheardPremiumReport: 'Upgrade to #BeHeard Premium Report',
        beheardFreeReport: '#BeHeard Free Report',
      },
    },
    companyPulseSurveyUpdates: {
      cardTitle: 'Pulse Survey Updates',
      emptyMessage: 'There are currently no Pulse Surveys.',
      seeAllPulseSurveys: 'See all Pulse Surveys',
      columnLabels: { openDate: 'Open Date', closeDate: 'Close Date' },
      linkLabels: { managePulseSurvey: 'Manage Pulse Survey' },
    },
    companySuggestionUpdates: {
      cardTitle: 'Suggestion Updates',
      emptyMessage: 'There are currently no suggestions.',
      seeAllSuggestions: 'See all Suggestions',
      columnLabels: {
        date: 'Date',
        from: 'From',
        numberOfReplies: 'Number of replies',
        suggestion: 'Suggestion',
      },
    },
    companyFreeResources: {
      cardTitle: 'Free Resources',
      content:
        'Check out our tool kit with everything you’ll need for an effective #BeHeard Survey experience.',
      letsDoIt: "Let's do it!",
    },
    companyCostAnalysis: {
      cardTitle: 'Cost Analysis',
      content:
        'How much is employee disengagement costing you? Find out with our FREE Engagement Cost Analysis.',
      getStartedNow: 'Get started now!',
    },
    companyNotifications: {
      dialogTitle: 'Are you sure you want to send',
      send: 'Send',
    },
    snackbarSuccess: {
      updated: 'Organization successfully updated',
      surveyAddedToCart: 'Survey added to your cart successfully',
    },
  },
  companies: {
    addFunds: 'Add Funds',
    allowApplication: 'Allow {application} application',
    allowInternationalization: 'Allow Internationalization',
    beheardAccess: '#BeHeard Access',
    beseenAccess: '#Beseen Access',
    clientSince: 'Client Since',
    companyName: 'Organization Name',
    createCompany: 'Create Organization',
    description:
      'You, as the Sparck Administrator, can manage organizations the way you want :)',
    editCompany: 'Edit Organization',
    emails: 'E-mails',
    funds: 'Funds',
    fundsAmount: 'Funds amount',
    manageNgcFor: 'Manage NGC for',
    ngcAccountSuccessfullyUpdated: 'NGC account updated successfully!',
    ngcAmount: 'NGC Amount',
    ngcApiClientId: 'NGC API Client ID',
    ngcApiSecret: 'NGC API Secret',
    ngcManagement: 'NGC Management',
    virginExperiencesManagement: 'Virgin Experiences Management',
    released: 'Released',
    saveChanges: 'Save Changes',
    sendEmailsTo: 'Send e-mails to',
    sparckAmount: 'Sparck Amount',
    title: 'Organizations',
    total: 'Total',
    validations: { companyNameIsRequired: 'Organization Name is required' },
    snackbar: { success: { emailSent: 'This email was successfully sent!' } },
  },
  employees: {
    title: 'Employees',
    description:
      'Welcome! Getting started is fast and easy, just upload your employee email addresses. You can include workplace demographic information later if you upgrade to the Premium #BeHeard report.',
    openedSurveyResent: 'The opened survey email was resent to this user!',
    betaTeamInviteSent: 'The Beta team invite email was sent to this user!',
    welcomeNotificationSent: 'The Welcome message was sent to this user!',
    employeeRoleDisclaimer:
      'If you create an employee without adding a role they will get the Employee role by default. If the employee is a Manager or above, select the Role before creating the employee.',
    fields: {
      businessUnit: 'Business Unit',
      companyInternalId: 'Organization Internal ID',
      dateOfBirth: 'Date of Birth',
      email: 'E-mail',
      ethnicity: 'Ethnicity',
      gender: 'Gender',
      highValueTalent: 'High Value Talent',
      highValueTalentTooltip:
        'Filter reports by level of performance based on internal metrics (ex. Low, medium, and high performance).',
      hireDate: 'Hire Date',
      jobType: 'Job Type',
      location: 'Location Name',
      performanceLevel: 'Performance Level',
      performanceLevelTooltip:
        'Filter reports by level of performance based on internal insights (ex. low, medium, high).',
      region: 'Region',
      country: 'Country',
      roles: 'Roles',
      supervisors: 'Supervisors',
      territory: 'Territory',
      title: 'Title',
    },
    placeholders: { male: 'Male' },
    sendEmails: 'Send E-mails',
    tooltips: {
      changePassword: 'Change Password',
      spoofEmployee: 'Spoof This Employee',
    },
    bulkEditEmployees: 'Bulk Edit Employees',
    hrisSection: 'HRIS Section',
    hrisSectionLabel: 'Import Employees',
    hrisSectionDescription:
      'Make your employee upload even easier and integrate with your HRIS system. ',
    bulkUpload: 'Bulk Upload',
    bulkUploadLabel: 'Upload Spreadsheet',
    bulkUploadDescription:
      '200 rows? 500+ rows? We can handle your employee spreadsheet!',
    employeeManagement: 'Employee Management',
    employeeManagementLabel: 'Add an Employee',
    employeeManagementDescription:
      'Want to add your employees individually? You can do that here!',
    notifyViaSmsAndEmail: 'Notify {name} via SMS/Email',
    changeEmployeePasswordDialog: {
      title: 'Change Employee Password',
      changePassword: 'Change Password',
    },
    employeesGraphDialogTitle: 'Employees Graph Visualization',
    selectEmployees: 'Select Employees',
    manageEmployeeFormTabs: {
      info: 'Info',
      demographics: 'Demographics',
      rolesAndSupervisors: 'Roles & Supervisors',
    },
    generalInformation: 'General Information',
    employeeContact: 'Employee Contact',
    employeeLocation: 'Employee Location',
    demographicInformation: 'Demographic Information',
    validations: {
      invalidEmailFormat: 'Invalid email format',
      emailIsRequired: 'Email is required',
    },
    snackbars: {
      error: {
        assignAtLeastOneRole:
          'Please assign at least one role to the employee.',
      },
    },
  },
  hrisImport: {
    trinet: 'TriNet',
    submittedHRISSystem:
      'You have submitted {hrisSuggestion} as your HRIS System. Thanks for letting us know.',
    employeesImported:
      'Your employees were successfully imported from your HRIS!',
    importEmployeesFromHRIS: 'Import employees from your HRIS system',
    selectHRIS:
      "Select an HRIS system and input your HRIS system ID - it's as simple as that!",
    hrisSystem: 'HRIS System',
    companyId: 'Organization ID',
    importEmployeesLabel: 'Import Employees from HRIS',
    doYouUseAnotherHRIS: 'Do you use another HRIS system?',
    comingSoon:
      'If you are using a different HRIS system, let us know. Enter the name below and we will make this a priority for future integrations.',
    submit: 'Submit',
    futureDevelopment: 'We will put it on our list for future development',
  },
  employeesUpload: {
    employeesImportedSuccessfully: 'Employees imported successfully!',
    uploadEmployeeInformation: 'Upload Your Employee Information',
    description1:
      "Use our Excel template to upload your employees. The second tab of the sheet will provide instructions and examples if needed. Once your Excel file is completed, use the Upload button to send it. You will be able to check each employee's information before importing.",
    description2:
      'The template includes two orange columns (on the far right) for you to add custom workplace demographics. This will give you additional filtering options for your reporting.',
    downloadExcelTemplate: 'Click here to download the Excel template.',
    dragAndDrop: 'Drag and drop your spreadsheet or just click here!',
    importingYourSpreadsheet:
      'Importing your spreadsheet! This may take some time...',
  },
  invoices: {
    title: 'Invoices',
    description: 'Check and manage your invoices here',
    invoicePDF: 'Click here to get a PDF of the invoice',
    proceedToPayment: 'Proceed to Payment',
    fields: {
      invoiceDate: 'Invoice Date',
      receiptNumber: 'Receipt #',
      paid: 'Paid',
      amount: 'Amount',
    },
  },
  roles: {
    title: 'Roles',
    description:
      'Manage permission settings for each role in your organization',
    types: {
      companyAdmin: 'Organization Admin',
      ceo: 'CEO',
      executive: 'Executive',
      manager: 'Manager',
      second_admin: 'Second Admin',
      employee: 'Employee',
    },
    fields: {
      allowBeseenApplication: 'Allow #BeSeen application',
      permissions: 'Permissions',
      selectAll: 'Select All',
    },
    validations: {
      typeIsRequired: 'Type is required',
      descriptionIsRequired: 'Description is required',
    },
  },
  demographics: {
    title: 'Demographics',
    description: 'Demographics description',
  },
  settings: {
    formLabels: {
      companyPurpose: "Organization's purpose",
      timezone: 'Timezone',
      suggestionBox: 'Name of Suggestion Box',
    },
    placeholders: {
      companyPurpose: "What is your organization's purpose?",
      timezone: 'Which timezone are you in?',
      suggestionBox: 'Share your awesome suggestions!',
    },
    sectionTitles: {
      socialMedia: 'Social Media',
      feed: 'Feed',
      insightsPermissions: 'Insights Permissions',
      nominations: 'Nominations',
      notifications:'Notifications',
      mfa: 'Multi-Factor Authentication',
      profanityFilter: 'Profanity Filter',
      integrations: 'Integrations',
      recognitionFrequency: 'Recognition Frequency',
    },
    sectionControlLabels: {
      socialMedia: {
        allowSharing: 'Allow Social Media Sharing',
        facebook: 'Facebook',
        linkedIn: 'LinkedIn',
        twitter: 'Twitter',
      },
      feed: { suggestionBox: 'Include Suggestion Box' },
      insightsPermissions: {
        organizationWideBeheard: 'Display Organization-Wide BeHeard',
        pulseSurvey: 'Display Pulse Survey',
        managerTeamBeheard: "Display Managers' Team BeHeard",
        managerTeamPulseSurvey: "Display Managers' Pulse Survey",
      },
      profanityFilter: { enableProfanityFilter: 'Enable Profanity Filter' },
      nominations: { enableNominations: 'Enable Nominations' },
      notifications: {
        enableEmailNotifications: 'Enable Email Notifications',
        enableSmsNotifications: 'Enable SMS/TEXT Notifications'
      },
      mfa: {
        requireMFA: 'Require Multi-Factor Authentication to all employees',
      },
      recognitionFrequency: { atLeastEvery: 'At least every {frequency} days' },
    },
    saveChanges: 'Save Changes',
    suggestionBoxTooltipTitle:
      'Give employees the opportunity to share their thoughts and ideas.',
    slackIntegration: {
      step1: "Step #1: Set your Slack's Workspace ID",
      workspaceIdOfSlack: 'Workspace ID of your Slack Organization',
      setWorkspaceID: 'Set Workspace ID',
      step2:
        'Step #2: Click the link below and allow Sparck to set Sparcky Bot to your Slack Workspace',
      allowSparckToIntegrateSlack:
        "Allow Sparck to integrate with your Slack's Workspace",
      alreadyClickedSparckBot:
        "Already clicked the button above and finished the process? {link} to reload the screen and check Slack's integration status!",
    },
    msTeamsIntegration: {
      stepsTutorialFindLink: {
        stepOne: {
          label: 'Click on the 3 dots button',
          description:
            'The 3 dots button is located right to the channel you created. Then click "Get link to channel"',
        },
        stepTwo: { label: 'Click copy!' },
      },
      stepsTutorialInstallBot: {
        stepOne: {
          label: 'Download Sparck Bot',
          customDescription: {
            partOne: 'Click "Download MS Teams Sparck Bot" in this screen',
            partTwo:
              "Go to MS Teams > Apps > Manage Your Apps > Upload an app to your org's app catalog",
          },
        },
        stepTwo: {
          label: 'Install App',
          description:
            'Find Sparck Bot in your file explorer, select and click on Sparck App in MS Teams',
        },
        stepThree: {
          label: 'Add to Team',
          description:
            'Click on the arrow down button and select "Add to a Team"',
        },
        stepFour: {
          label: 'Setting Up',
          description:
            'Type the name of your company and select the option with the Sparck Feed channel you created in previous steps and click "Set up a Bot"',
        },
      },
      stepsTutorialSetPolicies: {
        stepOne: {
          label: 'Adding Policies',
          customDescription: {
            partOne: 'Go to the page App Setup Policies of your MS Teams:',
            partTwo: 'Click the link "Global (Org-wide default)"',
            partThree: 'In the "Installed apps" section, click "Add apps"',
            partFour:
              'Type "Sparcky" on the search field and click "add" for the Sparcky app',
          },
        },
        stepTwo: {
          label: 'Pin App',
          customDescription: {
            partOne:
              'You should see the bot listed in the "Installed apps section"',
            partTwo:
              'Scroll down to the "Pinned apps" section and click on "+ Add apps" link',
            partThree:
              'Type "Sparcky" on the search field, click "add" for the Sparcky app and click "add" again to save this step',
            partFour:
              'Type "Sparcky" on the search field and click "add" for the Sparcky app',
          },
        },
        stepThree: {
          label: 'Done!',
          customDescription: {
            partOne:
              'You should see the bot listed in the "Pinned apps section"',
            partTwo:
              'Place the Sparcky in the position you want by dragging and dropping it',
            partThree: 'Then click the "Save" button at the bottom',
          },
        },
      },
      stepOne: 'Step #1: Paste your MS Teams Channel link',
      channelLinkLabel: 'Channel Link of your MS Teams Organization',
      save: 'Save',
      stepTwo:
        'Step #2: Click the link below to download your Sparck Bot (.zip file)',
      downloadMSTeamsSparckBot: 'Download MS Teams Sparck Bot',
      frequentlyAskedQuestions: 'Frequently Asked Questions',
      whereToFindChannelLink: 'Where do I find my MS Teams Channel Link?',
      whatToDoNext: 'I downloaded my Sparck Bot. What do I do now?',
      sparckBotByDefault:
        "I want my organization's employees to have Sparck Bot by default. How do I do that?",
    },
    settingsIntegration: {
      sparckFeedChannelDefined: 'Sparck Feed channel already defined',
      integrate: 'Integrate',
      remove: 'Remove',
      integrationSectionTitles: {
        slackBot: 'Slack Bot Integration',
        msTeams: 'MS Teams Bot Integration',
      },
      slackBotIntegrationDialogTitle: 'Slack Bot Integration',
      msTeamsBotIntegrationDialogTitle: 'MS Teams Bot Integration',
      slackIntegrationConfirmDelete: {
        itemName: 'Sparcky Bot Slack Integration',
        actionLabel: 'remove',
      },
      msTeamsIntegrationConfirmDelete: {
        itemName: 'Sparcky Bot MS Teams Integration',
        actionLabel: 'remove',
      },
    },
    snackbarSuccess: { saved: 'Settings saved!' },
  },
  signup: {
    createAccount: 'Create Account',
    secondaryActionButtonLabels: { backToLogin: 'Back to Login' },
    description:
      'We are excited to help you launch your complimentary #BeHeard Survey. Get started for free and upgrade later. Please provide the following information to create your organization account. No credit card required.',
    confirmEmailDialog: {
      title: 'Almost there!',
      content:
        'Before we create your account, quick email check - does everything look correct?',
      changeEmail: 'Change email',
      emailIsCorrect: 'Email is correct',
    },
    formLabels: {
      companyName: 'Organization Name',
      industry: 'Industry',
      websiteUrl: 'Website URL',
      password: 'Password',
      sendEmail: 'Send e-mail notifying that the organization was created.',
    },
    termsAndConditionsLink: {
      firstLine: 'By clicking Create Account, you agree to the',
      secondLine: 'Sparck Terms and Conditions',
    },
    validations: {
      sparckAdminSchema: {
        companyNameIsRequired: 'Organization Name is required',
        invalidUrlFormat: 'Invalid URL format',
        websiteUrlIsRequired: 'Website URL is required',
        industryIsRequired: 'Industry is required',
      },
      employeesSchema: {
        companyNameIsRequired: 'Organization Name is required',
        invalidUrlFormat: 'Invalid URL format',
        websiteUrlIsRequired: 'Website URL is required',
        industryIsRequired: 'Industry is required',
        firstNameIsRequired: 'First name is required',
        lastNameIsRequired: 'Last name is required',
        phoneNumberInvalidFormat: 'Must be a valid US phone number',
        phoneNumberIsRequired: 'Phone Number is required',
        invalidEmailFormat: 'Invalid email format',
        emailIsRequired: 'Email is required',
        passwordCapitalLetter:
          'Password must include at least one capital letter.',
        passwordLowercaseLetter:
          'Password must include at least one lowercase letter.',
        passwordNumber: 'Password must include at least one number.',
        passwordSpecialCharacter:
          'Password must include at least one special character (+, #, !, ? or @).',
        passwordIsRequired: 'Password is required',
      },
    },
    snackbar: {
      success: {
        company: 'Organization',
        createdSuccessfully: 'created successfully!',
      },
    },
  },
  login: {
    snackbar: {
      success: {
        checkEmail: 'Please check your email for further instructions.',
        useNewPassword: 'Please use your new password to log in.',
        passwordUpdated: 'Password updated successfully!',
      },
    },
    changePassword: {
      title: 'Change Password',
      formLabels: {
        currentPassword: 'Current Password',
        newPassword: 'New Password',
      },
      changePasswordButton: 'Change Password',
      backToLoginButton: 'Back to login',
    },
    loginForm: {
      welcome: 'Welcome!',
      backupCodeInstruction:
        'Enter one of the backup codes that you saved when you enabled Multifactor Authentication',
      labels: {
        password: 'Password',
        verificationCode: 'Verification Code',
        useDifferentMFAMethod: 'Use a different MFA method',
        backupCode: 'Enter Backup Code',
        authApp: 'Auth App',
        useBackupCode: 'Use Backup Code',
      },
      resendVerificationCodeTooltip: 'Resend verification code to your phone',
      useBackupCode: 'Use a backup code',
      dontHaveBackupCode: "I don't have a backup code",
      loginButton: 'Login',
      forgotPasswordButton: 'Forgot my password',
    },
    recoverPassword: {
      setPassword: 'Set your password',
      forgotPassword: 'Forgot your password?',
      enterNewPassword: 'Enter your new password.',
      formLabels: { password: 'Password' },
      enterEmail: 'Enter your email address to recover your password.',
      setPasswordButton: 'Set Password',
      recoverPasswordButton: 'Recover Password',
      backToLoginButton: 'Back to login',
    },
  },
  mfa: {
    enable: 'Enable',
    disable: 'Disable',
    viewOrDownload: 'View/Download',
    primary: 'PRIMARY',
    makePrimary: 'Make Primary',
    gotIt: 'Got it!',
    enterPassword: 'Enter Your Password',
    enterVerificationCode: 'Enter Verification Code',
    mfaFactors: {
      authApp: {
        title: 'Authenticator App',
        description:
          'Use an authenticator app to generate one time security codes (most secure).',
        steps: {
          scanQRCode: 'Scan QR Code',
          success: 'You have successfully enabled Auth App!',
        },
      },
      sms: {
        title: 'SMS',
        description: 'Use your mobile phone to receive security codes.',
        steps: {
          smsSetup: 'SMS Setup',
          success: 'You have successfully enabled SMS!',
        },
      },
      email: {
        title: 'Email',
        description: 'Use your email to receive security codes (least secure).',
        steps: { success: 'You have successfully enabled Email!' },
      },
      backupCodes: {
        title: 'Backup Codes',
        description:
          'Use backup codes when you are unable to use another method.',
        steps: { success: 'Your Backup Codes' },
        actions: { download: 'Download', print: 'Print', copy: 'Copy' },
      },
      dialogTitle: {
        setup: 'MFA {selectedFactor} Setup',
        disable: 'MFA Disable {selectedFactor}',
      },
      disableDialogContent:
        'Are you sure you want to disable {selectedFactor} as a multi-factor authentication method?',
    },
    enterCredentials: 'To continue, please enter your password',
    enterVerificationCodeMessage: {
      email: 'We sent a verification code to this email: {email}.',
      sms: 'We sent a verification code via SMS to the number ending in {phoneNumberLastDigits}.',
      authenticatorApp:
        'Enter the verification code generated by your authenticator app.',
    },
    setupComponentTitle: 'Select authentication options',
    setupComponentDescription:
      "Each time you sign in to your Sparck account, you'll need your password and a verification code.",
    snackbars: {
      success: {
        mfaDisabled:
          '{selectedFactor} was successfully disabled as an MFA method!',
      },
      error: {
        verificationCode:
          'We could not verify the code you entered. Make sure to type a valid verification code',
      },
    },
    next: 'Next',
    youNeedAnAuthApp:
      'You will need an authentication app to complete this process such as {example}',
    scanQRCode: 'Scan the QR code into your app',
    whenYouAreDone: "When you're done scanning, click 'Next'",
    successfullyEnabled: 'Successfully enabled',
    yourBackupCodes: 'Your backup codes',
    yourBackupCodesDescription:
      'Save these emergency backup codes and store them somewhere safe. If you lose your phone or authentication device, you can use backup codes to sign in.',
    footerMessage: 'Authentication codes will be texted to the number you set.',
    validations: { verificationCodeRequired: 'Verification code is required' },
    enterSMSCode: 'Enter the 6-digit code sent to you via SMS',
    enterAuthAppCode: 'Enter the 6-digit code generated by your app',
    enterEmailCode: 'Enter the 6-digit code sent to your email',
    labels: { enterYourCode: 'Enter your code' },
    resendCode: 'Resend Code',
    enterPhoneNumber:
      'Please, enter the number you want to use to receive the verification codes.',
    mfaOptions: { authApp: 'Authenticator App', sms: 'Text Message (SMS)' },
    weRecommendAnApp:
      'We recommend a time-based password app to keep our customers secure.',
    mfaRequired:
      '{organizationName} requires Multi-Factor Authentication (MFA)',
    mfaRequiredDescription:
      "{organizationName} requires every employee to set up Multi-Factor Authentication for use with the Sparck Application. This adds an additional layer of security and protection for your and your company's information and is very easy to set up. Just select the method you would like to use below and we will walk you through setting it up. If you decide you want to use a different method in the future, you can update your MFA settings from your Profile screen.",
    startMFASetup: 'Start MFA Setup',
    takeMeToSparck: 'Take me to Sparck!',
    backupCodesDialog: {
      title: 'Your backup codes',
      resetSuccessAlert: {
        title: 'Sucess!',
        description: 'Your backup codes have just been reset successfully.',
      },
      resetBackupCodes: 'Reset Backup Codes',
      submit: 'Submit',
    },
  },
  marketing: {
    snackbar: {
      success: { surveyAddedToCart: 'Survey added to your cart successfully' },
    },
    testimonialFlexCare:
      "At FlexCare, our organization culture is really important to us because our employees are the heart of our operation. As we began to scale, Sparck's #BeHeard Premium Report helped us align our priorities based on our organizational effectiveness and what was most important to our employees. We were able to outline an organization-wide strategy yet personalize the results using location and departments while maintaining employee confidentiality. This served as a great coaching tool for our managers to give them better insight into how to lead a more productive and engaged team. I highly recommend this survey. It was an easy process with actionable insights!",
    testimonialTeledyneTechnologies:
      "Sparck's free Employee Engagement Cost Analysis tool gave us an easy and effective way to communicate how an increase in engagement would directly impact our organization's performance. The customized one-page overview was a great addition to our executive meeting and gave us a way to look at our specific numbers and outline a solution. I highly recommend this tool to HR professionals who are mapping out their internal strategies and want to upgrade to the #BeHeard Premium Report.",
    upgradeNow: 'Upgrade Now',
    marketingHeader: {
      upgradeToday: 'Upgrade Today!',
      getMoreStrategicLook:
        'Get a more strategic look at your #BeHeard Survey results with personalized recommendations and demographic insights.',
      beheardPremiumReportDescription:
        "Our statistically valid #BeHeard Premium Report will give you a deeper understanding of your results and actionable steps to increase your employee engagement at every level of your organization. Plus, you'll also receive critical insights and tailored advice when you upgrade to the Premium Report. Check out the details below, and if you want to chat, we are here for you.",
      upgradeNow: 'Upgrade Now',
    },
  },
  shoppingCart: {
    yourShoppingCartItems: 'Your Shopping Cart items',
    employee: 'employee',
    employees: 'employees',
    removeFromCart: 'Remove from Cart',
    proceedToPayment: 'Proceed to Payment',
    noItems: 'You have no items in your shopping cart.',
    productTitle: '{productName} - {itemToUpgradeName} for {employeesQuantity}',
    total: 'Total',
    snackbar: {
      success: { itemRemoved: 'Item removed from your cart successfully' },
      error: { couldNotRemoveItem: 'Item could not be removed from your cart' },
    },
  },
  notifications: {
    survey: {
      isNowOpened: '{surveyName} is now opened!',
      isClosed: '{surveyName} is closed!',
      isReopened: '{surveyName} is reopened!',
      wasExtended: '{surveyName} was extended!',
      canNowBeUpgraded: '{surveyName} can now be upgraded!',
      needsToBeFinished:
        'You have a {surveyName} survey that needs to be finished!',
      lastDayToFinish: 'Today is the last day to finish  {surveyName}!',
      thankYou:
        'Thank you so much for your participation in {surveyName} survey!',
      fullReportAvailable:
        'Congratulations! Your full {surveyName} #BeHeard Report is now available!',
      freeReportAvailable:
        'Your free {surveyName} #BeHeard Report is now available',
    },
    pulseSurvey: {
      isNowOpened: '{pulseSurveyName} is now opened!',
      isClosed: '{pulseSurveyName} is closed!',
      isReopened: '{pulseSurveyName} is reopened!',
      wasExtended: '{pulseSurveyName} was extended!',
      needsToBeFinished:
        'You have a {pulseSurveyName} pulse survey that needs to be finished!',
      lastDayToFinish: 'Today is the last day to finish {pulseSurveyName}!',
      thankYou:
        'Thank you so much for your participation in {pulseSurveyName} pulse survey!',
    },
    sparck: {
      youRecognized: "You've recognized {sparckedUser}!",
      recognizedBy: "You've been recognized by {sparckOwner}!",
      takeActionForRecognition:
        'Take Action for this recognition from {sparckOwner} to {sparckedUser}!',
      wasAnswered: 'Your Sparck was answered by {sparckedUser}:',
      rewardRedeemed: '{sparckedUser} has redeemed their reward',
    },
    mentions: {
      title: "You've been mentioned by {mentioningUser} in a {sourceType}!",
    },
    suggestions: {
      newReply: {
        title: 'You received a {newReply} regarding a suggestion',
        newReplyLabel: 'new reply',
      },
      newSuggestion: { title: 'There is a new suggestion from an employee.' },
    },
    actionLabels: {
      viewSurvey: 'View Survey',
      viewPulseSurvey: 'View Pulse Survey',
      viewReport: 'View Report',
      viewSparck: 'View Sparck',
      takeAction: 'Take Action',
      viewPost: 'View Post',
      viewComment: 'View Comment',
      goToChat: 'Go to Chat',
      goToSuggestions: 'Go to Suggestions',
    },
    yourNotifications: 'Your Notifications',
    markAllRead: 'Mark All Read',
    noNotifications: ' You have no notifications.',
  },
  managerCertification: {
    managerCertificationData: {
      roadmap: {
        dayOne: {
          title: 'Welcome To The Sparck Certification!',
          description:
            'Welcome to Section 1 of the Sparck Employee Recognition Certification. To kick things off, we are going to ease into the week and step up to the start line with a quick introduction to the 3-Minute Sparck Sprint. To successfully complete Section 1, you must watch the training video and participate in our daily quiz. Have fun!',
          sections: {
            learnItDescription:
              'This is where the magic begins. In this section, you can easily access the training video and all the resources you need to develop these new skills.',
            masterItDescription:
              "I hope you enjoyed the first video! It's time to test your knowledge so far and put the wheels into motion with the action areas Anna assigned. We can't wait to see who you choose to recognize!",
          },
          tasks: {
            trainingVideoName: 'Section 1 Training Video',
            challengeName: 'Section 1 Challenge',
          },
        },
        dayTwo: {
          title: 'Minute 1: Reflect - Timing is Everything!',
          description:
            "Welcome to Section 2 of the Certification! Yesterday we provided an introduction to the 3-minute Sparck Sprint and now it's time to pull back the layers. Today you will dive into Minute 1 of the Sparck Sprint: Reflect. To successfully complete Section 2, watch the training video, go on the Sparck Tour, and take the quiz. Enjoy!",
          sections: {
            practiceItDescription:
              "This is a new section that will be incorporated for the rest of the week. Anna is going to take what you learned in the training video and show you how our technology helps you leverage these lessons. Buckle up, it's time for the tour!",
            masterItDescription:
              "It's time to take what you've learned and put it to the test with the Section 2 Quiz. This isn't to trip you up but help you master each minute of the Sprint. Be sure to log in to your Sparck account and begin the reflection process with your personalized team insights.",
          },
          tasks: {
            trainingVideoName: 'Section 2 Training Video',
            challengeName: 'Section 2 Challenge',
            challengeDescription:
              'As you know, the goal of reflection is to identify who, why, and when you need to recognize your team. Leverage your Sparck insights and gain immediate recognition awareness.',
          },
        },
        dayThree: {
          title: 'Minute 2: Connect - Personalize The Experience',
          description:
            "Welcome to Section 3 of the Certification! Today will focus on Minute 2 of the Sparck Sprint: Connect. To successfully complete Section 3, watch the training video, go on the Sparck Tour, and take the Quiz. We have resources like a breakdown of the different Appreciation Styles and a list of F.R.O.G. questions (you'll know what this means after the training). These will help you elevate and strengthen your employee conversations. Be sure to check out the comedy video as well, you're guaranteed to smile!",
          sections: {
            masterItDescription:
              "Strengthen your knowledge and be sure to take the Section 3 Quiz. But don't stop there, based on who you are planning to recognize at the end of the certification, log in and check out their Sparck Appreciation Style!",
          },
          tasks: {
            trainingVideoName: 'Section 3 Training Video',
            sparckAppreciationStyles: 'Sparck Appreciation Styles',
            sparckAppreciationStylesDescription:
              'Learn the different ways your employees like to be appreciated! Check out the different Sparck Appreciation Styles {link}. To learn more about the different Appreciation Styles (and get a big laugh), watch this comedy video created by Betsaida LeBron, team building strategist, management coach, and the founder of ImprovEQ. Click below to watch!',
            challengeName: 'Section 3 Challenge',
            challengeDescription:
              "Do you think you already know your team's appreciation styles? See if you're right.",
          },
        },
        dayFour: {
          title: 'Minute 3: Recognize - Time To Take Action',
          description:
            "Welcome to Section 4 of the Certification! Today we will be focusing on Minute 3 of the Sparck Sprint: Recognize. You're almost to the finish line! Now that you know how to Reflect and Connect, the final piece is perfecting the recognition. To successfully complete Section 4, watch the training video, go on the Sparck Tour, and complete the Quiz. Don't forget to access the highlights in your Training Resources. Have fun!",
          sections: {
            masterItDescription:
              'You are so close, test your knowledge by taking the Section 4 Quiz. And after today, you may be curious to uncover what rewards would mean the most to your team. Log in to your account and explore their profiles!',
          },
          tasks: {
            trainingVideoName: 'Section 4 Training Video',
            challengeName: 'Section 4 Challenge',
            challengeDescription:
              'Think about the person you are going to recognize this week. If you had $10 to spend, what would you get them? You are just a few clicks away from knowing.',
          },
        },
        dayFive: {
          title: "It's Time To Recognize Your Employee!",
          description:
            "Welcome to Section 5 of the Certification. You're about to cross the finish line! Use what you've learned and combine the magical pieces of the 3-minute Sparck Sprint:  Connect, Reflect, and Recognize to create a meaningful recognition experiences. Watch the training video, take the Sparck Tour, and complete your final quiz. To get more inspiration check out your training resources. {rememberMessage}",
          rememberMessage:
            "And Remember:  In addition to the daily exercises, to officially get certified you need to take what you've learned and recognize, or 'Sparck', one of your employees. We can't wait to hear the story!",
          sections: {
            masterItDescription:
              "You made it, I hope you enjoyed the sprint! Cross the finish line  with the Section 5 Quiz and share your 'Sparck' story. If you need a few ideas before you take action, be sure to use the insights Anna shared during the tour and log in to your account!",
          },
          tasks: {
            trainingVideoName: 'Section 5 Training Video',
            challengeName: 'Section 5 Challenge',
            challengeDescription:
              "Take a look behind the curtain and identify how to recognize all your team members at the Right Time, Right Way, and Right Reward! It's time to Sparck the workplace.",
          },
        },
        trainingResources: 'Access Training Resources',
        takeTheQuiz: 'Take the Quiz',
        watchSparckTour: 'Watch the Sparck Tour',
      },
      learnIt: 'Learn It',
      practiceIt: 'Practice It',
      masterIt: 'Master It',
    },
    celebrationBoard: {
      title: "Let's Celebrate Your Certification!",
      description:
        "Celebrate with us! It's always more fun to share in your excitement so everyone who completes the Certification will have their names added to the board. We can't wait to see the beautiful impact you will have in your organization Let's Sparck the world!",
      congratulations: 'Congratulations!',
    },
    leaderboard: {
      title: 'Have fun and see where you land on the Leaderboard!',
      header: 'Leaderboard',
    },
    managerCertificationComponent: {
      minutes: {
        minuteOneDescription:
          'Minute 1 teaches you to Reflect and capture recognition opportunities at the Right Time. Timing is everything!',
        minuteTwoDescription:
          "Minute 2 teaches you to Connect and identify the Right Way to recognize. It's critical to learn your employee's recognition preferences.",
        minuteThreeDescription:
          "Minute 3 teaches you to Recognize with the Right Reward. Personalization is key, don't be generic.",
      },
      secretComponents: {
        rightTime: 'Right Time',
        rightWay: 'Right Way',
        rightReward: 'Right Reward',
      },
      sections: {
        welcome: {
          title: 'Employee Recognition Certification',
          description: {
            firstParagraph:
              'Welcome, ready to have some fun? In this certification you are going to learn how to incorporate our 3-Minute Sparck Sprint into your day. It is designed to equip you to provide effective and personalized recognition. This will not only increase employee happiness but productivity and overall commitment. This Certification was created to be completed over a 5 Day period but feel free to dive right in and complete everything at your own pace!',
            middleParagraph:
              'Our 3-minute Sparck Sprint will tee you up to deliver the right type of recognition, every time.',
            lastParagraph:
              'There is an art to recognition that has never been taught, these are the secret components you will be ready for:',
          },
        },
        realLifeSparckStory: {
          title: 'A Real-Life Sparck Story',
          subtitle: 'Want to see the impact personalized recognition can have?',
          description:
            'Watch how the 3-minute Sparck Sprint equipped our CEO, Anna Straus, to recognize Abigail Kidd at the Right Time, in the Right Way, with the Right Reward. This story will be deconstructed throughout the certification to bring the training to life!',
        },
        sneakPeek: {
          title: 'Get A Sneak Peek Of The Sparck Certification',
          description:
            "You are about to embark on an exciting journey with our Employee Recognition Certification! Get a sneak peek of what's to come as our CEO Anna Straus coaches you through the 3-minute Sparck Sprint!",
        },
        roadmap: {
          title: 'Certification Roadmap',
          description:
            "In just 10 minutes a day, over a 5-day period you will get certified in Sparck's Employee Recognition program. This toolkit will spotlight each day as you go through the program to provide you with everything that you need to be successful. See what's in store and access the 5-Section Roadmap by clicking the cards below.",
          enter: 'ENTER',
        },
      },
    },
    submitStoryCard: {
      title: 'Submit Your Story',
      description:
        'Share all the exciting details with us as we celebrate your dash across the finish line! If you have a story of a time someone at work has made you feel truly seen, you can share this story as well!',
      submitStoryForm: {
        snackbarSuccess: 'Your story was successfully submitted',
        tellYourStory: 'Tell your story',
        permissionToShareOnSocialMedia:
          'Do we have your permission to share your recognition on social media?',
        canWeTagYouOnSocialMedia:
          'Can we tag you? If so, please provide your social media handles. (IG, Twitter, and LinkedIn)',
      },
    },
    managerCertificationTask: {
      day: 'Section',
      clickForResource: 'Click {link} to download {name}',
      trainingResourceForSection: 'Training Resource for Section {section}',
      clickForVideoScript: 'Click {link} for the video script',
      frogQuestions: 'F.R.O.G. questions',
      takeTheQuiz: 'Take the quiz!',
      takeMeToTheChallenge: 'Take me to the challenge!',
    },
    managerCertificationTasks: {
      trainingVideo: 'training video',
      trainingVideos: 'training videos',
      trainingResource: 'training resource',
      trainingResources: 'training resources',
      quiz: 'quiz',
      quizzes: 'quizzes',
      challenge: 'challenge',
      challenges: 'challenges',
    },
    managerCertificationFormBuilder: {
      termsOfCommunicationFirstParagraph:
        'Sparck is committed to protecting and respecting your privacy, and we’ll only use your personal information to administer your account and to provide the products and services you requested from us. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you. If you consent to us contacting you for this purpose, please tick the box below to say how you would like us to contact you:',
      termsOfCommunicationMiddleParagraph:
        'You can unsubscribe from these communications at any time. For more information on how to unsubscribe, our privacy practices, and how we are committed to protecting and respecting your privacy,please review our Privacy Policy.',
      termsOfCommunicationLastParagraph:
        'By clicking submit below, you consent to allow Sparck to store and process the personal information submitted above to provide you the content requested.',
      agreeToReceiveCommunications:
        'I agree to receive other communications from Sparck',
    },
    managerCertificationQuizDialog: {
      title: 'Section {section} quiz',
      requiredField: 'Please complete this required field',
      snackbarSuccess: 'Your answers were submitted successfully!',
      quizQuestions: {
        sectionOne: {
          whoAreYouGoingToRecognize:
            'Who are you going to recognize at the end of this certification?',
          runningBuddy:
            'Who did you select as your "running buddy"? (optional)',
          threeComponentsOfThreeMinuteSparckSprint:
            'What are the three components of the three-minute Sparck Sprint?',
          threeAreasToDeliverEffectiveRecognition:
            'The three areas you need to address to deliver effective recognition are The Right Time, The Right Way, and The Right ____.',
          feedbackOrSuggestionsSection1:
            'Any feedback, suggestions, or ideas you want to share about the training? (optional)',
        },
        sectionTwo: {
          didThePersonChange:
            'Did the person you chose to recognize change based on this reflection time? If so, to who?',
          goalDuringYourReflection:
            'The goal during your reflection time is to identify Who, Why, and ______ to recognize someone.',
          feedbackOrSuggestionsSection2:
            'Any thoughts, suggestions, or feedback you want to share to make this training more beneficial? (optional)',
        },
        sectionThree: {
          gInTheFrogStandsFor:
            'The "G" in the F.R.O.G. conversational framework stands for what?',
          yourSparckAppreciationStyle:
            'What is your Sparck Appreciation Style? What do you agree with and what would you change?',
          appreciationStyleOfTheEmployeeYouAreGoingToRecognize:
            'What is the Sparck Appreciation Style for the employee you are going to recognize?',
          feedbackOrSuggestionsSection3:
            'Would you like to share any feedback, suggestions, or ideas to make this training better? (optional)',
        },
        sectionFour: {
          howWouldYouDescribeTheTwoTypesOrRewards:
            'After this training, how would you describe the two types of rewards you can offer?',
          recognitionNeedsToBeFrequentSpecificAnd:
            'Recognition is positive reinforcement that needs to be frequent, specific, and _________.',
          whatAreYouRecognizingThemForSection4:
            'Think about the person you are going to recognize, what are you recognizing them for?',
          feedbackOrSuggestionsSection4:
            'Would you like to share any feedback, suggestions, or ideas to make this training better?',
        },
        sectionFive: {
          mostValuableThingYouLearnedThisWeek:
            'What is the most valuable thing you learned this week?',
          whatWouldYouChangeAboutTheCertification:
            'What would you change about the certification?',
          whatDayAreYouGoingToRecognizeThem:
            'Reflect: Now that you know who you are going to recognize, what day are you going to recognize them?',
          howAreYouGoingToRecognizeThem:
            'Connect: Now that you know their recognition preferences, how are you going to recognize them?',
          whatAreYouRecognizingThemForSection5:
            'Recognize: Now that you know your words should be genuine and specific to their impact, what are you recognizing them for?',
          whatIsYourRecognitionPreference:
            "We love to celebrate those who are certified. What's your recognition preference?",
          canWeTagYouInSocialMedia:
            'Can we tag you in our social media post when you complete it? (Type Yes or No)',
          testimonial: {
            description:
              "Hey Sparck-Star! You're on your way to being certified. If you feel that this training has been valuable and have a testimonial we can share with other managers, we'd love to hear it!",
            label: 'Your Testimonial',
          },
        },
      },
    },
    managerCertification5DayRoadmap: {
      title: '5-Day Certification Roadmap',
      description:
        "This toolkit was designed to be over a 5-Day period but you don't need to wait! You can complete the training at your own pace. It will spotlight each day as you go through the program to provide you with everything that you need to be successful. See what's in store and access the 5-Day Roadmap by clicking the button below.You will have a chance to read more of these stories as you near completion of the Certification.",
      downloadRoadmap: 'Download the roadmap',
    },
    submit: 'Submit',
    noOneHasCompletedTheCertification:
      'No one has completed the certification yet.',
  },
  mobile: {
    feed: {
      floatingButton: {
        dialogTitle: 'About Us'
      }
    },
  }
}
