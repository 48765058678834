export default {
  actions: {
    create: 'Crear {name}',
    edit: 'Editar {name}',
    save: 'Guardar {name}',
  },
  routes: {
    approvals: 'Aprobaciones',
    beheardReport: 'Informe #BeHeard',
    beheardSurvey: 'Encuesta #BeHeard',
    budget: 'Presupuestos',
    changePassword: 'Cambiar Contraseña',
    companies: 'Organizaciones',
    companyInsights: 'Métricas de la Organización',
    companyReporting: 'Informe Organizacional',
    coreValues: 'Valores Fundamentales',
    dashboard: 'Tablero',
    demographics: 'Demografía',
    employees: 'Empleados',
    feed: 'Feed',
    inappropriateContent: 'Contenido Inapropiado',
    insights: 'Métricas',
    invoices: 'Facturas',
    login: 'Iniciar sesión',
    logout: 'Cerrar sesión',
    marketing: 'Mejorar',
    managePulseSurvey: 'Gestionar Encuesta Pulse',
    managerCertification: 'Certificación de Empleados',
    managerCertificationTask: 'Tarea de Certificación de Reconocimiento de Empleados',
    nominationsManagement: 'Nominaciones',
    pulseSurveyInsights: 'Insights',
    pulseSurveyOverview: 'Encuestas Pulse',
    manageSurvey: ' ',
    surveyOverview: 'Encuestas #BeHeard',
    company: 'Mi Organización',
    myRewards: 'Mis Recompensas',
    mySettings: 'Mis Configuraciones',
    notifications: 'Notificaciones',
    onboarding: 'Introducción',
    onboardingQuestions: 'Preguntas de Introducción',
    people: 'Personas',
    rewards: 'Beneficios',
    profile: 'Perfil',
    profileQuestions: 'Preguntas de Perfil',
    pulseSurveys: 'Encuestas Pulse',
    recognitionPrograms: 'Eventos de Reconocimiento',
    roles: 'Responsabilidades',
    settings: 'Configuraciones',
    shareReport: 'Compartir Informe',
    shoppingCart: 'Carrito de Compras',
    signup: 'Crear Cuenta',
    recognize: '¡Reconocer!',
    sparckDisplay: 'Mostrar Sparck',
    sparckMemories: 'Memorias Sparck',
    suggestionChat: 'Chat de Sugerencias',
    suggestions: 'Sugerencias',
    survey: 'Encuesta',
    surveys: 'Encuestas',
    takeAction: 'Tomar Acción',
    teamInsights: 'Métricas del Equipo',
    virginExperiencesSettings: 'Virgin Experiences',
  },
  industries: {
    accounting: 'Contabilidad',
    airlinesAviation: 'Aerolíneas/Aviación',
    alternativeDisputeResolution: 'Resolución Alternativa de Disputas',
    alternativeMedicine: 'Medicina Alternativa',
    animation: 'Animación',
    apparelFashion: 'Ropa y Moda',
    architecturePlanning: 'Arquitectura y Planificación',
    artsCrafts: 'Artes y Oficios',
    automotive: 'Automotriz',
    aviationAerospace: 'Aviación y Aeroespacial',
    banking: 'Banca',
    biotechnology: 'Biotecnología',
    broadcastMedia: 'Medios de Comunicación',
    buildingMaterials: 'Materiales de Construcción',
    businessSuppliesEquipment: 'Suministros y Equipos Comerciales',
    cannabis: 'Cannabis',
    capitalMarkets: 'Mercados de Capital',
    chemicals: 'Productos Químicos',
    civicSocialOrganization: 'Organización Cívica y Social',
    civilEngineering: 'Ingeniería Civil',
    commercialRealEstate: 'Bienes Raíces Comerciales',
    computerNetworkSecurity: 'Seguridad de Redes y Computadoras',
    computerGames: 'Juegos de Computadora',
    computerHardware: 'Hardware de Computadora',
    computerNetworking: 'Redes de Computadoras',
    computerSoftware: 'Software de Computadora',
    construction: 'Construcción',
    consumerElectronics: 'Electrónica de Consumo',
    consumerGoods: 'Bienes de Consumo',
    consumerServices: 'Servicios al Consumidor',
    cosmetics: 'Cosméticos',
    dairy: 'Lácteos',
    defenseSpace: 'Defensa y Espacio',
    design: 'Diseño',
    eLearning: 'Aprendizaje en Línea',
    educationManagement: 'Gestión Educativa',
    electricalElectronicManufacturing: 'Fabricación Eléctrica y Electrónica',
    entertainment: 'Entretenimiento',
    environmentalServices: 'Servicios Ambientales',
    eventServices: 'Servicios de Eventos',
    executiveOffice: 'Oficina Ejecutiva',
    facilitiesServices: 'Servicios de Instalaciones',
    farming: 'Agricultura',
    financialServices: 'Servicios Financieros',
    fineArt: 'Bellas Artes',
    fishery: 'Pesca',
    foodBeverages: 'Alimentos y Bebidas',
    foodProduction: 'Producción de Alimentos',
    fundraising: 'Recaudación de Fondos',
    furniture: 'Muebles',
    gamblingCasinos: 'Juegos de Azar y Casinos',
    glassCeramicsConcrete: 'Vidrio, Cerámica y Concreto',
    governmentAdministration: 'Administración Pública',
    governmentRelations: 'Relaciones Gubernamentales',
    graphicDesign: 'Diseño Gráfico',
    healthWellnessFitness: 'Salud, Bienestar y Fitness',
    higherEducation: 'Educación Superior',
    hospitalHealthCare: 'Hospitales y Atención Médica',
    hospitality: 'Hospitalidad',
    humanResources: 'Recursos Humanos',
    importExport: 'Importación y Exportación',
    individualFamilyServices: 'Servicios Individuales y Familiares',
    industrialAutomation: 'Automatización Industrial',
    informationServices: 'Servicios de Información',
    informationTechnologyServices: 'Servicios de Tecnología e Información',
    insurance: 'Seguros',
    internationalAffairs: 'Asuntos Internacionales',
    internationalTradeDevelopment: 'Comercio Internacional y Desarrollo',
    internet: 'Internet',
    investmentManagement: 'Gestión de Inversiones',
    investmentBanking: 'Banca de Inversión',
    judiciary: 'Judicatura',
    lawEnforcement: 'Aplicación de la Ley',
    lawPractice: 'Práctica de Derecho',
    legalServices: 'Servicios Legales',
    legislativeOffice: 'Oficina Legislativa',
    leisureTravelTourism: 'Ocio, Viajes y Turismo',
    libraries: 'Bibliotecas',
    logisticsSupplyChain: 'Logística y Cadena de Suministro',
    luxuryGoodsJewelry: 'Bienes de Lujo y Joyería',
    machinery: 'Maquinaria',
    managementConsulting: 'Consultoría de Gestión',
    maritime: 'Marítimo',
    marketResearch: 'Investigación de Mercado',
    marketingAdvertising: 'Marketing y Publicidad',
    mechanicalOrIndustrialEngineering: 'Ingeniería Mecánica o Industrial',
    mediaProduction: 'Producción de Medios',
    medicalDevice: 'Dispositivo Médico',
    medicalPractice: 'Práctica Médica',
    mentalHealthCare: 'Atención de Salud Mental',
    military: 'Militar',
    miningMetals: 'Minería y Metales',
    motionPicturesFilm: 'Cine y Películas',
    museumsInstitutions: 'Museos e Instituciones',
    music: 'Música',
    nanotechnology: 'Nanotecnología',
    newspapers: 'Periódicos',
    nonProfitManagement: 'Gestión de Organizaciones sin Fines de Lucro',
    oilEnergy: 'Petróleo y Energía',
    onlineMedia: 'Medios en Línea',
    outsourcingOffshoring: 'Outsourcing/Offshoring',
    packageFreightDelivery: 'Entrega de Paquete/Flete',
    packagingContainers: 'Empaque y Contenedores',
    paperForestProducts: 'Papel y Productos Forestales',
    performingArts: 'Artes Escénicas',
    pharmaceuticals: 'Farmacéutica',
    philanthropy: 'Filantropía',
    photography: 'Fotografía',
    plastics: 'Plásticos',
    politicalOrganization: 'Organización Política',
    primarySecondaryEducation: 'Educación Primaria/Secundaria',
    printing: 'Impresión',
    professionalTrainingCoaching: 'Capacitación y Coaching Profesional',
    programDevelopment: 'Desarrollo de Programas',
    publicPolicy: 'Política Pública',
    publicRelationsCommunications: 'Relaciones Públicas y Comunicaciones',
    publicSafety: 'Seguridad Pública',
    publishing: 'Publicación',
    railroadManufacturer: 'Fabricante de Ferrocarriles',
    ranching: 'Ganadería',
    realEstate: 'Bienes Raíces',
    recreationalFacilitiesServices: 'Instalaciones y Servicios Recreativos',
    religiousInstitutions: 'Instituciones Religiosas',
    renewablesEnvironment: 'Energías Renovables y Medio Ambiente',
    research: 'Investigación',
    restaurants: 'Restaurantes',
    retail: 'Venta al por menor',
    securityInvestigations: 'Seguridad e Investigaciones',
    semiconductors: 'Semiconductores',
    shipbuilding: 'Construcción Naval',
    sportingGoods: 'Artículos Deportivos',
    staffingRecruiting: 'Personal y Reclutamiento',
    supermarkets: 'Supermercados',
    telecommunications: 'Telecomunicaciones',
    textiles: 'Textiles',
    thinkTanks: 'Think Tanks',
    tobacco: 'Tabaco',
    translationLocalization: 'Traducción y Localización',
    transportationTruckingRailroad: 'Transporte/Camión/Ferrocarril',
    utilities: 'Servicios Públicos',
    ventureCapitalPrivateEquity: 'Capital de Riesgo y Capital Privado',
    veterinary: 'Veterinaria',
    warehousing: 'Almacenaje',
    wholesale: 'Venta al por mayor',
    wineSpirits: 'Vino y Bebidas Espirituosas',
    wireless: 'Inalámbrico',
    writingEditing: 'Escritura y Edición'
  },
  topbar: {
    viewReleaseNotes: 'Ver Notas de Lanzamiento',
    termsOfService: 'Ver Términos de Servicio',
    privacyPolicy: 'Ver Política de Privacidad',
  },
  footer: { weLove: 'Nosotros', slogan: '¡Reconocimiento único como tú!' },
  formHandler: {
    create: 'Crear',
    edit: 'Editar',
    update: 'Actualizar',
    updated: 'actualizado con éxito',
    created: 'creado(a) con éxito',
    successWithTitle: ' ',
    success: '¡Éxito!',
  },
  confirmDelete: {
    confirmation: '¿Está seguro de que desea',
    deleteLabel: 'eliminar',
    delete: 'Eliminar Elemento',
  },
  table: {
    body: {
      emptyDataSourceMessage: 'Sin filas para mostrar',
      addTooltip: 'Agregar',
      deleteTooltip: 'Eliminar',
      editTooltip: 'Editar',
      filterRow: { filterPlaceHolder: 'Filtrar', filterTooltip: 'Filtrar' },
      editRow: {
        deleteText: '¿Está seguro de que desea eliminar esta fila?',
        cancelTooltip: 'Cancelar',
        saveTooltip: 'Guardar',
      },
    },
    grouping: {
      placeholder: 'Arrastrar cabeceras...',
      groupedBy: 'Agrupado por:',
    },
    header: { actions: 'Acciones' },
    pagination: {
      labelDisplayedRows: '{from}-{to} de {count}',
      labelRowsSelect: 'filas',
      labelRowsPerPage: 'Filas por página:',
      firstAriaLabel: 'Primera Página',
      firstTooltip: 'Primera Página',
      previousAriaLabel: 'Página Anterior',
      previousTooltip: 'Página Anterior',
      nextAriaLabel: 'Página Siguiente',
      nextTooltip: 'Página Siguiente',
      lastAriaLabel: 'Última Página',
      lastTooltip: 'Última Página',
    },
    toolbar: {
      addRemoveColumns: 'Agregar o quitar columnas',
      nRowsSelected: '{0} fila(s) seleccionada(s)',
      showColumnsTitle: 'Mostrar Columnas',
      showColumnsAriaLabel: 'Mostrar Columnas',
      exportTitle: 'Exportar',
      exportAriaLabel: 'Exportar',
      exportPDFName: 'Exportar en PDF',
      exportCSVName: 'Exportar en CSV',
      searchTooltip: 'Buscar',
      searchPlaceholder: 'Buscar',
    },
  },
  simpleCrudTable: {
    actions: 'Acciones',
    limit: {
      firstLine: 'Puedes agregar hasta',
      secondLine: 'elementos solamente.',
    },
    rows: 'filas',
  },
  simpleLibrarySelection: {
    selected: { singular: 'Seleccionado(a)', plural: 'Seleccionados(as)' },
    sortedAscending: 'orden ascendente',
    sortedDescending: 'orden descendente',
  },
  companyAdminLayout: {
    add: 'Agregar',
    remove: 'Eliminar',
    thisItem: 'este elemento',
    successfullyUpdated: '¡actualizado(a) con éxito!',
    successfullyDeleted: '¡eliminado(a) con éxito!',
    success: '¡Éxito!',
    all: 'Todos',
  },
  welcome: {
    welcomeCompany: {
      title: 'Mensaje de Bienvenida',
      description:
        'Envía esta mensaje si la organización aún no ha sido notificada sobre su creación.',
    },
    welcomeEmployee: {
      title: 'Mensaje de Bienvenida',
      description:
        'Envía esta mensaje si deseas dar la bienvenida a un empleado en nuestra plataforma.',
    },
    welcomeAllEmployees: {
      title: 'Mensaje de Bienvenida a todos los empleados',
      description:
        'Envía esta mensaje si deseas dar la bienvenida a todos los empleados de una vez.',
    },
    welcomeNewEmployees: {
      title: 'Mensaje de Bienvenida a los nuevos empleados',
      description:
        'Envía esta mensaje si deseas dar la bienvenida a solo un empleado que aún no haya recibido el correo.',
    },
    betaTeamInviteEmail: {
      title: 'Invitación del Equipo Beta',
      description:
        'Envía esta mensaje si deseas invitar a un empleado a Sparck Beta.',
    },
    resendSurvey: {
      title: 'Reenviar Encuesta',
      description:
        'Envía esta mensaje si deseas invitar a un empleado a una encuesta nuevamente.',
    },
    resendPulseSurvey: {
      title: 'Reenviar Encuesta Pulse',
      description:
        'Envía este mensaje si deseas invitar a un empleado a participar nuevamente en la encuesta activa de Pulse'
    },
  },
  linkHere: 'AQUÍ',
  loading: 'Cargando...',
  yes: 'Sí',
  no: 'No',
  thereAreNo: 'No hay {name}',
  name: 'Nombre',
  nameIsRequired: 'El nombre es obligatorio',
  description: 'Descripción',
  firstName: 'Nombre',
  lastName: 'Apellido',
  phoneNumber: 'Teléfono',
  cellNumber: 'Celular',
  cancel: 'Cancelar',
  department: 'Departamento',
  email: 'Correo electrónico',
  status: 'Estado',
  birthday: 'Cumpleaños',
  for: 'para',
  invalidDateFormat: 'Fecha inválida',
  participation: 'Participación',
  reward: 'Beneficio',
  type: 'Tipo',
  value: 'Valor',
  select: 'Seleccionar',
  back: 'Volver',
  statuses: {
    accepted: 'Aceptado',
    approved: 'Aprobado',
    declined: 'Rechazado',
    pending: 'Pendiente',
  },
  beheardSurveyDrivers: {
    alignment: 'Alineación',
    team: 'Equipo',
    fit: 'Compatibilidad',
    valuing: 'Valoración',
    growth: 'Crecimiento',
    turnover: 'Rotación',
  },
  dashboard: {
    welcome: {
      title: '¡Bienvenido(a) a Sparck!',
      description:
        '¡Felicidades por configurar tu cuenta corporativa! Para comenzar, sigue los pasos en este panel para lanzar tu encuesta gratuita de compromiso #BeHeard.',
    },
    getStarted: 'Empezar',
    greetings: '¡Feliz {day}, {userName}!',
    noReminders: 'No tienes recordatorios.',
    reminders: {
      year: 'año',
      years: 'años',
      workiversaryOn: '{userName} {years} años de trabajo en {date}',
      birthdays: 'Cumpleaños',
      workiversaries: 'Aniversarios de Trabajo',
      showToday: 'Mostrar solo {sectionName} de hoy',
      showUpcoming: 'Mostrar próximos {sectionName}',
      today: '{sectionName} de hoy',
      upcoming: '{sectionName} próximos',
      noRemindersToday: 'No hay {reminderSectionName} hoy',
    },
    profileProgress: 'Tu perfil está {percentage} completo.',
    tasks: 'Tareas',
    noTasks: '¡Estás al día con tus tareas!',
  },
  pulseSurvey: {
    header: 'Tu voz es importante',
    responsesAreAnonymous: 'Todas las respuestas son anónimas.',
    needHelp: '¿Necesitas ayuda?',
    clickHere: 'Haz clic aquí',
    changeButtonText: 'Cambiar',
    previous: 'Anterior',
    next: 'Siguiente',
    finish: 'Terminar',
    openEndedLabel: 'Tu respuesta',
    openEndedPlaceholder: 'Escribe tu respuesta aquí...',
    openEndedHelperText:
      'Para garantizar tu confidencialidad, no pongas ninguna información de identificación personal.',
    noPulseSurveys:
      'No hay ninguna Encuesta Pulse en este momento, pero te avisaremos cuando haya.',
    statistics: {
      tableHeaders: {
        satisfaction: 'Satisfacción',
        importance: 'Importancia',
        openEnded: 'Texto Abierto',
        statement: 'Afirmación',
        agree: 'de acuerdo',
        neutral: 'neutrales',
        disagree: 'en desacuerdo',
        important: 'importante',
        indifferent: 'indiferente',
        not_important: 'no importante',
      },
      answers: 'Respuestas',
    },
  },
  pulseSurveyOverview: {
    header: 'Encuestas Pulse',
    textFieldLabel: 'Encuesta Pulse',
    snackbarSuccess: {
      openedPulseSurvey: '¡La Encuesta Pulse ha sido lanzada!',
      closedPulseSurvey: 'La Encuesta Pulse ha sido cerrada',
      extendedPulseSurvey: 'La Encuesta Pulse ha sido extendida',
      deletedPulseSurvey: '¡La Encuesta Pulse ha sido eliminada!',
      addedToCart: 'Encuesta Pulse añadida a tu carrito con éxito',
    },
    newPulseSurvey: 'Nueva Encuesta Pulse',
    launchPulseSurvey: 'Lanzar Encuesta Pulse',
    editPulseSurvey: 'Editar Encuesta Pulse',
    deletePulseSurvey: 'Eliminar Encuesta Pulse',
    closePulseSurvey: 'Cerrar Encuesta Pulse',
    extendPulseSurvey: 'Extender Encuesta Pulse',
    pulseReport: 'Informe Pulse',
    dialogs: {
      title: 'Encuestas Pulse',
      create: {
        descriptionFirstLine:
          'Es hora de hacer que la Encuesta Pulse sea tuya. Puedes realizar cambios antes del lanzamiento de la Encuesta Pulse y extender su duración antes de que se cierre. Haz clic',
        link: 'AQUÍ',
        descriptionSecondLine:
          'para las mejores prácticas relacionadas con Encuestas Pulse.',
      },
      edit: {
        descriptionFirstLine:
          '¿Necesitas editar tu Encuesta Pulse? No hay problema. Puedes hacerlo aquí. Por favor, realiza tus cambios abajo. Haz clic',
        link: 'AQUÍ',
        descriptionSecondLine:
          'para las mejores prácticas relacionadas con Encuestas Pulse.',
      },
      confirmClose: {
        title: 'Cerrar Encuesta Pulse',
        actionLabel: 'Cerrar Encuesta Pulse',
        deleteItemLabel: 'cerrar',
      },
      confirmDelete: {
        title: 'Eliminar Encuesta Pulse',
        actionLabel: 'Eliminar Encuesta Pulse',
        alternateConfirmDeleteLabel:
          'Esta encuesta pulse tiene respuestas asociadas. ¿Estás seguro de que quieres eliminarla? Esta acción no se puede deshacer.',
      },
      demographicsPickerLabel:
        '¿Qué grupos demográficos recibirán esta Encuesta Pulse?',
      rolesPicker: {
        label:
          '¿Empleados con qué responsabilidades recibirán esta Encuesta Pulse?',
        selectLabel: 'Responsabilidades',
      },
      formLabels: {
        pulseSurveyName: 'Nombre de la Encuesta Pulse',
        launchDate: 'Fecha de Lanzamiento',
        closeDate: 'Fecha de Cierre',
      },
      questionsTable: {
        header: 'Preguntas',
        tableHeaders: {
          statement: 'Afirmación',
          showImportance: '¿Mostrar escala de importancia?',
          isOpenEnded: '¿Es pregunta abierta?',
        },
        statementPlaceholder: 'Escribe tu pregunta/afirmación...',
        statementHelperText:
          'Por favor, escribe tu pregunta y haz clic en el botón "+" para agregarla.',
        librarySelection: { headers: { statement: 'Afirmación' } },
      },
    },
    validations: {
      pulseSurveyNameIsRequired: 'El nombre de la Encuesta Pulse es obligatorio',
      minimumLaunchDate: 'No puedes seleccionar una fecha pasada',
      maximumLaunchDate:
        'La fecha de lanzamiento no puede ser posterior a la fecha de cierre',
      launchDateIsRequired: 'La fecha de lanzamiento es obligatoria',
      minimumCloseDate:
        'La fecha de cierre no puede ser anterior a la fecha de lanzamiento',
      closeDateIsRequired: 'La fecha de cierre es obligatoria',
    },
  },
  surveyLaunchMode: {
    label: '¿Cómo te gustaría lanzar tu encuesta?',
    manual: {
      label: 'Lanzar Manualmente',
      description:
        "Al seleccionar Lanzar Manualmente, tu encuesta no se lanzará hasta que hagas clic en el botón {launchButtonLabel} en la pantalla de Gestionar Encuestas. También se te pedirá que ingreses una Fecha de Cierre antes de poder lanzar tu encuesta, lo que cerrará automáticamente la encuesta en la fecha seleccionada.",
    },
    scheduled: {
      label: 'Programar Lanzamiento',
      description:
        'Al seleccionar Programar Lanzamiento, tu encuesta se lanzará automáticamente en la fecha de lanzamiento que hayas especificado y se cerrará automáticamente en la fecha de cierre especificada. Siempre puedes lanzar tu encuesta antes haciendo clic en el botón {launchButtonLabel} en la pantalla de Gestionar Encuestas.',
    },
    drivers: {
      label: 'Qué factores de compromiso quieres incluir',
      description: 'TODO Al seleccionar el botón {launchButtonLabel} en la pantalla de Gestionar Encuestas.',
      fit: 'Ajuste',
      fitToolTipText: 'Ajuste',
      growth: 'Crecimiento',
      growthToolTipText: 'Crecimiento',
      alignment: 'Alineación',
      alignmentToolTipText: 'Alineación',
      value: 'Valor',
      valueToolTipText: 'Valor',
      team: 'Equipo',
      teamToolTipText: 'Equipo',
    },
    changeManagerText: {
      label: 'Usa este texto en lugar de Manager',
      toolTipDescription:
        'Puedes escribir texto como: Gerentes, Director, para que se muestre en la página de tu encuesta',
    },
  },
  myCompany: {
    greetings: { good: 'Feliz', welcomeBack: '¡Bienvenido de nuevo!' },
    companyOverview: {
      cardTitle: 'Visión General de la Organización',
      sectionLabels: {
        name: 'Nombre de la Organización',
        numberOfEmployees: 'Número de Empleados',
        companyAdministrators: 'Administradores de la Organización',
      },
      sendBulkMessage: 'Enviar Bienvenida',
      textFieldLabel: 'Nombre de la Organización',
      seeLess: 'Ver Menos',
      seeMore: 'Ver Más',
      dialogTitle: 'Enviar Bienvenidas',
    },
    companyBeheard: {
      cardTitle: 'Actualizar Informe',
      getMoreFromResults:
        'Aprovecha mejor tus resultados. Aprende más sobre el Informe #BeHeard Premium.',
      learnMore: 'Aprender Más',
    },
    companyGotQuestions: {
      cardTitle: '¿Alguna Pregunta?',
      weAreHereToHelp: '¡Estamos aquí para ayudarte!',
      sectionLabels: {
        customerExperience: 'Socio(a) de Experiencia del Cliente',
        technicalSupport: 'Soporte Técnico',
      },
    },
    companySurveyUpdates: {
      cardTitle: 'Actualizaciones de Encuestas',
      emptyMessage: 'No hay encuestas por el momento.',
      seeAllSurveys: 'Ver todas las Encuestas',
      columnLabels: {
        surveyType: 'Tipo de Encuesta',
        openDate: 'Fecha de Lanzamiento',
        closeDate: 'Fecha de Cierre',
        invites: 'Invitaciones',
      },
      linkLabels: {
        manageBeheardSurvey: 'Gestionar Encuesta #BeHeard',
        beheardPremiumReport: 'Informe #BeHeard Premium',
        shareReport: 'Compartir Informe',
        upgradeToBeheardPremiumReport:
          'Actualizar al Informe #BeHeard Premium',
        beheardFreeReport: 'Informe #BeHeard Gratuito',
      },
    },
    companyPulseSurveyUpdates: {
      cardTitle: 'Actualizaciones de Encuestas Pulse',
      emptyMessage: 'No hay Encuestas Pulse por el momento.',
      seeAllPulseSurveys: 'Ver todas las Encuestas Pulse',
      columnLabels: {
        openDate: 'Fecha de Lanzamiento',
        closeDate: 'Fecha de Cierre',
      },
      linkLabels: { managePulseSurvey: 'Gestionar Encuesta Pulse' },
    },
    companySuggestionUpdates: {
      cardTitle: 'Actualizaciones de Sugerencias',
      emptyMessage: 'No hay sugerencias por el momento.',
      seeAllSuggestions: 'Ver todas las Sugerencias',
      columnLabels: {
        date: 'Fecha',
        from: 'De',
        numberOfReplies: 'Número de respuestas',
        suggestion: 'Sugerencia',
      },
    },
    companyFreeResources: {
      cardTitle: 'Recursos Gratuitos',
      content:
        'Conoce nuestro kit de herramientas con todo lo que necesitarás para tener una experiencia eficaz con la Encuesta #BeHeard.',
      letsDoIt: '¡Vamos allá!',
    },
    companyCostAnalysis: {
      cardTitle: 'Análisis de Costos',
      content:
        '¿Cuánto te está costando la falta de compromiso de tus empleados? Descúbrelo con nuestro Análisis de Costos de Compromiso GRATUITO.',
      getStartedNow: '¡Comienza ahora!',
    },
    companyNotifications: {
      dialogTitle: '¿Estás seguro de que deseas enviar',
      send: 'Enviar',
    },
    snackbarSuccess: {
      updated: 'Datos de la Organización actualizados con éxito',
      surveyAddedToCart: 'Encuesta agregada a tu carrito con éxito',
    },
  },
  companies: {
    addFunds: 'Añadir Créditos',
    allowApplication: 'Permitir aplicación {application}',
    allowInternationalization: 'Permitir Internacionalización',
    beheardAccess: 'Acceso #BeHeard',
    beseenAccess: 'Acceso #Beseen',
    clientSince: 'Cliente Desde',
    companyName: 'Nombre de la Organización',
    createCompany: 'Crear Organización',
    description:
      'Tú, como Administrador de Sparck, puedes gestionar organizaciones como prefieras :)',
    editCompany: 'Editar Organización',
    emails: 'Correos Electrónicos',
    funds: 'Créditos',
    fundsAmount: 'Cantidad de Créditos',
    manageNgcFor: 'Gestionar NGC para',
    ngcAccountSuccessfullyUpdated: '¡Cuenta NGC actualizada con éxito!',
    ngcAmount: 'Cantidad del NGC',
    ngcApiClientId: 'NGC API Client ID',
    ngcApiSecret: 'Contraseña de la API de NGC',
    ngcManagement: 'Gestión del NGC',
    virginExperiencesManagement: 'Gestión de Virgin Experiences',
    released: 'Lanzado',
    saveChanges: 'Guardar Cambios',
    notifyViaSmsAndEmail: 'Notificar a {name} por SMS/Email',
    sparckAmount: 'Monto del Sparck',
    title: 'Organizaciones',
    total: 'Total',
    validations: { companyNameIsRequired: 'El nombre de la organización es obligatorio' },
    snackbar: { success: { emailSent: '¡Este correo electrónico fue enviado con éxito!' } },
  },
  employees: {
    title: "Empleados",
    description: "¡Bienvenido! Comenzar es rápido y fácil, solo sube la dirección de correo electrónico de tus empleados. Puedes incluir información demográfica sobre el entorno laboral más tarde si actualizas al informe #BeHeard Premium.",
    openedSurveyResent: "¡El correo electrónico de apertura de encuesta se reenvió a este usuario!",
    betaTeamInviteSent: "¡El correo electrónico de invitación al equipo Beta se reenvió a este usuario!",
    welcomeNotificationSent: "El mensaje de bienvenida fue enviado a este usuario.",
    employeeRoleDisclaimer: "Si creas un empleado sin asignarle un rol, obtendrá el rol de Empleado de forma predeterminada. Si el empleado es un Gerente o superior, selecciona el Rol antes de crear el empleado.",
    fields: {
      businessUnit: "Unidad de Negocio",
      companyInternalId: "ID Interno de la Organización",
      dateOfBirth: "Fecha de Nacimiento",
      email: "Correo Electrónico",
      ethnicity: "Etnia",
      firstName: "Nombre",
      gender: "Género",
      highValueTalent: "Talento de Alto Valor",
      highValueTalentTooltip: "Filtra los informes por nivel de desempeño basado en métricas internas (bajo, medio y alto, por ejemplo).",
      hireDate: "Fecha de Contratación",
      jobType: "Tipo de Trabajo",
      location: "Nombre de la Ubicación",
      performanceLevel: "Nivel de Rendimiento",
      performanceLevelTooltip: "Filtra los informes por nivel de desempeño basado en percepciones internas (bajo, medio, alto, por ejemplo).",
      region: "Región",
      country: "País",
      roles: "Responsabilidades",
      supervisors: "Supervisores",
      territory: "Territorio",
      title: "Puesto"
    },
    placeholders: {
      male: "Masculino"
    },
    sendEmails: "Enviar Correos Electrónicos",
    tooltips: {
      changePassword: "Cambiar Contraseña",
      spoofEmployee: "Ingresar como este Usuario"
    },
    bulkEditEmployees: "Editar Múltiples Empleados",
    hrisSection: "Sección HRIS",
    hrisSectionLabel: "Importar Empleados",
    hrisSectionDescription: "Facilita aún más la carga de empleados y realiza la integración con tu sistema HRIS.",
    bulkUpload: "Carga Masiva",
    bulkUploadLabel: "Hoja de Carga",
    bulkUploadDescription: "¿200 filas? ¿500+ filas? ¡Podemos manejar tu hoja de empleados!",
    employeeManagement: "Gestión de Empleados",
    employeeManagementLabel: "Agregar un Empleado",
    employeeManagementDescription: "¿Quieres agregar a tus empleados individualmente? ¡Puedes hacerlo aquí!",
    sendEmailsTo: "Enviar correos electrónicos a {name}",
    changeEmployeePasswordDialog: {
      title: "Cambiar Contraseña del Empleado",
      changePassword: "Cambiar Contraseña"
    },
    employeesGraphDialogTitle: "Visualización del Gráfico de Empleados",
    selectEmployees: "Seleccionar Empleados",
    manageEmployeeFormTabs: {
      info: "Información",
      demographics: "Datos Demográficos",
      rolesAndSupervisors: "Responsabilidades y Supervisores"
    },
    generalInformation: "Información General",
    employeeContact: "Contacto del Empleado",
    employeeLocation: "Ubicación del Empleado",
    demographicInformation: "Información Demográfica",
    validations: {
      invalidEmailFormat: "Formato de correo electrónico no válido",
      emailIsRequired: "El correo electrónico es obligatorio"
    },
    snackbars: {
      error: {
        assignAtLeastOneRole: "Por favor, asigna al menos una responsabilidad al empleado."
      }
    }
  },
  hrisImport: {
    trinet: "TriNet",
    submittedHRISSystem: "Has enviado {hrisSuggestion} como tu sistema HRIS. Gracias por informarnos.",
    employeesImported: "¡Tus empleados se han importado con éxito desde tu HRIS!",
    importEmployeesFromHRIS: "Importa empleados desde tu sistema HRIS",
    selectHRIS: "Selecciona un sistema HRIS e ingresa el ID de tu sistema HRIS - ¡así de sencillo!",
    hrisSystem: "Sistema HRIS",
    companyId: "ID de la Organización",
    importEmployeesLabel: "Importa Empleados por HRIS",
    doYouUseAnotherHRIS: "¿Usas otro sistema HRIS?",
    comingSoon: "Si estás usando un sistema HRIS diferente, háznoslo saber. Escribe el nombre a continuación y lo priorizaremos para futuras integraciones.",
    submit: "Enviar",
    futureDevelopment: "Lo pondremos en la lista de nuestros desarrollos futuros"
  },
  employeesUpload: {
    employeesImportedSuccessfully: "¡Empleados importados con éxito!",
    uploadEmployeeInformation: "Sube la información de tus empleados",
    description1:
      "Usa nuestro modelo Excel para cargar a tus empleados. La segunda pestaña de la hoja de cálculo proporciona instrucciones y ejemplos, si es necesario. Una vez que tu archivo Excel esté listo, usa el botón de carga para enviarlo. Podrás verificar la información de cada empleado antes de importarla.",
    description2:
      "El modelo incluye dos columnas al final para que agregues datos demográficos personalizados del lugar de trabajo. Esto proporcionará opciones adicionales de filtrado para tus informes.",
    downloadExcelTemplate: "Haz clic aquí para descargar el modelo Excel.",
    dragAndDrop: "¡Arrastra y suelta tu hoja de cálculo o haz clic aquí!",
    importingYourSpreadsheet:
      "¡Importando tu hoja de cálculo! Esto puede tardar un poco..."
  },
  invoices: {
    title: "Facturas",
    description: "Consulta y gestiona tus facturas aquí",
    invoicePDF: "Haz clic aquí para descargar el PDF de la factura",
    proceedToPayment: "Proceder al Pago",
    fields: {
      invoiceDate: "Fecha de la Factura",
      receiptNumber: "# de Recibo",
      paid: "Pagado",
      amount: "Monto"
    }
  },
  roles: {
    title: "Responsabilidades",
    description:
      "Administra la configuración de permisos para cada responsabilidad en tu organización",
    types: {
      companyAdmin: "Administrador",
      ceo: "CEO",
      executive: "Ejecutivo",
      manager: "Gerente",
      employee: "Empleado"
    },
    fields: {
      allowBeseenApplication: "Permitir la aplicación #BeSeen",
      permissions: "Permisos",
      selectAll: "Seleccionar Todo"
    },
    validations: {
      typeIsRequired: "El tipo es obligatorio",
      descriptionIsRequired: "La descripción es obligatoria"
    }
  },
  demographics: { title: 'Demografía', description: 'Descripción de la demografía' },
  settings: {
    formLabels: {
      companyPurpose: 'Propósito de la Organización',
      timezone: 'Zona Horaria',
      suggestionBox: 'Nombre del Buzón de Sugerencias',
    },
    placeholders: {
      companyPurpose: '¿Cuál es el propósito de tu organización?',
      timezone: '¿En qué zona horaria te encuentras?',
      suggestionBox: '¡Comparte tus increíbles sugerencias!',
    },
    sectionTitles: {
      socialMedia: 'Redes Sociales',
      feed: 'Feed',
      insightsPermissions: 'Permisos de Insights',
      nominations: 'Nominaciones',
      notifications: 'Notificaciones',
      mfa: 'Autenticación Multifactor',
      profanityFilter: 'Filtro de Profanidad',
      integrations: 'Integraciones',
      recognitionFrequency: 'Frecuencia de Reconocimiento',
    },
    sectionControlLabels: {
      socialMedia: {
        allowSharing: 'Permitir Compartir en Redes Sociales',
        facebook: 'Facebook',
        linkedIn: 'LinkedIn',
        twitter: 'Twitter',
      },
      feed: { suggestionBox: 'Incluir Buzón de Sugerencias' },
      insightsPermissions: {
        organizationWideBeheard: 'Mostrar BeHeard de Toda la Organización',
        pulseSurvey: 'Mostrar Encuesta Pulse',
        managerTeamBeheard: 'Mostrar BeHeard del Equipo de Gerentes',
        managerTeamPulseSurvey: 'Mostrar Encuesta Pulse del Equipo de Gerentes',
      },
      profanityFilter: {
        enableProfanityFilter: 'Habilitar Filtro de Profanidad',
      },
      nominations: { enableNominations: 'Habilitar Nominaciones' },
      notifications: {
        enableEmailNotifications: 'Habilitar Notificaciones Por Correo Electrónico',
        enableSmsNotifications: 'Habilitar Notificaciones Por SMS/TEXTO'
      },
      mfa: {
        requireMFA:
          'Establecer Autenticación Multifactor como obligatoria para todos los empleados',
      },
      recognitionFrequency: {
        atLeastEvery: 'Al menos cada {frequency} días',
      },
    },
    saveChanges: 'Guardar Cambios',
    suggestionBoxTooltipTitle:
      'Brinda a los empleados la oportunidad de compartir sus pensamientos e ideas.',
    slackIntegration: {
      step1: "Paso #1: Establece el ID de Espacio de Trabajo de tu Slack",
      workspaceIdOfSlack: 'ID de Espacio de Trabajo de tu Organización Slack',
      setWorkspaceID: 'Establecer ID de Espacio de Trabajo',
      step2:
        'Paso #2: Haz clic en el enlace a continuación y permite que Sparck configure el Bot Sparcky en tu Espacio de Trabajo Slack',
      allowSparckToIntegrateSlack:
        "Permite que Sparck se integre con el Espacio de Trabajo de tu Slack",
      alreadyClickedSparckBot:
        "¿Ya hiciste clic en el botón anterior y terminaste el proceso? {link} para recargar la pantalla y verificar el estado de la integración con Slack.",
    },
    msTeamsIntegration: {
      stepsTutorialFindLink: {
        stepOne: {
          label: 'Haz clic en el botón de 3 puntos',
          description:
            'El botón de 3 puntos está ubicado a la derecha en el canal que creaste. Luego haz clic en "Obtener enlace al canal"',
        },
        stepTwo: { label: '¡Haz clic en copiar!' },
      },
      stepsTutorialInstallBot: {
        stepOne: {
          label: 'Descargar Sparck Bot',
          customDescription: {
            partOne: 'Haz clic en "Descargar Sparck Bot para MS Teams" en esta pantalla',
            partTwo:
              'Ve a MS Teams > Aplicaciones > Gestiona tus Apps > Carga tu app a tu catálogo de aplicaciones',
          },
        },
        stepTwo: {
          label: 'Instalar Aplicación',
          description:
            'Encuentra Sparck Bot en tu explorador de archivos, selecciona y haz clic en la App Sparck en MS Teams',
        },
        stepThree: {
          label: 'Agregar al Equipo',
          description:
            'Haz clic en el botón de la flecha hacia abajo y selecciona "Agregar a un Equipo"',
        },
        stepFour: {
          label: 'Configuración',
          description:
            'Escribe el nombre de tu organización y selecciona la opción con el canal Sparck Feed que creaste en los pasos anteriores y haz clic en "Configurar un Bot"',
        },
      },
      stepsTutorialSetPolicies: {
        stepOne: {
          label: 'Agregar Políticas',
          customDescription: {
            partOne:
              'Accede a la página de Configuración de Política de Aplicaciones de tu MS Teams:',
            partTwo: 'Haz clic en el enlace "Global (Org-wide default)"',
            partThree: 'En la sección "Apps Instaladas", haz clic en "Agregar apps"',
            partFour:
              'Escribe "Sparcky" en el campo de búsqueda y haz clic en "agregar" para la app Sparcky',
          },
        },
        stepTwo: {
          label: 'Anclar App',
          customDescription: {
            partOne: 'Verás el bot listado en la sección "Apps Instaladas"',
            partTwo:
              'Ve a la sección "Apps Ancladas" y haz clic en el enlace "+ Agregar apps"',
            partThree:
              'Escribe Sparcky en el campo de búsqueda, haz clic en "agregar" para la app Sparcky y haz clic en "agregar" nuevamente para guardar este paso',
            partFour:
              'Escribe "Sparcky" en el campo de búsqueda y haz clic en "agregar" para la app Sparcky',
          },
        },
        stepThree: {
          label: '¡Completado!',
          customDescription: {
            partOne: 'Verás el bot listado en la sección "Apps Ancladas"',
            partTwo: 'Coloca Sparcky donde desees arrastrando y soltando',
            partThree: 'Luego haz clic en el botón "Guardar" en la parte inferior',
          },
        },
      },
      stepOne: 'Paso #1: Pega el enlace de tu Canal MS Teams',
      channelLinkLabel: 'Enlace del Canal de tu Organización MS Teams',
      save: 'Guardar',
      stepTwo:
        'Paso #2: Haz clic en el enlace a continuación para descargar tu Sparck Bot (archivo .zip)',
      downloadMSTeamsSparckBot: 'Descargar Sparck Bot para MS Teams',
      frequentlyAskedQuestions: 'Preguntas Frecuentes',
      whereToFindChannelLink: '¿Dónde encuentro el enlace de mi Canal MS Teams?',
      whatToDoNext: 'He descargado mi Sparck Bot. ¿Qué hago ahora?',
      sparckBotByDefault:
        'Quiero que los empleados de mi organización tengan Sparck Bot por defecto. ¿Cómo hago esto?',
    },
    settingsIntegration: {
      sparckFeedChannelDefined: 'Canal Sparck Feed ya definido',
      integrate: 'Integrar',
      remove: 'Eliminar',
      integrationSectionTitles: {
        slackBot: 'Integración de Slack Bot',
        msTeams: 'Integración de MS Teams Bot',
      },
      slackBotIntegrationDialogTitle: 'Integración de Slack Bot',
      msTeamsBotIntegrationDialogTitle: 'Integración de MS Teams Bot',
      slackIntegrationConfirmDelete: {
        title: 'Eliminar Integración de Slack Bot Sparcky',
        itemName: 'Integración de Slack Bot Sparcky',
        actionLabel: 'eliminar',
      },
      msTeamsIntegrationConfirmDelete: {
        title: 'Eliminar Integración de Sparcky Bot MS Teams',
        itemName: 'Integración de Sparcky Bot MS Teams',
        actionLabel: 'eliminar',
      },
    },
    snackbarSuccess: { saved: '¡Configuraciones guardadas!' },
  },
  signup: {
    createAccount: 'Crear Cuenta',
    secondaryActionButtonLabels: { backToLogin: 'Volver al Inicio de Sesión' },
    description:
      'Estamos emocionados de ayudarte a lanzar tu encuesta #BeHeard. Comienza con la versión gratuita y mejora después. Por favor, proporciona la siguiente información para crear tu cuenta organizacional gratuita. No se requiere tarjeta de crédito.',
    confirmEmailDialog: {
      title: '¡Casi listo!',
      content:
        'Antes de crear tu cuenta, necesitamos hacer una rápida verificación de correo electrónico - ¿todo bien?',
      changeEmail: 'Cambiar correo electrónico',
      emailIsCorrect: 'El correo electrónico es correcto',
    },
    formLabels: {
      companyName: 'Nombre de la Organización',
      industry: 'Industria',
      websiteUrl: 'URL del Sitio Web',
      phoneNumber: 'Teléfono',
      cellNumber: 'Celular',
      firstName: 'Nombre',
      lastName: 'Apellido',
      email: 'Correo Electrónico',
      password: 'Contraseña',
      sendEmail: 'Enviar un correo notificando que la organización fue creada.',
    },
    termsAndConditionsLink: {
      firstLine: 'Al hacer clic en Crear Cuenta, aceptas los',
      secondLine: 'Términos y Condiciones de Sparck',
    },
    validations: {
      sparckAdminSchema: {
        companyNameIsRequired: 'El nombre de la organización es obligatorio',
        invalidUrlFormat: 'Formato de URL inválido',
        websiteUrlIsRequired: 'La URL es obligatoria',
        industryIsRequired: 'La industria es obligatoria',
      },
      employeesSchema: {
        companyNameIsRequired: 'El nombre de la organización es obligatorio',
        invalidUrlFormat: 'Formato de URL inválido',
        websiteUrlIsRequired: 'La URL es obligatoria',
        industryIsRequired: 'La industria es obligatoria',
        firstNameIsRequired: 'El nombre es obligatorio',
        lastNameIsRequired: 'El apellido es obligatorio',
        phoneNumberInvalidFormat:
          'Debe tener un formato válido de número de teléfono en EE.UU.',
        phoneNumberIsRequired: 'El número de teléfono es obligatorio',
        invalidEmailFormat: 'Formato de correo electrónico inválido',
        emailIsRequired: 'El correo electrónico es obligatorio',
        passwordCapitalLetter:
          'La contraseña debe incluir al menos una letra mayúscula.',
        passwordLowercaseLetter:
          'La contraseña debe incluir al menos una letra minúscula.',
        passwordNumber: 'La contraseña debe incluir al menos un número.',
        passwordSpecialCharacter:
          'La contraseña debe incluir al menos un carácter especial (+, #, !, ? o @).',
        passwordIsRequired: 'La contraseña es obligatoria',
      },
    },
    snackbar: {
      success: {
        company: 'Organización',
        createdSuccessfully: 'creada con éxito!',
      },
    },
  },
  login: {
    snackbar: {
      success: {
        checkEmail:
          'Por favor, verifica tu correo electrónico para obtener más instrucciones.',
        useNewPassword:
          'Por favor, utiliza tu nueva contraseña para acceder a la aplicación.',
        passwordUpdated: '¡Contraseña actualizada con éxito!',
      },
    },
    changePassword: {
      title: 'Cambiar Contraseña',
      formLabels: { currentPassword: 'Contraseña Actual', newPassword: 'Nueva Contraseña' },
      changePasswordButton: 'Cambiar Contraseña',
      backToLoginButton: 'Volver al Inicio de Sesión',
    },
    loginForm: {
      welcome: '¡Bienvenido!',
      backupCodeInstruction:
        'Ingresa uno de los códigos de recuperación que guardaste al habilitar la Autenticación Multifactor',
      labels: {
        password: 'Contraseña',
        verificationCode: 'Código de Verificación',
        useDifferentMFAMethod: 'Usar un método MFA diferente',
        backupCode: 'Ingresa el Código de Recuperación',
        authApp: 'Aplicación de Autenticación',
        useBackupCode: 'Usar Código de Recuperación',
      },
      resendVerificationCodeTooltip:
        'Reenviar código de verificación a tu teléfono',
      useBackupCode: 'Usar un código de recuperación',
      dontHaveBackupCode: 'No tengo un código de recuperación',
      loginButton: 'Iniciar Sesión',
      forgotPasswordButton: 'Olvidé mi Contraseña',
    },
    recoverPassword: {
      setPassword: 'Establecer tu contraseña',
      forgotPassword: '¿Olvidaste tu contraseña?',
      enterNewPassword: 'Ingresa tu nueva contraseña.',
      formLabels: { password: 'Contraseña' },
      enterEmail: 'Ingresa tu correo electrónico para recuperar tu contraseña.',
      setPasswordButton: 'Establecer Contraseña',
      recoverPasswordButton: 'Recuperar Contraseña',
      backToLoginButton: 'Volver al Inicio de Sesión',
    },
  },
  mfa: {
    enable: 'Habilitar',
    disable: 'Deshabilitar',
    viewOrDownload: 'Ver/Descargar',
    primary: 'PRIMARIO',
    makePrimary: 'Hacer Primario',
    gotIt: '¡Entendido!',
    enterPassword: 'Introduce tu contraseña',
    enterVerificationCode: 'Introduce el código de verificación',
    mfaFactors: {
      authApp: {
        title: 'Aplicación de Autenticación',
        description:
          'Usa una aplicación de autenticación para generar códigos de seguridad únicos (más seguro).',
        steps: {
          scanQRCode: 'Escanea el código QR',
          success: '¡Has habilitado la Aplicación de Autenticación con éxito!',
        },
      },
      sms: {
        title: 'SMS',
        description:
          'Usa tu teléfono móvil para recibir códigos de seguridad.',
        steps: {
          smsSetup: 'Configuración de SMS',
          success: '¡Has habilitado SMS con éxito!',
        },
      },
      email: {
        title: 'Correo electrónico',
        description:
          'Usa tu correo electrónico para recibir códigos de seguridad (menos seguro).',
        steps: { success: '¡Has habilitado el correo electrónico con éxito!' },
      },
      backupCodes: {
        title: 'Códigos de recuperación',
        description:
          'Usa los códigos de recuperación cuando no puedas usar otro método.',
        steps: { success: 'Tus Códigos de Recuperación' },
        actions: { download: 'Descargar', print: 'Imprimir', copy: 'Copiar' },
      },
      dialogTitle: {
        setup: 'Configuración de AMF de {selectedFactor}',
        disable: 'Deshabilitar AMF de {selectedFactor}',
      },
      disableDialogContent:
        '¿Realmente deseas deshabilitar {selectedFactor} como método de autenticación multifactor?',
    },
    enterCredentials: 'Para continuar, por favor introduce tu contraseña',
    enterVerificationCodeMessage: {
      email: 'Enviamos un código de verificación al correo electrónico: {email}.',
      sms: 'Enviamos un código de verificación por SMS al número con final {phoneNumberLastDigits}.',
      authenticatorApp:
        'Introduce el código de verificación generado por tu aplicación de autenticación.',
    },
    setupComponentTitle: 'Selecciona las opciones de autenticación',
    setupComponentDescription:
      'Cada vez que inicies sesión en tu cuenta de Sparck, necesitarás tu contraseña y un código de verificación.',
    snackbars: {
      success: {
        mfaDisabled:
          '{selectedFactor} ha sido deshabilitado como método de AMF con éxito!',
      },
      error: {
        verificationCode:
          'No pudimos verificar el código que ingresaste. Asegúrate de proporcionar un código válido',
      },
    },
    next: 'Siguiente',
    youNeedAnAuthApp:
      'Necesitarás una aplicación de autenticación como {example} para completar este proceso',
    scanQRCode: 'Usa tu aplicación para escanear el código QR',
    whenYouAreDone: "Cuando termines de escanear, haz clic en 'Siguiente'",
    successfullyEnabled: 'Habilitado con éxito',
    yourBackupCodes: 'Tus códigos de recuperación',
    yourBackupCodesDescription:
      'Guarda estos códigos de recuperación en un lugar seguro. Si pierdes tu teléfono o dispositivo de autenticación, podrás usar los códigos de recuperación para autenticarte.',
    footerMessage:
      'Los códigos de autenticación se enviarán al número que proporcionaste.',
    validations: {
      verificationCodeRequired: 'El código de verificación es obligatorio',
    },
    enterSMSCode: 'Introduce el código de 6 dígitos que te enviamos por SMS',
    enterAuthAppCode: 'Introduce el código de 6 dígitos generado por tu aplicación',
    enterEmailCode:
      'Introduce el código de 6 dígitos que enviamos a tu correo electrónico',
    labels: { enterYourCode: 'Introduce tu código' },
    resendCode: 'Reenviar código',
    enterPhoneNumber:
      'Por favor, introduce el número que deseas usar para recibir los códigos de verificación.',
    mfaOptions: {
      authApp: 'Aplicación de Autenticación',
      sms: 'Mensaje de texto (SMS)',
    },
    weRecommendAnApp:
      'Recomendamos una aplicación basada en tiempo para mantener a nuestros clientes seguros.',
    mfaRequired:
      '{organizationName} ha definido la Autenticación Multifactor (MFA) como obligatoria',
    mfaRequiredDescription:
      '{organizationName} requiere que cada empleado habilite la Autenticación Multifactor para usar con la aplicación Sparck. Esto añade una capa extra de seguridad y protección para tus datos y los de tu empresa, y es muy fácil de configurar. Solo selecciona el método que deseas usar a continuación y te mostraremos cómo configurarlo. Si decides usar otro método en el futuro, podrás actualizar tus configuraciones de MFA desde la pantalla de tu perfil.',
    startMFASetup: 'Iniciar configuración de MFA',
    takeMeToSparck: '¡Llévame a Sparck!',
    backupCodesDialog: {
      title: 'Tus códigos de recuperación',
      resetSuccessAlert: {
        title: '¡Éxito!',
        description:
          'Tus códigos de recuperación han sido restablecidos con éxito.',
      },
      resetBackupCodes: 'Restablecer códigos de recuperación',
      submit: 'Enviar',
    },
  },
  marketing: {
    snackbar: {
      success: {
        surveyAddedToCart: 'Encuesta agregada a su carrito con éxito'
      }
    },
    testimonialFlexCare:
      'En FlexCare, nuestra cultura organizacional es muy importante para nosotros porque nuestros empleados son el corazón de nuestro negocio. A medida que comenzamos a crecer, el Informe #BeHeard Premium de Sparck nos ayudó a alinear nuestras prioridades basadas en nuestra eficiencia y en lo que es más importante para nuestros empleados. Pudimos delinear una estrategia a nivel organizacional y, al mismo tiempo, personalizar los resultados usando la ubicación y los departamentos mientras manteníamos el anonimato de los colaboradores. Esto sirvió como una excelente herramienta de coaching que proporcionó a nuestros gerentes una mejor perspectiva de cómo liderar un equipo más comprometido y productivo. ¡Recomiendo mucho esta encuesta! Fue un proceso fácil con perspectivas de acción.',
    testimonialTeledyneTechnologies:
      'La herramienta de Análisis de Costo de Compromiso de Empleados proporcionada por Sparck nos dio una manera fácil y eficaz de comunicar cómo el aumento en el compromiso de los empleados impactaría directamente los resultados de nuestra organización. La visión general personalizada en una página fue una gran adición a nuestra reunión ejecutiva y nos dio una forma de ver nuestros números específicos y delinear una solución. Recomiendo encarecidamente esta herramienta para profesionales de RRHH que están mapeando sus estrategias internas y quieren mejorar su Informe #BeHeard Premium.',
    upgradeNow: 'Mejorar Ahora',
    marketingHeader: {
      upgradeToday: '¡Mejora Hoy!',
      getMoreStrategicLook:
        'Obtén una visión más estratégica de los resultados de tu Encuesta #BeHeard con recomendaciones personalizadas y perspectivas demográficas.',
      beheardPremiumReportDescription:
        'Nuestro Informe #BeHeard Premium estadísticamente válido proporcionará una comprensión más profunda de tus resultados y pasos para tomar acciones para aumentar el compromiso de tus empleados en cada nivel de tu organización. Además, recibirás percepciones críticas y consejos específicos cuando mejores al Informe Premium. Revisa los detalles a continuación y, si deseas conversar, estamos aquí.',
      upgradeNow: 'Mejorar Ahora'
    }
  },
  shoppingCart: {
    yourShoppingCartItems: 'Tus Artículos en el Carrito',
    employee: 'empleado',
    employees: 'empleados',
    removeFromCart: 'Eliminar del Carrito',
    proceedToPayment: 'Proceder al Pago',
    noItems: 'No tienes artículos en tu carrito de compras.',
    productTitle:
      '{productName} - {itemToUpgradeName} para {employeesQuantity}',
    total: 'Total',
    snackbar: {
      success: {
        itemRemoved: 'Artículo eliminado de tu carrito con éxito'
      },
      error: {
        couldNotRemoveItem:
          'No se pudo eliminar este artículo de tu carrito'
      }
    }
  },
  notifications: {
    survey: {
      isNowOpened: '{surveyName} ¡está abierta!',
      isClosed: '{surveyName} ¡ha sido cerrada!',
      isReopened: '{surveyName} ¡ha sido reabierta!',
      wasExtended: '{surveyName} ¡ha sido extendida!',
      canNowBeUpgraded: '{surveyName} ¡ahora puede ser mejorada!',
      needsToBeFinished: '¡Tienes una encuesta {surveyName} que necesitas completar!',
      lastDayToFinish: '¡Hoy es el último día para completar {surveyName}!',
      thankYou: '¡Muchas gracias por tu participación en la encuesta {surveyName}!',
      fullReportAvailable: '¡Felicidades! ¡Tu Informe #BeHeard Completo para {surveyName} ya está disponible!',
      freeReportAvailable: '¡Tu Informe #BeHeard para {surveyName} ya está disponible!',
    },
    pulseSurvey: {
      isNowOpened: '{pulseSurveyName} ¡está abierta!',
      isClosed: '{pulseSurveyName} ¡ha sido cerrada!',
      isReopened: '{pulseSurveyName} ¡ha sido reabierta!',
      wasExtended: '{pulseSurveyName} ¡ha sido extendida!',
      needsToBeFinished: '¡Tienes una encuesta pulse {pulseSurveyName} que necesitas completar!',
      lastDayToFinish: '¡Hoy es el último día para completar {pulseSurveyName}!',
      thankYou: '¡Muchas gracias por tu participación en la encuesta pulse {pulseSurveyName}!',
    },
    sparck: {
      youRecognized: '¡Reconociste a {sparckedUser}!',
      recognizedBy: '¡Fuiste reconocido por {sparckOwner}!',
      takeActionForRecognition: '¡Toma acción respecto a este reconocimiento de {sparckOwner} para {sparckedUser}!',
      wasAnswered: 'Tu Sparck fue respondido por {sparckedUser}:',
      rewardRedeemed: '{sparckedUser} redimió su beneficio',
    },
    mentions: {
      title: '¡Fuiste mencionado(a) por {mentioningUser} en un(a) {sourceType}!',
    },
    suggestions: {
      newReply: {
        title: 'Recibiste una nueva {newReply} relacionada con una sugerencia',
        newReplyLabel: 'nueva respuesta',
      },
      newSuggestion: { title: 'Hay una nueva sugerencia de un empleado.' },
    },
    actionLabels: {
      viewSurvey: 'Ver Encuesta',
      viewPulseSurvey: 'Ver Encuesta Pulse',
      viewReport: 'Ver Informe',
      viewSparck: 'Ver Sparck',
      takeAction: 'Tomar Acción',
      viewPost: 'Ver Publicación',
      viewComment: 'Ver Comentario',
      goToChat: 'Ir al Chat',
      goToSuggestions: 'Ir a sugerencias',
    },
    yourNotifications: 'Tus Notificaciones',
    markAllRead: 'Marcar todas como leídas',
    noNotifications: 'No tienes notificaciones.',
  },
  managerCertification: {
    managerCertificationData: {
      roadmap: {
        dayOne: {
          title: "Bienvenido(a) a la Certificación Sparck",
          description: "Bienvenido(a) a la Sección 1 de la Certificación de Reconocimiento de Empleados Sparck. Para comenzar, te llevaremos a la línea de salida de la semana con una introducción al Sprint de 3 Minutos Sparck. Para completar con éxito la Sección 1, necesitas ver el video de capacitación y participar en nuestro cuestionario diario. ¡Diviértete!",
          sections: {
            learnItDescription: "Aquí es donde comienza la magia. En esta sección, podrás acceder fácilmente al video de capacitación y todos los recursos que necesitas para desarrollar estas nuevas habilidades.",
            masterItDescription: "Espero que hayas disfrutado del primer video. Es hora de probar tus conocimientos hasta aquí y poner en práctica las áreas designadas por Anna. Estamos ansiosos por ver a quién decidirás reconocer."
          },
          tasks: {
            trainingVideoName: "Video de Capacitación de la Sección 1",
            challengeName: "Desafío de la Sección 1"
          }
        },
        dayTwo: {
          title: "Minuto 1: Reflexiona - El Tiempo lo es Todo",
          description: "Bienvenido(a) a la Sección 2 de la Certificación! Ayer hicimos una introducción al Sprint de 3 Minutos Sparck y ahora es hora de revelar las capas. Hoy profundizarás en el Minuto 1 del Sprint Sparck: Reflexiona. Para completar con éxito la Sección 2, mira el video de capacitación, haz el recorrido de Sparck y participa en el cuestionario. ¡Diviértete!",
          sections: {
            practiceItDescription: "Esta es una nueva sección que se incorporará durante el resto de la semana. Anna usará lo que aprendiste en el video de capacitación y te mostrará cómo la tecnología te ayuda a aprovechar estas lecciones. ¡Abróchate el cinturón, es hora del recorrido!",
            masterItDescription: "Es hora de usar lo que has aprendido y ponerlo a prueba con el Cuestionario de la Sección 2. Esto no es para hacerte cometer errores, sino para ayudarte a dominar cada minuto del Sprint. Asegúrate de estar conectado a tu cuenta de Sparck y comienza el proceso de reflexión con los conocimientos de tu equipo."
          },
          tasks: {
            trainingVideoName: "Video de Capacitación de la Sección 2",
            challengeName: "Desafío de la Sección 2",
            challengeDescription: "Como sabes, el objetivo de la reflexión es identificar quién, por qué y cuándo debes reconocer en tu equipo. Potencia tus conocimientos Sparck y gana una visión inmediata del reconocimiento."
          }
        },
        dayThree: {
          title: "Minuto 2: Conéctate - Personaliza la Experiencia",
          description: "Bienvenido(a) a la Sección 3 de la Certificación! El día de hoy se enfocará en el Minuto 2 del Sprint Sparck: Conéctate. Para completar con éxito la Sección 3, mira el video de capacitación, haz el recorrido de Sparck y participa en el Cuestionario. Tenemos recursos como una hoja de trabajo de los diferentes Estilos de Apreciación y una lista de preguntas F.R.O.G. (Sabrás qué significa esto después del entrenamiento). Estos recursos te ayudarán a aumentar y fortalecer las conversaciones con tus empleados. ¡No olvides ver el video cómico también, seguro te hará reír!",
          sections: {
            masterItDescription: "Refuerza tus conocimientos y asegúrate de participar en el Cuestionario de la Sección 3. Pero no te detengas ahí, teniendo en cuenta a quién planeas reconocer al final de la certificación, entra a tu cuenta y verifica el Estilo de Apreciación Sparck de esa persona."
          },
          tasks: {
            trainingVideoName: "Video de Capacitación de la Sección 3",
            sparckAppreciationStyles: "Estilos de Apreciación Sparck",
            sparckAppreciationStylesDescription: "Aprende las diferentes maneras en que tus empleados les gusta ser reconocidos. Revisa los diferentes Estilos de Apreciación Sparck {link}. Para aprender más sobre los diferentes Estilos de Apreciación (y reír mucho), mira este video cómico creado por Betsaida LeBron, estratega en construcción de equipos, coach de gestión y fundadora de ImprovEQ. ¡Haz clic abajo para verlo!",
            challengeName: "Desafío de la Sección 3",
            challengeDescription: "¿Crees que ya conoces los estilos de apreciación de tu equipo? Descubre si estás en lo cierto."
          }
        },
        dayFour: {
          title: "Minuto 3: Reconoce - Es Hora de Actuar",
          description: "Bienvenido(a) a la Sección 4 de la Certificación! Hoy nos centraremos en el Minuto 3 del Sprint Sparck: Reconoce. ¡Ya estás casi en la meta! Ahora que ya sabes cómo Reflexionar y Conectarte, la pieza final es perfeccionar el reconocimiento. Para completar con éxito la Sección 4, mira el video de capacitación, haz el recorrido de Sparck y completa el Cuestionario. No olvides revisar los aspectos destacados en tus Recursos de Capacitación. ¡Diviértete!",
          sections: {
            masterItDescription: "Estás muy cerca, prueba tus conocimientos con el Cuestionario de la Sección 4. Y después de hoy, seguramente estarás curioso por descubrir qué recompensas son más significativas para tu equipo. Inicia sesión en tu cuenta y explora los perfiles de tus empleados."
          },
          tasks: {
            trainingVideoName: "Entrenamiento de la Sección 4",
            challengeName: "Desafío de la Sección 4",
            challengeDescription: "Piensa en la persona que vas a reconocer esta semana. Si tuvieras $10 para gastar, ¿qué le darías a esa persona? Estás a pocos clics de saberlo."
          }
        },
        dayFive: {
          title: "¡Es Hora de Reconocer a Tu Empleado!",
          description: "Bienvenido a la Sección 5 de la Certificación. ¡Estás a punto de cruzar la meta! Usa lo que has aprendido hasta aquí y combina las piezas mágicas del Sprint de 3 Minutos Sparck: Conéctate, Reflexiona y Reconoce para crear una experiencia de reconocimiento significativa. Mira el video de capacitación, haz el recorrido de Sparck y completa el cuestionario final. Para más inspiración, revisa tus recursos de capacitación. {remembermessage}",
          rememberMessage: "Y recuerda: además de los ejercicios, para convertirte oficialmente en un certificado, debes usar lo que aprendiste y reconocer a uno de tus empleados. Estamos ansiosos por escuchar tu historia.",
          sections: {
            masterItDescription: "¡Lo lograste! Espero que hayas disfrutado del sprint. Cruza la meta con el Cuestionario de la Sección 5 y comparte tu historia 'Sparck'. Si necesitas algunas ideas antes de actuar, asegúrate de usar los conocimientos que Anna compartió durante el recorrido y accede a tu cuenta."
          },
          tasks: {
            trainingVideoName: "Entrenamiento de la Sección 5",
            challengeName: "Desafío de la Sección 5",
            challengeDescription: "Echa un vistazo detrás de las cámaras e identifica cómo reconocer a todos los miembros de tu equipo en el Momento Correcto, de la Manera Correcta y con la Recompensa Correcta. ¡Es hora de Sparck en tu lugar de trabajo!"
          }
        },
        trainingResources: "Acceder a los Recursos de Capacitación",
        takeTheQuiz: "Participa en el Cuestionario",
        watchSparckTour: "Ver el Recorrido Sparck"
      },
      learnIt: 'Aprende',
      practiceIt: 'Ponlo en Práctica',
      masterIt: 'Domina',
      celebrationBoard: {
        title: '¡Celebremos tu Certificación!',
        description: '¡Celebra con nosotros! Siempre es más divertido compartir tu entusiasmo con los demás, por eso todos los que completen la Certificación verán sus nombres en el panel. Estamos ansiosos por ver el hermoso impacto que harás en tu organización. ¡Vamos a iluminar el mundo a través de Sparck!',
        congratulations: '¡Felicidades!'
      },
      leaderboard: {
        title: '¡Diviértete y ve tu posición en la Tabla de Clasificación!',
        header: 'Clasificación'
      },
      managerCertificationComponent: {
        minutes: {
          minuteOneDescription: 'El Minuto 1 te enseña a Reflexionar y aprovechar las oportunidades de reconocimiento en el Momento Correcto. ¡El tiempo lo es todo!',
          minuteTwoDescription: 'El Minuto 2 te enseña a Conectarte e identificar el Momento Correcto para reconocer. Es fundamental conocer las preferencias de tus empleados.',
          minuteThreeDescription: 'El Minuto 3 te enseña a Reconocer con la Recompensa Correcta. La personalización es clave, no seas genérico.'
        },
        secretComponents: {
          rightTime: 'Momento Correcto',
          rightWay: 'Forma Correcta',
          rightReward: 'Recompensa Correcta'
        },
        sections: {
          welcome: {
            title: 'Certificación de Reconocimiento de Empleados',
            description: {
              firstParagraph: 'Bienvenido, ¿listo para divertirte? En esta certificación aprenderás cómo incorporar nuestra Sprint de 3 Minutos Sparck en tu día. Ha sido preparada para equiparte para realizar reconocimiento personalizado eficiente. Esto aumentará no solo la felicidad de tus empleados sino también la productividad general y el compromiso. Esta certificación fue creada para ser completada en un periodo de 5 días, pero siéntete libre de profundizar y terminar todo a tu propio ritmo!',
              middleParagraph: 'Nuestra Sprint de 3 Minutos Sparck te preparará para realizar el tipo de reconocimiento correcto, todas las veces.',
              lastParagraph: 'Hay un arte en el acto de reconocer que nunca ha sido enseñado, estos son los aspectos para los cuales estarás preparado:'
            }
          },
          realLifeSparckStory: {
            title: 'Una Historia Real Sparck',
            subtitle: '¿Quieres ver el impacto que un reconocimiento personalizado puede causar?',
            description: 'Mira cómo la Sprint de 3 Minutos Sparck capacitó a nuestra CEO, Anna Straus, para reconocer a Abigail Kidd en el Momento Correcto, de la Manera Correcta, con la Recompensa Correcta. ¡Esta historia será analizada durante toda la certificación para dar vida al entrenamiento!'
          },
          sneakPeek: {
            title: 'Mira una Introducción de la Certificación Sparck',
            description: '¡Estás a punto de embarcarte en un emocionante viaje con nuestra Certificación de Reconocimiento de Empleados! Mira una introducción de lo que está por venir, donde nuestra CEO Anna Straus te guía a través de la Sprint de 3 Minutos Sparck!'
          },
          roadmap: {
            title: 'Mapa de la Certificación',
            description: 'Con solo 10 minutos al día, al final de un periodo de cinco días estarás certificado por el programa de Reconocimiento de Empleados Sparck. Este kit de recursos te mostrará todo lo que harás cada día para que tengas éxito al pasar por el programa. Mira lo que te espera y accede al Mapa de 5 Secciones haciendo clic en los tarjetas a continuación.',
            enter: 'ACCESAR'
          }
        },
      },
    },
    submitStoryCard: {
      title: 'Cuenta tu Historia',
      description:
        '¡Comparte todos los detalles emocionantes con nosotros mientras celebramos tu carrera hacia la meta! Si tienes una historia de una vez que alguien en el trabajo te hizo sentir verdaderamente visto, ¡también puedes compartir esa historia!',
      submitStoryForm: {
        snackbarSuccess: 'Tu historia ha sido enviada con éxito',
        tellYourStory: 'Cuenta tu historia',
        permissionToShareOnSocialMedia:
          '¿Nos permites compartir tu reconocimiento en las redes sociales?',
        canWeTagYouOnSocialMedia:
          '¿Podemos etiquetarte? Si es así, por favor proporciona los datos de tus redes sociales. (IG, Twitter, y LinkedIn)',
      },
    },
    managerCertificationTask: {
      day: 'Sección',
      clickForResource: 'Haz clic {link} para descargar {name}',
      trainingResourceForSection:
        'Recurso de Capacitación para la Sección {section}',
      clickForVideoScript: 'Haz clic {link} para el guion del video',
      frogQuestions: 'preguntas F.R.O.G.',
      takeTheQuiz: '¡Haz el Quiz!',
      takeMeToTheChallenge: 'Llévame al desafío!',
    },
    managerCertificationTasks: {
      trainingVideo: 'video de capacitación',
      trainingVideos: 'videos de capacitación',
      trainingResource: 'recurso de capacitación',
      trainingResources: 'recursos de capacitación',
      quiz: 'quiz',
      quizzes: 'quizzes',
      challenge: 'desafío',
      challenges: 'desafíos',
    },
    managerCertificationFormBuilder: {
      termsOfCommunicationFirstParagraph:
        'Sparck tiene un compromiso con la protección y el respeto a tu privacidad, y solo utilizaremos tus datos personales para gestionar tu cuenta y proporcionar los productos y servicios que solicites. De vez en cuando, nos gustaría ponernos en contacto contigo sobre nuestros productos y servicios, así como otros contenidos que puedan interesarte. Si estás de acuerdo, por favor marca la casilla a continuación para indicarnos cómo te gustaría que nos pongamos en contacto.',
      termsOfCommunicationMiddleParagraph:
        'Puedes cancelar la recepción de estas comunicaciones en cualquier momento. Para más información sobre cómo cancelar, nuestras prácticas de privacidad y cómo estamos comprometidos con la protección y el respeto a tu privacidad, por favor revisa nuestra Política de Privacidad.',
      termsOfCommunicationLastParagraph:
        'Al hacer clic en el botón de enviar a continuación, autorizas a Sparck a almacenar y procesar la información enviada arriba para proporcionarte el contenido solicitado.',
      agreeToReceiveCommunications:
        'Acepto recibir otras comunicaciones de Sparck',
    },
    managerCertificationQuizDialog: {
      title: 'Quiz de la sección {section}',
      requiredField: 'Por favor, completa este campo obligatorio',
      snackbarSuccess: '¡Tus respuestas han sido enviadas con éxito!',
      quizQuestions: {
        sectionOne: {
          whoAreYouGoingToRecognize:
            '¿A quién vas a reconocer al final de esta certificación?',
          runningBuddy:
            '¿A quién elegiste como tu "compañero de camino" (opcional)?',
          threeComponentsOfThreeMinuteSparckSprint:
            '¿Cuáles son los tres componentes de la Sprint de tres minutos Sparck?',
          threeAreasToDeliverEffectiveRecognition:
            'Las tres áreas que necesitas considerar para realizar un reconocimiento efectivo son En el Momento Correcto, De la Manera Correcta, y con la ____ Correcta.',
          feedbackOrSuggestionsSection1:
            '¿Algún comentario, sugerencia o idea que te gustaría compartir sobre la capacitación? (opcional)',
        },
        sectionTwo: {
          didThePersonChange:
            '¿La persona que elegiste para reconocer cambió basado en este tiempo de reflexión? Si es así, ¿a quién?',
          goalDuringYourReflection:
            'El objetivo durante tu reflexión es identificar Quién, Por qué y ______ reconocer a alguien.',
          feedbackOrSuggestionsSection2:
            '¿Alguna idea, sugerencia o comentario que quieras compartir para hacer este entrenamiento más valioso? (opcional)',
        },
        sectionThree: {
          gInTheFrogStandsFor:
            '¿Qué significa la "G" en el modelo conversacional F.R.O.G.?',
          yourSparckAppreciationStyle:
            '¿Cuál es tu Estilo de Apreciación Sparck? ¿En qué estás de acuerdo y qué cambiarías?',
          appreciationStyleOfTheEmployeeYouAreGoingToRecognize:
            '¿Cuál es el Estilo de Apreciación Sparck del empleado que vas a reconocer?',
          feedbackOrSuggestionsSection3:
            '¿Te gustaría compartir algún comentario, sugerencia o idea para mejorar este entrenamiento? (opcional)',
        },
        sectionFour: {
          howWouldYouDescribeTheTwoTypesOrRewards:
            'Después de esta capacitación, ¿cómo describirías los dos tipos de recompensa que puedes ofrecer?',
          recognitionNeedsToBeFrequentSpecificAnd:
            'El reconocimiento es un refuerzo positivo que debe ser frecuente, específico y _________.',
          whatAreYouRecognizingThemForSection4:
            'Piensa en la persona que vas a reconocer. ¿Por qué la vas a reconocer?',
          feedbackOrSuggestionsSection4:
            '¿Te gustaría compartir algún comentario, sugerencia o idea para mejorar este entrenamiento?',
        },
        sectionFive: {
          mostValuableThingYouLearnedThisWeek:
            '¿Cuál es la cosa más valiosa que aprendiste esta semana?',
          whatWouldYouChangeAboutTheCertification:
            '¿Qué cambiarías sobre esta certificación?',
          whatDayAreYouGoingToRecognizeThem:
            'Reflexiona: Ahora que sabes a quién vas a reconocer, ¿qué día lo reconocerás?',
          howAreYouGoingToRecognizeThem:
            'Conéctate: Ahora que conoces las preferencias de reconocimiento, ¿cómo vas a reconocer a la(s) persona(s)?',
          whatAreYouRecognizingThemForSection5:
            'Reconoce: Ahora que sabes que tus palabras deben ser genuinas y específicas, ¿por qué motivo vas a reconocer a la(s) persona(s)?',
          whatIsYourRecognitionPreference:
            'Nos encanta celebrar con aquellos que ya están certificados. ¿Cuál es tu preferencia de reconocimiento?',
          canWeTagYouInSocialMedia:
            '¿Podemos etiquetarte en una publicación en nuestras redes sociales cuando finalices? (Escribe Sí o No)',
          testimonial: {
            description:
              '¡Hola, Estrella-Sparck! Estás en camino de obtener tu certificación. Si sientes que esta capacitación te ha agregado valor y tienes un testimonio que podamos compartir con otros gerentes, ¡nos encantaría escucharlo!',
            label: 'Tu Testimonio',
          },
        },
      },
    },
    managerCertification5DayRoadmap: {
      title: 'Mapa de Certificación de 5 Días',
      description:
        'Este kit de herramientas fue desarrollado para un período de 5 días, pero ¡no necesitas esperar! Puedes completar el entrenamiento a tu propio ritmo. Te mostrará cada día a medida que avances en el programa para proporcionarte todo lo que necesitas para tener éxito. Mira lo que te espera y accede al Mapa de 5 Días haciendo clic en el botón de abajo. Podrás leer más sobre estas historias a medida que te acerques a la conclusión de la certificación.',
      downloadRoadmap: 'Descargar el mapa',
    },
    submit: 'ENVIAR',
    noOneHasCompletedTheCertification:
      'Ningún usuario ha completado la certificación hasta el momento.',
  },
  mobile: {
    feed: {
      floatingButton: {
        dialogTitle: 'Sobre Nosotros'
      }
    },
  }
}
