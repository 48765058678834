/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from 'react'

import { useMutation, useQuery } from 'react-query'
import { API } from 'core/requests'
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  styled,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
// import { DatePicker } from '@mui/lab'
import { addDays, startOfDay } from 'date-fns'
import { get } from 'lodash'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router'
import * as yup from 'yup'

import { ManagePulseSurveyQuestions } from './components'
import {
  DemographicsPicker,
  FormHandler,
  RolesPicker,
  SurveyPreviewDialog,
} from 'components'
import { initialDialog } from 'constants/dialog'
import { ROUTES_PATHS } from 'core/routes'
import { PulseSurveyQuestionType } from 'enums/pulseSurvey'
import { SurveyStatus as PulseSurveyStatus } from 'enums/survey'
import { t } from 'i18n'
import { PulseSurveys } from 'pages'
import {
  DateTimePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)
import { CompanyType } from 'types'
import { useCompany } from 'contexts'
import { timezones } from 'constants/company'

const validationSchema = yup.object({
  name: yup
    .string()
    .nullable()
    .required(t('pulseSurveyOverview.validations.pulseSurveyNameIsRequired')),
  status: yup.string().nullable().required(),
  reward_amount: yup
    .string()
    .nullable()
    .when('allow_reward', {
      is: true,
      then: yup
        .string()
        .nullable()
        .required(t('surveyOverview.validations.rewardAmountIsRequired'))
        .test(
          'min-monetary-value',
          t('surveyOverview.validations.rewardAmountIsRequired'),
          function (value: any) {
            if (this.parent.reward_amount) {
              return value >= 1.0
            }
            return false
          },
        ),
    }),
  open_at: yup
    .date()
    .typeError(t('invalidDateFormat'))
    .nullable()
    .when('status', {
      is: PulseSurveyStatus.Scheduled,
      then: yup
        .date()
        .typeError(t('invalidDateFormat'))
        .nullable()
        .min(
          startOfDay(new Date()),
          t('pulseSurveyOverview.validations.minimumLaunchDate'),
        )
        .max(
          yup.ref('close_at'),
          t('pulseSurveyOverview.validations.maximumLaunchDate'),
        )
        .required(t('pulseSurveyOverview.validations.launchDateIsRequired')),
    }),
  close_at: yup
    .date()
    .typeError(t('invalidDateFormat'))
    .nullable()
    .when('status', {
      is: PulseSurveyStatus.Scheduled,
      then: yup
        .date()
        .typeError(t('invalidDateFormat'))
        .nullable()
        .min(
          yup.ref('open_at'),
          t('pulseSurveyOverview.validations.minimumCloseDate'),
        )
        .required(t('pulseSurveyOverview.validations.closeDateIsRequired')),
    }),
})

const getPreviewData = response => {
  if (!response) return null

  const PREVIEW_SURVEY_ID = 1

  const previewData = {
    ...response,
    id: PREVIEW_SURVEY_ID,
    questions_attributes: response.questions_attributes?.map(
      (question, index) => ({
        ...question,
        id: index + 1,
        survey_id: PREVIEW_SURVEY_ID,
      }),
    ),
  }

  return previewData
}

export default function ManagePulseSurvey({
  dialog,
  closeDialog,
  fetchPulseSurveys,
  isEditMode,
}: any) {
  const [pulseQuestions, setPulseQuestions] = useState<
    {
      statement: string
      show_importance: boolean
      open_ended: boolean
    }[]
  >(dialog?.data?.questions_attributes ?? [])
  const [previewDialog, setPreviewDialog] = useState(initialDialog)
  const initialCompany: CompanyType = {}
  const [companyState, setCompanyState] = useState(initialCompany)
  const { company, setCompany } = useCompany()!
  const intl = useIntl()
  const history = useHistory()

  const questionsAttributes = pulseQuestions.map(question => ({
    ...question,
    type: question.open_ended
      ? question.open_ended && question.show_importance
        ? PulseSurveyQuestionType.OpenEndedAndImportance
        : PulseSurveyQuestionType.OpenEnded
      : question.show_importance
        ? PulseSurveyQuestionType.SatisfactionAndImportance
        : PulseSurveyQuestionType.Satisfaction,
  }))

  const { data: demographics, isLoading: demographicsIsLoading } = useQuery(
    'getDemographics',
    () => API.get('demographics'),
  )

  const { data: pulseLibraryQuestions } = useQuery(
    'getPulseLibraryQuestions',
    () => API.get('pulseLibraryQuestions'),
  )

  const previewMutation = useMutation(
    (payload: any) => API.create('pulseSurveyPreview', payload),
    {
      onSuccess: (response: any) => {
        if (response && !response.errors) {
          setPreviewDialog({ isOpen: true, data: getPreviewData(response) })
        }
      },
    },
  )

  const initialValues =
    dialog && dialog?.data
      ? dialog?.data
      : {
        name: '',
        open_at: null,
        close_at: null,
        demographics: [],
        roles_who_can_see_insights: [],
        questions_attributes: [],
      }

  useEffect(() => {
    if (get(company, 'id')) {
      setCompanyState({ ...company })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company])

  const updateCompanyMutation = useMutation(
    // #TODO This part must be refactored to remove this return
    (payload: any) => {
      return Promise.resolve({})
    },
    {
      onSettled: (response: any, error: any, variables, contexts) => {
        const selectedDemographics = variables.values.demographics?.filter(
          demographic => demographic.demographics?.length > 0,
        )
        const values = { ...variables.values }

        variables.createUpdateAction({
          ...values,
          id: dialog?.data?.isClone ? null : variables.values.id,
          demographics: selectedDemographics,
          questions_attributes: dialog?.data?.isClone
            ? questionsAttributes.map((question: any) => {
              delete question.id
              delete question.survey_id
              return question
            })
            : questionsAttributes,
        })
      },
    }
  )

  return (
    <>
      <FormHandler
        title={intl.formatMessage({ id: 'pulseSurveyOverview.dialogs.title' })}
        descriptionCreateMode={
          <span>
            {t('pulseSurveyOverview.dialogs.create.descriptionFirstLine')}{' '}
            <a
              href='https://faq.sparckco.com/knowledge/understanding-pulse-surveys'
              rel='noopener noreferrer'
              target='_blank'
            >
              {t('pulseSurveyOverview.dialogs.create.link')}{' '}
            </a>
            {t('pulseSurveyOverview.dialogs.create.descriptionSecondLine')}
          </span>
        }
        descriptionUpdateMode={
          <span>
            {t('pulseSurveyOverview.dialogs.edit.descriptionFirstLine')}{' '}
            <a
              href='https://faq.sparckco.com/knowledge/understanding-pulse-surveys'
              rel='noopener noreferrer'
              target='_blank'
            >
              {t('pulseSurveyOverview.dialogs.edit.link')}{' '}
            </a>
            {t('pulseSurveyOverview.dialogs.edit.descriptionSecondLine')}
          </span>
        }
        requestUrl='pulseSurveys'
        detailsRequestId={get(dialog, 'data.id')}
        detailsRequestCallback={(response: any) => {
          if (response) {
            setPulseQuestions(
              response.questions_attributes.map((question: any) => ({
                ...question,
                show_importance:
                  question.type === PulseSurveyQuestionType.SatisfactionAndImportance ||
                  question.type === PulseSurveyQuestionType.OpenEndedAndImportance,
                open_ended:
                  question.type === PulseSurveyQuestionType.OpenEnded ||
                  question.type === PulseSurveyQuestionType.OpenEndedAndImportance,
              }))
            )

            if (!dialog) {
              window.location.replace(
                window.location.origin + ROUTES_PATHS.pulseSurveyOverview,
              )
            }
          }
        }}
        data={get(dialog, 'data')}
        closeDialog={closeDialog}
        fetchRows={fetchPulseSurveys}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, createUpdateAction) => {
          if (values.status === "scheduled") {
            values.open_at = dayjs(values.open_at).format("YYYY-MM-DD HH:00:00")
            values.close_at = dayjs(values.close_at).format("YYYY-MM-DD HH:00:00")

            const openDateInNewTimezone = dayjs.tz(values.open_at, company.timezone)
            const closeDateInNewTimezone = dayjs.tz(values.close_at, company.timezone)

            values.open_at = openDateInNewTimezone.format('YYYY-MM-DDTHH:mm:ssZ')
            values.close_at = closeDateInNewTimezone.format('YYYY-MM-DDTHH:mm:ssZ')
            updateCompanyMutation.mutate({ companyState, createUpdateAction, values })
          } else {
            const selectedDemographics = values.demographics?.filter(
              demographic => demographic.demographics?.length > 0,
            )

            createUpdateAction({
              ...values,
              id: dialog?.data?.isClone ? null : values.id,
              demographics: selectedDemographics,
              questions_attributes: dialog?.data?.isClone
                ? questionsAttributes.map((question: any) => {
                  delete question.id
                  delete question.survey_id

                  return question
                })
                : questionsAttributes,
            })
          }

        }}
        mutationOnSuccess={response => {
          history.push(
            `${ROUTES_PATHS.pulseSurveyOverview}/${dialog && response.id}`,
          )

          closeDialog()
          fetchPulseSurveys()
        }}
      // secondaryActionButtons={[
      //   {
      //     id: 'preview',
      //     label: 'Preview',
      //     onClick: formik => {
      //       previewMutation.mutate({
      //         ...formik.values,
      //         open_at:
      //           formik.values.status === PulseSurveyStatus.Scheduled
      //             ? formik.values.open_at
      //             : null,
      //         close_at:
      //           formik.values.status === PulseSurveyStatus.Scheduled
      //             ? formik.values.close_at
      //             : null,
      //         questions_attributes: questionsAttributes.map(question => ({
      //           ...question,
      //           id: null,
      //           survey_id: null,
      //         })),
      //       })
      //     },
      //     variant: 'outlined',
      //     color: 'primary',
      //     isLoading: false,
      //   },
      // ]}
      >
        {({ formik }) => (
          <ManagePulseSurveyForm
            intl={intl}
            formik={formik}
            isEditMode={isEditMode}
            isClone={dialog?.data?.isClone}
            demographics={demographics}
            demographicsIsLoading={demographicsIsLoading}
            pulseQuestions={pulseQuestions}
            setPulseQuestions={setPulseQuestions}
            pulseLibraryQuestions={pulseLibraryQuestions}
            previewDialog={previewDialog}
            setPreviewDialog={setPreviewDialog}
            companyState={companyState}
            setCompanyState={setCompanyState}
          />
        )}
      </FormHandler>
    </>
  )
}

const StyledDatepicker = styled(DateTimePicker)(() => ({
  width: '100%'
}))

function ManagePulseSurveyForm({
  intl,
  formik,
  isEditMode,
  isClone,
  demographics,
  demographicsIsLoading,
  pulseQuestions,
  setPulseQuestions,
  pulseLibraryQuestions,
  previewDialog,
  setPreviewDialog,
  companyState,
  setCompanyState
}) {
  const { data: roles } = useQuery('getRoles', () => API.get('roles'))

  const theme: any = useTheme()
  const [companyTimezone,] = useState(companyState.timezone)

  useEffect(() => {
    if (demographics?.length > 0) {
      const savedCategories = formik.values.demographics?.map(d => d.id)

      const newDemographics = demographics
        .filter(demographic => !savedCategories.includes(demographic.id))
        .map(demographic => ({
          category: demographic.id,
          demographics: [],
        }))

      formik.setFieldValue('demographics', [
        ...(formik.values.demographics ?? []),
        ...newDemographics,
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demographics])

  useEffect(() => {
    if (!formik.values.status) return

    if (formik.values.status === PulseSurveyStatus.AwaitingManualOpening) {
      formik.setFieldValue('open_at', null)
      formik.setFieldValue('close_at', null)
    }

    // if (formik.values.status === PulseSurveyStatus.Scheduled) {
    //   const openAt = new Date()
    //   openAt.setHours(openAt.getHours()+1,0,0)
    //   formik.setFieldValue('open_at', formik.values.open_at ?? openAt)
    //   formik.setFieldValue(
    //     'close_at',
    //     formik.values.close_at ?? addDays(new Date(), 5),
    //   )
    // }

    if (formik.values.status === PulseSurveyStatus.Scheduled) {
      const openAt = new Date()
      openAt.setHours(openAt.getHours() + 1, 0, 0)
      formik.setFieldValue('open_at', dayjs(formik?.values?.open_at).tz(companyState.timezone) ?? openAt)
      formik.setFieldValue(
        'close_at',
        dayjs(formik?.values?.close_at).tz(companyState.timezone) ?? addDays(new Date().setHours(0, 0, 0), 5),
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.status])

  useEffect(() => {
    if (isClone) {
      formik.setFieldValue('name', '')
      formik.setFieldValue('status', null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClone])

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              data-cy='name'
              id='name'
              name='name'
              label={t('pulseSurveyOverview.dialogs.formLabels.pulseSurveyName')}
              placeholder={intl.formatMessage({
                id: 'pulseSurveyOverview.dialogs.formLabels.pulseSurveyName',
              })}
              variant='outlined'
              value={formik.values.name}
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} my={1}>
            <Grid
              container
              p={2}
              sx={{
                border: `1px solid ${theme.palette.text.veryLight}`,
                borderRadius: 2,
              }}
            >
              <Grid item>
                <FormControl
                  error={formik.touched.status && formik.errors.status}
                  variant='standard'
                >
                  <FormLabel id='launch-type-radio-buttons-group-label'>
                    {t('surveyLaunchMode.label')}
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='launch-type-radio-buttons-group'
                    name='status'
                    value={formik.values.status}
                    onChange={formik.handleChange}
                    sx={{ mt: 1 }}
                  >
                    <FormControlLabel
                      value={PulseSurveyStatus.AwaitingManualOpening}
                      control={<Radio />}
                      label={t('surveyLaunchMode.manual.label')}
                    />

                    <FormControlLabel
                      value={PulseSurveyStatus.Scheduled}
                      control={<Radio />}
                      label={t('surveyLaunchMode.scheduled.label')}
                    />
                  </RadioGroup>
                  {formik.touched.status && formik.errors.status && (
                    <FormHelperText>{formik.errors.status}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item my={2}>
                <Typography variant='body2' color='text.secondary'>
                  {formik.values.status ===
                    PulseSurveyStatus.AwaitingManualOpening &&
                    t('surveyLaunchMode.manual.description', {
                      launchButtonLabel: t(
                        'pulseSurveyOverview.launchPulseSurvey',
                      ),
                    })}

                  {formik.values.status === PulseSurveyStatus.Scheduled &&
                    t('surveyLaunchMode.scheduled.description', {
                      launchButtonLabel: t(
                        'pulseSurveyOverview.launchPulseSurvey',
                      ),
                    })}
                </Typography>
              </Grid>

              {formik.values.status === PulseSurveyStatus.Scheduled && (
                <Grid item xs={12} mt={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <StyledDatepicker
                        label={t('surveyDialogs.formLabels.launchDate')}
                        value={dayjs(formik.values.open_at)}
                        disablePast
                        onChange={(value: any) => {
                          formik.setFieldValue('open_at', dayjs(value))
                        }}
                        views={['year', 'day', 'hours']}
                        timezone={companyTimezone}
                      />
                      {formik.touched.open_at && Boolean(formik.errors.open_at) && (
                        <FormHelperText>{formik.errors.open_at}</FormHelperText>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <StyledDatepicker
                        label={t('surveyDialogs.formLabels.closeDate')}
                        value={dayjs(formik.values.close_at)}
                        disablePast
                        onChange={(value: any) => {
                          formik.setFieldValue('close_at', dayjs(value))
                        }}
                        minDate={dayjs(formik.values.open_at)}
                        views={['year', 'day', 'hours']}
                        timezone={companyTimezone}
                      />
                      {formik.touched.close_at && Boolean(formik.errors.close_at) && (
                        <FormHelperText>{formik.errors.close_at}</FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        select
                        data-cy='timezone'
                        id='timezone'
                        name='timezone'
                        label={intl.formatMessage({
                          id: 'settings.formLabels.timezone',
                        })}
                        placeholder={intl.formatMessage({
                          id: 'settings.placeholders.timezone',
                        })}
                        variant='outlined'
                        fullWidth
                        margin='normal'
                        style={{ margin: '0px' }}
                        value={companyState.timezone}
                        onChange={event =>
                          setCompanyState({
                            ...companyState,
                            timezone: event.target.value,
                          })
                        }
                        disabled
                      >
                        {timezones.map(timezone => (
                          <MenuItem
                            key={timezone.value}
                            value={timezone.value}
                            data-cy='timezone_option'
                          >
                            {timezone.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <DemographicsPicker
              key='demographics'
              fieldName='demographics'
              formik={formik}
              demographics={demographics}
              demographicsIsLoading={demographicsIsLoading}
              label={t('pulseSurveyOverview.dialogs.demographicsPickerLabel')}
            />
          </Grid>

          <Grid item xs={12}>
            <RolesPicker
              fieldName='roles'
              roles={roles}
              formik={formik}
              selectLabel={t(
                'pulseSurveyOverview.dialogs.rolesPicker.selectLabel',
              )}
              label={t('pulseSurveyOverview.dialogs.rolesPicker.label')}
            />
          </Grid>

          <Grid item xs={12}>
            <ManagePulseSurveyQuestions
              pulseQuestions={pulseQuestions}
              setPulseQuestions={setPulseQuestions}
              pulseLibraryQuestions={pulseLibraryQuestions}
            />
          </Grid>
        </Grid>

        <SurveyPreviewDialog
          size='xl'
          previewDialog={previewDialog}
          setPreviewDialog={setPreviewDialog}
        >
          <PulseSurveys preview={previewDialog?.data} />
        </SurveyPreviewDialog>
      </LocalizationProvider>
    </>
  )
}

// function enqueueSnackbar(arg0: any, arg1: { variant: string }) {
//   throw new Error('Function not implemented.')
// }

