import { useState, MouseEvent, CSSProperties } from 'react'

import {
  alpha,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  CheckOutlined as CheckIcon,
  DeleteOutlined as DeleteIcon,
  EditOutlined as EditIcon,
  MoreVert as MoreVertIcon,
} from '@mui/icons-material'
import { formatRelative, parseISO } from 'date-fns'
import parse from 'html-react-parser'
import { get } from 'lodash'

import { MediaDisplay, TextFieldWithMentions, UserAvatar } from 'components'
import { MediaType } from 'components/MediaPicker'
import { UserPermission } from 'enums/user'
import { t } from 'i18n'
import { UseMutationResult } from 'react-query'
import { CommentType } from 'types'
import { mentionsToPlainText } from 'utils/mentionsParser'

export interface CommentState extends CommentType {
  editMode: boolean
}

type Props = {
  comment: CommentType
  publishComment: (any) => void
  publishCommentStatus: UseMutationResult
  deleteCommentMutation: UseMutationResult
  user: {
    id: number
    permissions?: any[]
  }
  style?: CSSProperties
  highlightedCommentId?: any
}

export const gifStyle = {
  width: 240,
  maxWidth: '100%',
  maxHeight: 240,
  borderRadius: 4,
  marginTop: 8,
}

export default function FeedComment({
  comment,
  publishComment,
  publishCommentStatus,
  deleteCommentMutation,
  user,
  highlightedCommentId,
}: Props) {
  const [commentState, setCommentState] = useState(comment)
  const [commentIsEditMode, setCommentIsEditMode] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const theme = useTheme()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const formattedComment = mentionsToPlainText(commentState.content)

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleEdit = () => {
    if (commentIsEditMode) {
      publishComment({
        ...commentState,
      })
      setCommentIsEditMode(false)
      handleMenuClose()
    } else {
      setCommentIsEditMode(true)
      handleMenuClose()
    }
  }

  const handleSaveComment = () => {
    publishComment({
      ...commentState,
    })
    setCommentIsEditMode(false)
  }

  return (
    <Box
      marginBottom={2}
      width={1}
      id={`comment-${comment.id}`}
      key={comment.id}
    >
      <Grid
        item
        xs={12}
        key={comment.id}
        {...(highlightedCommentId === comment.id && {
          sx: {
            outline: `1.5px solid ${theme.palette.primary.main}`,
            bgcolor: alpha(theme.palette.primary.main, 0.05),
            p: 1.5,
            borderRadius: 2,
          },
        })}
      >
        <Grid container spacing={1} data-cy='comment'>
          <Grid item xs={2} md='auto'>
            <UserAvatar
              userId={get(comment, 'user.id')}
              name={get(comment, 'user.name')}
              src={get(comment, 'user.avatar.url')}
              size={40}
            />
          </Grid>
          <Grid item xs={10} md sx={{ position: 'relative' }}>
            <Box
              component='div'
              sx={{
                backgroundColor: '#f0f2f5',
                borderRadius: '16px',
                padding: '8px 16px',
              }}
            >
              <Grid
                container
                alignItems='center'
                justifyContent='space-between'
                spacing={1}
                sx={{ mb: 1 }}
              >
                <Grid item>
                  <Grid container alignItems='center' spacing={1}>
                    <Grid item>
                      <Typography
                        variant='body2'
                        color='text.primary'
                        fontWeight={600}
                        style={{ maxWidth: isMobile ? '160px' : '' }}
                      >
                        {get(comment, 'user.name')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                {user.id === get(comment, 'user.id') &&
                  (user.permissions ?? []).includes(
                    UserPermission.ManageComments,
                  ) && (
                    <>
                      {isMobile ? (
                        <>
                          <IconButton
                            size='small'
                            onClick={commentIsEditMode ? handleMenuClose : handleMenuOpen}
                            data-cy='more_vert'
                            sx={{
                              position: 'absolute',
                              top: 8,
                              right: 8,
                            }}
                          >
                            {commentIsEditMode ? (
                              <CheckIcon
                                onClick={handleSaveComment}
                                sx={{
                                  color: 'primary.main',
                                }}
                              />
                            ) : (
                              <MoreVertIcon />
                            )}
                          </IconButton>
                          <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                          >
                            <MenuItem
                              onClick={handleEdit}
                              data-cy='edit_comment'
                              sx={{
                                color: 'primary.main',
                              }}
                            >
                              <EditIcon fontSize='small' sx={{ marginRight: 1 }} />
                              {t('feed.commentManagement.edit')}
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                deleteCommentMutation.mutate({ id: comment.id });
                                handleMenuClose();
                              }}
                              data-cy='delete_comment'
                              sx={{
                                color: 'error.main',
                              }}
                            >
                              <DeleteIcon fontSize='small' sx={{ marginRight: 1 }} />
                              {t('feed.commentManagement.delete')}
                            </MenuItem>
                          </Menu>
                        </>
                      ) : (
                        <Box>
                          <Grid container alignItems='center'>
                            <IconButton
                              data-cy='edit_comment'
                              size='small'
                              onClick={handleEdit}
                            >
                              {publishCommentStatus.isLoading &&
                                get(publishCommentStatus, 'variables.id') ===
                                comment.id ? (
                                <CircularProgress size={24} color='primary' />
                              ) : commentIsEditMode ? (
                                <CheckIcon
                                  color='primary'
                                  onClick={() => {
                                    publishComment({
                                      ...commentState,
                                    });
                                    setCommentIsEditMode(false);
                                  }}
                                />
                              ) : (
                                <EditIcon color='primary' />
                              )}
                            </IconButton>

                            <IconButton
                              data-cy='delete_comment'
                              size='small'
                              onClick={() =>
                                deleteCommentMutation.mutate({ id: comment.id })
                              }
                            >
                              {deleteCommentMutation.isLoading &&
                                get(deleteCommentMutation, 'variables.id') ===
                                comment.id ? (
                                <CircularProgress size={24} color='secondary' />
                              ) : (
                                <DeleteIcon color='error' />
                              )}
                            </IconButton>
                          </Grid>
                        </Box>
                      )}
                    </>
                  )}
              </Grid>
              {commentIsEditMode ? (
                <>
                  <TextFieldWithMentions
                    initialContent={commentState?.content}
                    onContentChange={(content, mentions) =>
                      setCommentState({
                        ...commentState,
                        content,
                        mentions,
                      })
                    }
                  />
                  {comment?.gif_id_on_provider && (
                    <Box mt={4}>
                      <MediaDisplay
                        media={{
                          id: comment?.gif_id_on_provider,
                          src: '',
                          isImage: true,
                          name: comment?.gif_id_on_provider,
                          type: MediaType.Gif,
                        }}
                      />
                    </Box>
                  )}
                </>
              ) : (
                <>
                  <Typography
                    variant='body1'
                    color='text.primary'
                    style={{ maxWidth: '60ch' }}
                  >
                    {parse(formattedComment)}
                  </Typography>

                  {comment?.gif_id_on_provider && (
                    <MediaDisplay
                      media={{
                        id: comment?.gif_id_on_provider,
                        src: '',
                        isImage: true,
                        name: comment?.gif_id_on_provider,
                        type: MediaType.Gif,
                      }}
                    />
                  )}
                </>
              )}

              {comment.updated_at !== comment.created_at && (
                <Box mt={1} width={1}>
                  <Grid container justifyContent='flex-end'>
                    <Typography
                      variant='body2'
                      style={{ fontSize: '11px' }}
                      color='text.secondary'
                    >
                      {t('feed.commentInfo.updated')}{' '}
                      {formatRelative(parseISO(comment.updated_at), new Date())}
                    </Typography>
                  </Grid>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
