import { get, intersection, isEmpty } from 'lodash'

export const capitalizeFirstLetter = (value: any = '') => {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const validateRoutePermission = (user, route) => {
  const userApplications = get(user, 'applications', {})

  const currentUserApplications = Object.entries({
    beseen: userApplications.beseen || user?.company?.beseen_application,
    beheard: userApplications.beheard || user?.company?.beheard_application,
  })
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(([_key, value]) => value)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .map(([key, _value]) => key)

  if (!user.sparck_administrator && route.sparckAdministratorOnly) return false

  if (route.isSurvey) {
    return true
  }

  const userRoles = route.userRoles ?? []
  const requiredPermissions = route.requiredPermissions ?? []

  const hasValidRole = userRoles.length > 0 && userRoles.some(role => (user.roles ?? []).includes(role))
  const hasValidPermissions = isEmpty(requiredPermissions) || requiredPermissions.some(permission =>
    (user.permissions ?? []).includes(permission)
  )

  return (
    user.sparck_administrator ||
    (hasValidRole && hasValidPermissions && !isEmpty(intersection(route.applications, currentUserApplications)))
  )
}
