import { Box, Button, Dialog, Grid, Typography, useTheme } from '@mui/material'
import sparckLogoColored from 'assets/images/sparck-logo-colored.png'
import { ROUTES_PATHS } from 'core/routes'
import { t } from 'i18n'

export default function OnboardingFinish({ dialogFinish, setDialogFinish }) {
  const theme = useTheme()

  return (
    <Dialog
      open={dialogFinish}
      onClose={() => setDialogFinish(false)}
      maxWidth="sm"
      fullWidth
    >
      <Grid container justifyContent="center">
        <Box my={5}>
          <img
            src={sparckLogoColored}
            alt="Sparck onboarding finish banner"
            style={{
              width: 120,
              height: 120,
            }}
          />
        </Box>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <Grid
            component="div"
            sx={{
              backgroundColor: 'primary.main',
              position: 'relative',
            }}
          >
            <Box
              component="div"
              sx={{
                background: `linear-gradient(
                  127.84deg,
                  ${theme.palette.secondary.dark} 0%,
                  ${theme.palette.secondary.main} 100%
                )`,
                margin: '0 auto',
                padding: '16px 32px',
                width: '100%',
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.125)',
              }}
            >
              <Typography
                variant="h4"
                color="text.white"
                fontWeight={700}
                align="center"
              >
                {t('onboarding.finish.congratulations')}
              </Typography>
            </Box>
            <Grid p={4}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    color="text.white"
                    fontWeight={600}
                    align="center"
                    paragraph
                  >
                    {t('onboarding.finish.completedOnboarding')}
                  </Typography>

                  <Box my={4} width={1}>
                    <Typography
                      variant="body1"
                      color="text.white"
                      fontWeight={500}
                      paragraph
                      style={{ lineHeight: 1.8 }}
                    >
                      {t('onboarding.finish.thankYou')}
                      <br />
                      {t('onboarding.finish.revealRecognitionStyle')}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Grid
                    container
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    gap={2}
                  >
                    <Grid item xs={12} sm={6}>
                      <Button
                        variant="outlined"
                        size="large"
                        fullWidth
                        sx={{
                          '&.MuiButton-outlined': {
                            color: 'white',
                            borderColor: 'white',
                          },
                          fontWeight: 'bold',
                        }}
                        onClick={() => {
                          window.location.replace(window.location.origin)
                        }}
                      >
                        {t('onboarding.finish.takeMeToMyDashboard')}
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Button
                        variant="outlined"
                        size="large"
                        fullWidth
                        sx={{
                          '&.MuiButton-outlined': {
                            color: 'white',
                            borderColor: 'white',
                          },
                          fontWeight: 'bold',
                        }}
                        onClick={() => {
                          const onboardingStatus = 'finish';
                          window.location.replace(`${ROUTES_PATHS.profile}?onboardingStatus=${onboardingStatus}`)
                        }}
                      >
                        See my results!
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  )
}
