import { useWeglot } from 'react-weglot'
import LanguageIcon from '@mui/icons-material/Language'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import Popover from '@mui/material/Popover'
import Button from '@mui/material/Button'
import { Grid, Theme, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'

const LanguageSwitcher = () => {
  const [lang, setLang] = useWeglot('wg_ca83f684e2bea1afe0dc71a735e3fe690', 'en')
  const theme = useTheme()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  return (
    <Grid item sx={{ mt: 1 }} >
      <PopupState variant="popover" popupId="language-switcher-popup">
        {(popupState) => (
          <div>
            <Button {...bindTrigger(popupState)} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px' }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                <LanguageIcon style={{ fontSize: isMobile ? '1.5rem' : '2rem', color: theme.palette.text.secondary }} />

                <Typography
                  variant="caption"
                  sx={{
                    textAlign: 'center',
                    textTransform: 'none',
                    color: theme.palette.text.primary,
                  }}
                >
                  Language: <span style={{ color: theme.palette.primary.main, fontSize: '1rem' }}>{lang}</span>
                </Typography>
              </div>
            </Button>

            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
            >
              <div style={{ padding: '10px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <button
                  onClick={() => {
                    setLang('en')
                    popupState.close()
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                    padding: '5px 10px',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    backgroundColor: 'transparent',
                  }}
                >
                  <img
                    src="https://flagcdn.com/w40/us.png"
                    alt="United States flag"
                    width="20"
                    height="15"
                  />
                  English
                </button>
                <button
                  onClick={() => {
                    setLang('es')
                    popupState.close()
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                    padding: '5px 10px',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    backgroundColor: 'transparent',
                  }}
                >
                  <img
                    src="https://flagcdn.com/w40/es.png"
                    alt="Spain flag"
                    width="20"
                    height="15"
                  />
                  Spanish
                </button>
              </div>
            </Popover>
          </div>
        )}
      </PopupState>
    </Grid>
  )
}

export default LanguageSwitcher
