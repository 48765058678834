import { ReactNode, FC, useState } from 'react'
import { Box, Button, Modal, Backdrop, Fade, Typography, useMediaQuery, useTheme, Tooltip } from '@mui/material'
import { DialogCloseButton } from 'components'
import { useHistory } from 'react-router-dom'

type FloatingButtonProps = {
  title?: string
  icon: ReactNode
  tooltip: string
  openModal: boolean
  content?: ReactNode
  redirectPath?: string
}

const FloatingButton: FC<FloatingButtonProps> = ({ title, icon, tooltip, openModal, content, redirectPath }) => {
  const theme: any = useTheme()
  const history = useHistory()
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    if (redirectPath) {
      history.push(redirectPath)
    } else if (openModal) {
      setOpen(true)
    }
  }

  const handleClose = () => setOpen(false)

  const isMiddleScreen = useMediaQuery(theme.breakpoints.up('sm'))

  if (isMiddleScreen && open) {
    setOpen(false)
  }

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          bottom: 76,
          right: 16,
          zIndex: 1000,
        }}
      >
        <Tooltip title={tooltip}>
          <Button
            onClick={handleOpen}
            sx={{
              background: theme =>
                `linear-gradient(90deg, ${theme.palette.warning.main} 0%, ${theme.palette.secondary.dark} 100%)`,
              color: 'white',
              width: 56,
              height: 56,
              borderRadius: '50%',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              minWidth: 0,
              padding: 0,
              '&:hover': {
                backgroundColor: 'primary.light',
                boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.15)',
              },
            }}
          >
            {icon}
          </Button>
        </Tooltip>
      </Box>

      {openModal && (
        <Modal
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
            sx: { backdropFilter: 'blur(5px)', backgroundColor: 'rgba(0, 0, 0, 0.5)' },
          }}
        >
          <Fade in={open}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                borderRadius: 4,
                p: 4,
                width: '90%',
                maxWidth: 500,
                maxHeight: '90%',
                overflowY: 'scroll',
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': { display: 'none' },
              }}
            >
              <DialogCloseButton handleClose={handleClose} />

              <Typography variant='h5' color='text.primary' paragraph>
                {title}
              </Typography>

              <Box sx={{ mt: 3 }}>{content}</Box>
            </Box>
          </Fade>
        </Modal>
      )}
    </>
  )
}

export default FloatingButton
