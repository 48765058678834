import { useState, useEffect } from 'react'

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Tab,
  Tabs,
  Tooltip,
} from '@mui/material'
import { get, uniqBy } from 'lodash'
import { t } from 'i18n'
import pluralize from 'pluralize'
import { useIntl } from 'react-intl'

import {
  ManageEmployeeDemographics,
  ManageEmployeeInfo,
  ManageEmployeeRolesAndSupervisors,
  ManageEmployeeSelectEmployees,
} from '../../components'
import { DialogTeleport } from 'components'
import { DemographicCategory } from 'enums/demographic'
import { a11yTabProps } from 'utils/accessibility'
import { getUserFullName } from 'utils/user'

enum ManageEmployeeTab {
  Info = 'info',
  Demographics = 'demographic',
  RolesAndSupervisors = 'rolesAndSupervisors',
}

export default function ManageEmployeeForm(props) {
  const {
    title,
    formik,
    mutation,
    isEditMode,
    bulkEditMode,
    bulkUsers,
    setBulkUsers,
    selectedSuperiors,
    setSelectedSuperiors,
    selectedRoles,
    setSelectedRoles,
    detailsRequestDone,
    demographics,
  } = props

  const intl = useIntl()

  const [selectedTab, setSelectedTab] = useState(ManageEmployeeTab.Info)
  const [dialogSelectEmployees, setDialogSelectEmployees] = useState(false)
  const [demographicsCompleted, setDemographicsCompleted] = useState(false)

  const tabs = [
    {
      value: ManageEmployeeTab.Info,
      label: t('employees.manageEmployeeFormTabs.info'),
      disabled: false,
      Component: ManageEmployeeInfo,
    },
    {
      value: ManageEmployeeTab.Demographics,
      label: t('employees.manageEmployeeFormTabs.demographics'),
      disabled: !bulkEditMode && (!formik.values.email || formik.errors.email),
      Component: ManageEmployeeDemographics,
    },
    {
      value: ManageEmployeeTab.RolesAndSupervisors,
      label: t('employees.manageEmployeeFormTabs.rolesAndSupervisors'),
      disabled: !bulkEditMode && (!formik.values.email || formik.errors.email),
      Component: ManageEmployeeRolesAndSupervisors,
    },
  ]

  const lastTab = tabs[tabs.length - 1].value

  useEffect(() => {
    const superiorsAttributes = get(
      formik,
      'values.superior_direct_reports_attributes',
      [],
    )

    if (superiorsAttributes.length > 0 && selectedSuperiors.length === 0) {
      setSelectedSuperiors(
        superiorsAttributes.map(superior => ({
          id: superior.id,
          superior_id: get(superior, 'superior.id'),
          full_name: getUserFullName(get(superior, 'superior', '')),
          title: get(superior, 'superior.title'),
          department: get(superior, 'superior.department'),
        })),
      )
    }

    const rolesAttributes = get(formik, 'values.role_users_attributes', [])

    if (rolesAttributes.length > 0 && selectedRoles.length === 0) {
      setSelectedRoles(
        rolesAttributes.map(role => ({
          id: role.id,
          role_id: get(role, 'role_id'),
          name: get(role, 'role.name'),
        })),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values])

  useEffect(() => {
    if (!isEditMode) setDemographicsCompleted(true);

    if (detailsRequestDone && demographics?.length > 0) {
      formik.setFieldValue('high_value_talent', false);

      const filteredDemographics = demographics.filter(
        demographic => demographic.category !== 'country',
      );

      for (const userDemographic of formik.values.demographics) {
        const companyDemographic = filteredDemographics.find(
          d => d.id === userDemographic.category,
        );

        const demographicValue =
          companyDemographic?.category !== DemographicCategory.HighValueTalent
            ? userDemographic.demographic
            : companyDemographic?.demographics_attributes.find(
              attribute => attribute.id === userDemographic.demographic,
            )?.flag;

        if (companyDemographic?.category) {
          formik.setFieldValue(companyDemographic?.category, demographicValue)
        }
      }

      setDemographicsCompleted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demographics]);


  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  const handleBulkSelect = users => {
    setBulkUsers(uniqBy([...bulkUsers, ...users], 'id'))
    setDialogSelectEmployees(false)
  }

  if (!isEditMode || (isEditMode && demographicsCompleted)) {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                aria-label='basic tabs example'
              >
                {tabs.map((tab, index) => (
                  <Tab
                    key={tab.value}
                    data-cy='tabs'
                    value={tab.value}
                    label={tab.label}
                    disabled={tab.disabled}
                    {...a11yTabProps(index)}
                  />
                ))}
              </Tabs>
            </Box>
            {tabs.map((tab, index) => (
              <TabPanel
                key={tab.value}
                value={tab.value}
                selectedTab={selectedTab}
                index={index}
              >
                <tab.Component
                  {...props}
                  setDialogSelectEmployees={setDialogSelectEmployees}
                />
              </TabPanel>
            ))}
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent='flex-end'>
              {isEditMode || selectedTab === lastTab ? (
                <Button
                  type='submit'
                  color='primary'
                  variant='contained'
                  size='large'
                  disabled={mutation.isLoading}
                  data-cy='submitButton'
                >
                  {`${isEditMode
                    ? intl.formatMessage({
                      id: 'formHandler.update',
                    })
                    : intl.formatMessage({
                      id: 'formHandler.create',
                    })
                    } ${pluralize.singular(title)}`}
                  {mutation.isLoading && (
                    <CircularProgress sx={{ ml: 1 }} size={24} />
                  )}
                </Button>
              ) : (
                <Tooltip title={formik.errors.email ?? ''}>
                  <span>
                    <Button
                      color='primary'
                      variant='contained'
                      size='large'
                      data-cy='nextButton'
                      disabled={
                        !bulkEditMode &&
                        (formik.errors.email || !formik.values.email)
                      }
                      onClick={() =>
                        setSelectedTab(
                          tabs[
                            tabs.findIndex(tab => tab.value === selectedTab) + 1
                          ].value,
                        )
                      }
                    >
                      Next
                    </Button>
                  </span>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        </Grid>

        <DialogTeleport
          dialogTitle={t('employees.selectEmployees')}
          dialogAction={null}
          dialogSize='lg'
          dialogOpen={dialogSelectEmployees}
          handleDialogClose={() => setDialogSelectEmployees(false)}
          isFullWidth
        >
          <ManageEmployeeSelectEmployees onSelect={handleBulkSelect} />
        </DialogTeleport>
      </>
    )
  }

  return null
}

function TabPanel(props) {
  const { children, value, index, selectedTab, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== selectedTab}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === selectedTab && (
        <Box width={1} py={3}>
          {children}
        </Box>
      )}
    </div>
  )
}
