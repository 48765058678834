export default {
  components: {
    surveyReward: {
      title: 'You received a {rewardAmount} Gift Card!',
      description:
        "As a thank you for participating in your organization's BeHeard Survey, {companyName} is providing you a gift card in the amount of {rewardAmount}. Please click a brand below to select your gift card. Thanks again for making your voice heard!",
      youSelected: 'You selected: {giftCardName}',
      chooseAnother: 'Choose Another',
      confirmThisGiftCard: 'Confirm this Gift Card',
    },
    demographicsFilters: {
      noResults: 'No results matched your selection.',
      lessThanFiveRespondents:
        'To protect employee privacy, we do not display results with less than 5 respondents.',
      modifySelections: 'Please modify your selections and try again.',
      snackbar: { success: { resetFilters: 'Your filters have been reset.' } },
      buttons: { resetFilters: 'Reset Filters', applyFilters: 'Apply Filters' },
    },
    survey: {
      engagementReportComparisonComponent: {
        info: 'Find specific information for each metric when you hover over this icon.',
      },
    },
    mediaDisplay: {
      snackbar: { warning: 'Unable to locate media. Please try again.' },
    },
    receivedReward: {
      snackbar: {
        success: {
          acceptedReward: 'You accepted this reward!',
          bankedReward: 'You banked this reward!',
          redeemedReward: 'You redeemed this reward!',
        },
      },
      getStatusLabels: {
        received: 'received a',
        accepted: 'accepted this',
        banked: 'banked this',
        redeemed: 'redeemed this',
      },
      getStatusLabelsTooltip: {
        received:
          'You just received this reward! You can accept it or bank it. Banked rewards will add up to your wallet balance',
        accepted: 'You accepted this reward. Make sure you redeemed it',
        banked:
          'You banked this reward. This reward amount was already added to your wallet balance',
        redeemed:
          'You redeemed this reward! You should have received an e-mail with details about it',
      },
      shareWhyYouBankedIt: 'Share with us why you banked it',
      rewardWasAutomaticallyBanked: 'This reward was automatically banked',
      confirmRewardBankedDialog: {
        title: 'You Got it!',
        text: '{points} will be added to your Reward Bank.',
        'actionLabel': 'Confirm'
      },
      rewardBankedDialog: {
        text1: "One moment while we process your gift receipt.",
        text2: "{points} points are being added to your Reward Bank - yay!"
      },
      rewardStatus: 'You {status} Reward!',
      actionButtonLabels: { bank: 'Bank Points', accept: 'I want this gift!' },
      bankedReason: 'Banked Reason: {reason}',
      youRatedThisReward: 'You rated this reward:',
      rateThisReward: 'Rate this reward:',
      notAcceptedReasonDialogTitle: 'Why I banked {rewardName}',
      bankedReasonLabel: 'Banked Reason',
      bankedReasonPlaceholder: 'I decided to bank this reward because...',
    },
    purchaseRetailerRewardDialog: {
      title: 'Select Retailer Reward',
      retailerRewardDetails: 'Retailer Reward Details',
      amountToSpend: 'Amount you want to spend',
      maxAmountMessage: "Amount can't be greater than {amount}",
      searchRewards: 'Search Rewards',
      noRewards:
        'There are no Rewards. Make sure you set an amount in the field above.',
      purchaseFromBrand:
        'Do you want to purchase a {amount} gift card from {brand}?',
      confirmPurchase: 'Confirm Purchase',
      snackbar: {
        giftCardPurchased: 'Your gift card was purchased successfully!',
      },
    },
    reportInappropriateContent: {
      snackbar: {
        success: {
          reportSent:
            'Your report was successfully sent to our moderation team.',
        },
      },
      header: 'Report this',
      question: {
        firstLine: 'What did you find in this',
        secondLine: 'that was inappropriate?',
      },
      contentLabels: {
        text: 'Has Inappropriate Text',
        image: 'Has Inappropriate Image',
        video: 'Has Inappropriate Video',
      },
      report: 'Report',
    },
    adminCompaniesSelect: { textFieldLabel: 'Organization as Admin' },
    adminMode: 'Admin mode',
    dropzone: "Drag 'n' drop some files here, or click to select files",
    errorBoundary: {
      somethingHappened: 'Well, that was unexpected.',
      unexpectedError:
        "We know this isn't what you expected but you just found a bug and are making our system better. Our team has been notified and we should have everything we need to fix this issue quickly. However, if you would like to reach out to our Support team, simply email us at {email}. Otherwise you can click the button below and we will take you to a place that is bug free... hopefully.",
      reloadThisPage: 'Take me to another page',
    },
    loadMoreButton: { loadMore: 'Load more', remaining: 'remaining...' },
    passwordRules: {
      lowercaseLetter: 'a lowercase letter',
      capitalLetter: 'a capital letter',
      number: 'a number',
      atLeastEightCharacters: 'at least 8 characters',
      specialCharacter: 'a special character (+, #, !, ? or @)',
      passwordMustContain: 'The password must contain:',
    },
    profileAutocompleteQuestions: {
      placeholder: 'Please submit one answer at a time...',
      save: 'Save',
      dialogTitle: 'Remove Answer',
      dialogContent: 'Are you sure you want to remove',
      removeAnswer: 'Remove Answer',
      legendLabels: {
        managers: "Manager's answers",
        employees: "Employee's answers",
      },
      listLabel: 'questions',
    },
    recognitionsByEvent: { noData: 'There is no data to display yet.' },
    termsAndConditions: {
      dialogTitle: 'Sparck Terms and Conditions',
      okButton: 'Ok, got it',
    },
    upgradeCostEstimate: {
      contactUs: 'Contact us for a custom quote',
      estimateYourCost: 'Estimate Your Cost to Upgrade',
      numberOfEmployees:
        'How many employees do you have? (Slide the bar until you reach your headcount).',
      employees: 'employees',
      estimatedReportCost: 'Estimated Report Cost',
    },
    reportComparison: {
      features: 'Features',
      freeReport: 'Free\nReport',
      premiumReport: 'Premium\nReport',
      companyEngagementScore: {
        title: 'Organization Engagement Score',
        tooltip:
          'The extent to which employees feel passionate about their jobs, are committed to our organization, put discretionary effort into their work and are willing to refer.  Results can easily be filtered by workplace demographics.',
      },
      participationRate: { title: 'Participation Rate' },
      customQuestions: { title: 'Custom Questions' },
      shortTermTurnover: {
        title: 'Short-Term Turnover',
        tooltip:
          'Based on your results, you can get a pulse on potential turnover in the next 12 months. Results can easily be filtered by workplace demographics.',
      },
      longTermTurnover: {
        title: 'Long-Term Turnover',
        tooltip:
          'Based on your results, you can get a pulse on how committed your employees are to building their career with you long term. Results can easily be filtered by workplace demographics.',
      },
      filters: {
        title: 'Filters',
        tooltip:
          'Use demographic filters to analyze data including Engagement Drivers, Turnover, Action Areas, Strengths, and Most Important Items.',
      },
      collaborationEffectivenessScore: {
        title: 'Collaboration Effectiveness Score',
        tooltip:
          'The extent to which the organization promotes a team-based work style (i.e. departments or groups work well together).',
      },
      jobFitEffectivenessScore: {
        title: 'Job Fit Effectiveness Score',
        tooltip:
          'The extent to which employees feel they are in the right jobs and have the necessary skills, knowledge and abilities to adequately perform in their roles.',
      },
      employeeAppreciationScore: {
        title: 'Employee Appreciation Score',
        tooltip:
          'The extent to which employees feel valued by the organization. Results can be easily filtered by workplace demographics.',
      },
      organizationalAlignmentScore: {
        title: 'Organizational Alignment Score',
        tooltip:
          'The extent to which employees believe in and put effort toward the organization’s mission, values and goals.',
      },
      growthEffectivenessScore: {
        title: 'Growth Effectiveness Score',
        tooltip:
          'The extent to which employees feel supported to advance their career or develop personal skill sets within the organization.  Results can easily be filtered by workplace demographics.',
      },
      topFiveMostImportantAreas: {
        title: 'Top Five Most Important Areas',
        tooltip:
          'These are the engagement areas that ranked most important to your employees. Results can easily be filtered by workplace demographics.',
      },
      topFiveFocusAreasForGreatestRoi: {
        title: 'Top Five Focus Areas for Greatest ROI',
        tooltip:
          'These are the areas that you should focus on to get the greatest return on your investment to increase engagement. These are the engagement areas that are most important to your employees where they feel the organization is least effective. Results can easily be filtered by workplace demographics.',
      },
      topFiveCompanyStrengths: {
        title: 'Top Five Organization Strengths',
        tooltip:
          'These are the areas where you are strongest, great data to build your employer brand.  These are the engagement areas that were most important to your employees and where they also felt you were most effective. Results can easily be filtered by workplace demographics.',
      },
      actionableRecommendationsOrganizationAndManagers: {
        title: 'Actionable Recommendations (Organization and Managers)',
        tooltip:
          'Based on your top five focus areas to get the greatest engagement ROI, a team of I/O psychologists took proven strategies to personalize each organizational and management recommendation. Results can easily be filtered by workplace demographics.',
      },
    },
    employeesUploadComponent: {
      warningMessage:
        'We were able to import only {importedEmployees} employees. Please correct the errors you see below and try again.',
      errorContainerMessage:
        'To see what is required or the correct format for any field, check our template in the link above.',
      errorTable: {
        columns: {
          row: 'Row',
          missingValues: 'Missing Values',
          invalidFormat: 'Invalid Format',
        },
      },
      employeeUploadFields: {
        companyInternalId: 'Employee ID',
        cellNumber: 'Cell Number',
        title: 'Title',
        hiredAt: 'Hire Date',
        gender: 'Gender',
        ethnicity: 'Ethnicity',
        dateOfBirth: 'DOB',
        jobType: 'FT/PT',
        businessUnit: 'Business Unit',
        region: 'Region',
        territory: 'Territory',
        locationName: 'Location Name',
        city: 'City',
        state: 'State/Province',
        country: 'Country',
        postalCode: 'Postal Code',
        performanceLevel: 'Performance Level',
        highValueTalent: 'High Value Talent',
        roles: 'Roles',
        superiors: 'Supervisors',
        preferredLanguage: 'Language',
      },
    },
    mediaPicker: {
      addMedia: 'Add Image/Video',
      addGif: 'Add GIF',
      addCard: 'Add Card',
      dragAndDrop: 'Drag and drop images and videos or just click here!',
      pasteLink: 'or paste an image/video link:',
      pasteLinkPlaceholder: 'e.g. image, youtube, vimeo, etc',
      verify: 'Verify',
      sparckCardsDialog: {
        title: 'Sparck Cards',
        noCards: 'There are no Cards available.',
      },
    },
    demographicsPicker: {
      add: 'Add',
      additional: 'Another',
      demographic: 'Demographic',
    },
    popupSteps: {
      gotIt: 'Got it!',
      next: 'Next',
      dontShowAgain: "Don't show again",
      back: 'Back',
    },
    phoneNumberInputWithFormatPicker: {
      chooseCountry: 'Choose a country',
    },
    confirmationDialog: {
      delete: 'archive',
      defaultMessage: 'Are you sure you want to {action} {item}?',
      launchBeheardSurveyMessage:
        'You are about to launch your survey and send each employee an email to take the survey. Please make sure you have added all participant employees before launching your survey by clicking {link}. If you are ready to launch click the Launch Survey button below.',
      launchPulseSurveyMessage:
        'You are about to launch your survey and send each employee an email to take the survey. If you are ready to launch, click the Launch Pulse Survey button below.',
    },
  },
}
