export const API_ROUTES = {
  acceptReward: 'rewards/rewards/:id/accept',
  acceptSuggestion: 'suggestion_box_ideas/:id/accept',
  acceptSurveyRewardIntention: 'survey_reward_intentions/:id/accept',
  addRewardAmountToWallet: 'rewards/rewards/:id/add_to_wallet',
  // addMoneyToWallet: 'redeemes/add_money',
  // addFloatValue: 'shopping_carts/float12345',
  addMoneyToWallet: 'company_transactions/add_money',
  addFloatValue: 'company_transactions/float_data_limit',
  allowReportedContent: 'profanity_filter/reported_records/:id/allow_content',
  analyticsReports: 'analytics/reports/:id',
  answerPublicSparckStyleAssessment: 'public/assessment_surveys/answer',
  answerPulseSurvey: 'pulse_surveys/surveys/:id/answer',
  answers: 'answers',
  answerSparck: 'recognitions/recognitions/:id/answer',
  assignToSuggestion: 'suggestion_box_ideas/:id/assign',
  approvals: 'approvals/approval_requests',
  approveApprovalRequest: 'approvals/approval_requests/:id/approve',
  badgesCount: 'badges/count',
  botSettings: 'bot/organization',
  budgetBreakdown: 'budgets/breakdown',
  budgetPerDemographics: 'budget_per_demographics',
  cards: 'recognition/cards',
  changePassword: 'users/:id/change_password',
  changePasswordByAdmin: 'users/:id/change_password_by_admin',
  checkCredentials: 'user/validate_password',
  closePulseSurvey: 'pulse_surveys/surveys/:id/close',
  closeSurvey: 'surveys/:id/close',
  comments: 'feed/comments/:id',
  companies: 'companies/:id',
  companyEmployeesCount: 'companies/:id/count_employees',
  companyInsights: 'analytics/insights/company_insights',
  companyReporting: 'companies/report',
  configureMSTeams: 'bot/organization/configure_msteams',
  configureSlack: 'bot/organization/configure_slack',
  configureSlackChannel: 'bot/organization/define_slack_channel',
  coreValues: 'core_values/:id',
  createMFAFactor: 'mfa/create_factor',
  createSocialShare: 'recognition/external_shares',
  declineApprovalRequest: 'approvals/approval_requests/:id/decline',
  declineSuggestion: 'suggestion_box_ideas/:id/decline',
  demographics: 'demographics/categories/:id',
  departmentInsights: 'analytics/insights/department_insights',
  depriveReportedContent:
    'profanity_filter/reported_records/:id/deprive_content',
  directReports: 'direct_reports/:id',
  disregardRecognitionAction: 'recognition/actions/:id/disregard',
  downloadBudgetActivity: 'budgets/reports/activity',
  downloadExternalRewardsTemplate: 'rewards/rewards/export_upload_template',
  downloadOpenEndedAnswers: 'exports/surveys/open_ended_answers/open_ended_answers_filter',
  downloadRewardsReport: 'rewards/reports/rewards',
  downloadRecognitionReport: 'rewards/reports/recognition_event',
  downloadEmployeeReport: 'rewards/reports/by_employee',
  downloadExecutiveReport: 'rewards/reports/by_executive',
  downloadManagerReport: 'rewards/reports/by_manager',
  addCart: 'shopping_carts/add_card_details',
  gettrasaction: 'rewards/purchases/company_purchase_rewards/',
  // getcurrenttrasaction: 'redeemes/current_balance',
  getcurrenttrasaction: 'company_transactions/current_balance',
  employees: 'users/:id',
  employeesArchiveWithoutMoney: 'users/:id/archive_without_money',
  employeesAutocomplete: 'users/autocomplete',
  sendBulkEmployees: 'users/welcome_email_data',
  sendBulkEmailEmployees: 'users/send_welcome_email_multiple_user',
  getEmployeeCount: 'users/users_count',
  employeesBulk: 'users/bulk_update',
  employeeRecognitionCertification: 'user_certification',
  employeeRecognitionCertificationBoards: 'user_certification/users_boards',
  employeesSearch: 'users/search',
  emptyWishlist: 'rewards/wishlists/:id/empty',
  extendPulseSurvey: 'pulse_surveys/surveys/:id/extend',
  extendSurvey: 'surveys/:id/extend',
  favorite: 'favorites',
  finishMFAAuthentication: 'mfa/finish_authentication',
  finishOnboarding: 'recognition/onboarding/questions/finish',
  finishPublicSparckStyleAssessment: 'public/assessment_surveys/finish',
  finishPulseSurvey: 'pulse_surveys/surveys/:id/finish',
  finishSurvey: 'surveys/finish',
  getBudgetSettings: 'budgets/settings/show',
  getMFABackupCodes: 'mfa/fetch_backup_codes',
  importEmployees: 'users/import',
  importExternalRewards: 'rewards/rewards/spreadsheet_upload',
  importHRIS: 'companies/:id/import',
  insights: 'companies/insights',
  inviteEmployeesToSurvey: 'participations/:id/invite',
  inviteShareReport: 'shared_reports/shared_reports/:id/share',
  invoices: 'invoices',
  likes: 'feed/likes/:id',
  login: 'tokens',
  logout: 'tokens/:token_id',
  markAllNotificationsAsRead: 'notifications/notifications/read_all',
  markNotificationAsRead: 'notifications/notifications/:id/read',
  mfaSetPrimaryFactor: 'mfa/set_primary_factor',
  userInsights: 'analytics/insights/user_insights',
  myRewards: 'rewards/rewards/:id',
  myRewardsPurchases: 'rewards/purchases',
  myRewardStorePurchases: 'rewards/grs_purchases',
  NGCAccounts: 'rewards/ngc/accounts',
  NGCFunds: 'rewards/ngc/funds',
  nominations: 'nominations/nominations',
  nominationsAwards: 'nominations/awards',
  nominationsEvents: 'nominations/events',
  nominationsEntries: 'nominations/entries',
  nominationsOrganizations: 'nominations/organizations/:id',
  nominationsPrograms: 'nominations/programs/:id',
  notifications: 'notifications/notifications/:id',
  notificationsCount: 'notifications/notifications/count_unread',
  onboardingQuestions: 'recognition/onboarding/questions/:id',
  openPulseSurvey: 'pulse_surveys/surveys/:id/open',
  openSurvey: 'surveys/:id/open',
  organizationalInsights: 'analytics/insights/company_insights',
  passwordRecovery: 'users/password_recovery',
  // paymentsDataApi: 'redeemes/:id',
  paymentsDataApi: 'company_transactions/:id',
  pdfPremiumReport: 'exports/surveys/premium_report',
  permissions: 'permissions/:id',
  possibleEventsFromUsers:
    'recognitions/events/list_possible_events_from_users',
  posts: 'feed/posts/:id',
  profileQuestions: 'recognition/profile_questions/:id',
  profileQuestionsLibrary: 'recognition/profile_library_questions/:id',
  profileQuestionsPickFromLibrary: 'recognition/profile_questions/pick',
  publicSparck: 'public/recognitions/:id',
  pulseLibraryQuestions: 'pulse_surveys/library_questions',
  pulseSurveyOverview: 'pulse_surveys/surveys/:id/insights',
  pulseSurveys: 'pulse_surveys/surveys/:id',
  pulseSurveyPreview: 'pulse_surveys/surveys/preview',
  recognitionActions: 'recognition/actions/:id',
  recognitions: 'recognitions/events/:id',
  redeemReward: 'rewards/rewards/:id/redeem',
  redeemes: 'rewards/purchases/company_purchase_rewards/:id',
  refreshToken: 'tokens/refresh',
  reminders: 'reminders',
  removeMFAFactor: 'mfa/remove_factor',
  removeMSTeamsIntegration: 'bot/organization/remove_msteams_configuration',
  removeReportedContent: 'profanity_filter/reported_records/:id/remove_content',
  removeSlackIntegration: 'bot/organization/remove_slack_configuration',
  replyToSuggestion: 'suggestion_box_ideas/:id/add_reply',
  reportedRecords: 'profanity_filter/reported_records',
  resendInvitation: 'users/resend_invitation_email',
  resendPulseSurveyInvitation: 'users/send_pulse_surveys_invitations',
  resetMFABackupCodes: 'mfa/reset_backup_codes',
  rewards: 'rewards/products/:id',
  rewardsNGC: 'rewards/ngc/products',
  // surveyRewardIntentions: 'survey_reward_intentions/coltranes_api',
  surveyRewardIntentions: 'survey_reward_intentions/carltons_api',
  rewardsVirginExperiences: 'virgin_experience/catalog/products',
  roles: 'roles/:id',
  sendBetaTeamInviteEmail: 'users/:id/invite',
  sendRecoveryInstructions: 'users/send_recovery_instructions',
  sendWelcomeEmail: 'companies/:id/send_welcome_email',
  sendWelcomeToEmployee: 'users/:id/send_welcome_notification',
  sendWelcomeNotificationToEmployees:
    'companies/:id/send_welcome_notification_to_employees',
  shareReport: 'shared_reports/shared_reports/:id',
  shoppingCart: 'shopping_carts',
  shoppingCartAddItem: 'shopping_carts/add_item',
  shoppingCartCheckout: 'shopping_carts/checkout',
  shoppingCartRemoveItem: 'shopping_carts/remove_item',
  sparckMemories: 'recognitions/recognitions/memories',
  sparcks: 'recognitions/recognitions/:id',
  sparcksSent: 'recognitions/recognitions/sent',
  spoofEmployee: 'users/:id/spoof',
  startMFAAuthentication: 'mfa/start_authentication',
  sparckMessageReuseCheck: 'recognitions/similar_recognition_messages',
  suggestions: 'suggestions',
  suggestionsBoxIdeas: 'suggestion_box_ideas/:id',
  suggestionNotes: 'suggestion_notes',
  surveyOverview: 'surveys/:id/overview',
  surveyParticipation: 'surveys/participations',
  surveyReport: 'surveys/:id/:report_type',
  surveyRewardsIntentions: 'survey_reward_intentions',
  surveys: 'surveys/:id',
  surveyPreview: 'surveys/preview',
  surveysToggleReportLevel: 'surveys/:id/toggle_report_level',
  surveyUninvitedUsers: 'participations/:id/uninvited',
  takePublicSparckStyleAssessment: 'public/assessment_surveys/take',
  takeRecognitionAction: 'recognition/actions/:id/taken',
  takeSurvey: 'surveys/take',
  tasks: 'tasks/tasks',
  teamInsights: 'analytics/insights/team_insights',
  rollUpTeamInsights: 'analytics/insights/full_team_insights',
  topFiveWishlistedRewards: 'rewards/wishes/most_desired_products',
  unassignToSuggestion: 'suggestion_box_ideas/:id/unassign',
  unfavorite: 'favorites/:id',
  updateBudgetSettings: 'budgets/settings/update',
  updateBudgetPamentStatus: 'shopping_carts/approve_payment_status',
  updateManagerBudgetLimit: 'budgets/settings/update_manager_budget_limit',
  updateWishlist: 'rewards/wishlists/:id/update',
  uploads: 'uploads',
  users: 'users/:id',
  usersAdvancedSearch: 'budgets/users/search',
  wishes: 'rewards/wishes/:id',
  wishlists: 'rewards/wishlists/:id',
  verifyMFAFactor: 'mfa/verify_factor',
  virginExperiencesOrganizational: 'virgin_experience/organization',
  virginExperiencesOrganizationalBalanceAlerts:
    'virgin_experience/organization/define_remaining_balance_alerts',
  virginExperiencesOrganizationalAddFunds:
    'virgin_experience/organization/add_fund',
  virginExperiencesSettings: 'virgin_experience/config/all',
  virginExperiencesSettingsCreditAlerts:
    'virgin_experience/config/remaining_credit_alerts',
  virginExperiencesSettingsCreditLimit: 'virgin_experience/config/credit_limit',
  virginExperiencesSettingsCreditPayments:
    'virgin_experience/credit_payments/:id',
  virginExperiencesSettingsPendingOrders:
    'virgin_experience/orders/for_credit_payment',
  addMoneyWithCart: 'shopping_carts/add_money_with_card',
  // budget_distribution: 'rewards/rewards/banked_rewards',
  budget_distribution: 'rewards/rewards/bank_wallet',
  get_distributions_amount_transactions: 'rewards/rewards/budget_distribution',
  addMoneyACH: 'shopping_carts/add_money_ach',
  getBankAccountDetails: 'shopping_carts/bank_account_details',
  removeBankAccount: 'shopping_carts/remove_bank_account',
  rewardsHistory: 'rewards/purchases/recognition_event_reward ',
  rewards_carlton_catalogs:
    'v1/survey_reward_intentions/carlton_catalogs',
  ssoIdToken: 'sso/id_token',
  ssoRewardCloudToken: 'grs/sso_session',
  addFundInBank: 'shopping_carts/add_fund_in_add_bank',
}
