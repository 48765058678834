import { get, isEmpty } from 'lodash'
import { Box, CircularProgress, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Link } from 'react-router-dom'
import {
  TeamInsightsMobile,
  TeamInsightsRewardBudgetProgress,
  TeamInsightsSparckProgressBar,
  TeamInsightsSparckTable,
} from '..'
import {
  EngagementDriverScoresComponent,
  EngagementScoreComponent,
  MinimumParticipantsAlert,
  RecognitionsByEvent,
  TopFiveAreas,
  PulseSurveyStatistics,
} from 'components'
// import { getEnvironmentName } from 'core/environments'
import { useUser } from 'contexts'
import { ROUTES_PATHS } from 'core/routes'
import { ActivityType } from 'enums/recognitionsByEvent'
import { UserRole } from 'enums/user'
import { t } from 'i18n'
import { InsightsFilters } from 'pages/insights/companyInsights/components'
import { InsightsCard } from 'pages/insights/components'
import { PulseSurveyType, SurveyType } from 'types'
import { BeheardReportRecommendations } from 'pages/beheardReport/components'
import { PERIODS } from 'constants/period'
export interface TeamInsightsProps {
  insights: any
  companyInsightsData?: any
  departmentInsightsData?: any
  insightsMeta: any
  insightsIsLoading: boolean
  teamInsightsLoading: boolean
  filters: {
    period: string
    survey: any
    pulse_survey: any
    manager: any
  }
  setFilters: any
  surveys: SurveyType[]
  pulseSurveys: PulseSurveyType[]
  company?: any
  insightsAll?: any
}

const METRICS_SPACING = 3
const MINIMUM_PARTICIPANTS_REQUIRED = 5

export default function TeamInsightsComponent(props: TeamInsightsProps) {
  const {
    insights,
    companyInsightsData,
    departmentInsightsData,
    insightsMeta,
    insightsIsLoading,
    teamInsightsLoading,
    company,
    filters,
    setFilters,
  } = props
  const { user } = useUser()!

  // const environment = getEnvironmentName()
  // const companyId = (environment === "production") ? 1025 : 928;

  const recognitionsByEvent = get(insights, 'recognitions.by_events')
  const surveyDetails = get(insights, 'last_premium_report')
  const surveyCompanyDetails = get(companyInsightsData, 'last_premium_report')
  const surveyDepartmentDetails = get(departmentInsightsData, 'last_premium_report')

  const surveyCompanyDetailsTemp = get(companyInsightsData, 'scores')
  const surveyDepartmentDetailsTemp = get(departmentInsightsData, 'scores')
  const surveyTeamDetailsTemp = get(insights, 'scores')

  const pulseSurveyDetails = get(insights, 'pulse_survey_insights.survey')

  // just for debuggin boomi. in Heroku server. TODO - need to remove this. written on 13/07/2023 by MJ
  company?.id === 694 ? company.display_managers_teams_beheard_results = true : "";

  const surveyHasMinimumParticipantsRequired = surveyDetails?.survey?.current_participation >= MINIMUM_PARTICIPANTS_REQUIRED
  const pulseSurveyHasMinimumParticipantsRequired = pulseSurveyDetails?.participation?.count >= MINIMUM_PARTICIPANTS_REQUIRED
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  // const surveyId = surveyDetails?.survey?.id
  const dontShowVisual = get(surveyDetails, "previous_survey_percents") ? true : false;
  const renderLoading = () => (
    <Box my={5} width={1}>
      <Grid container justifyContent='center'>
        <CircularProgress size={64} />
      </Grid>
    </Box>
  )

  let selectedPeriod: any;
  PERIODS.map(period => {
    if (period.id === filters.period) {
      selectedPeriod = period.label
    }
  })

  return (
    <>
      {dontShowVisual ? (<Box my={METRICS_SPACING} width={1}>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Typography variant='h6' color='text.secondary' align='left'>
            {t('insights.team.title')}
          </Typography>

          <Link to={ROUTES_PATHS.communityInsights}>
            {t('insights.team.link')}
          </Link>
        </Grid>
      </Box>) : <Box my={METRICS_SPACING} width={1}>&nbsp;</Box>}

      <Grid container>
        <Grid item xs={12}>
          <InsightsFilters {...props} />
        </Grid>
      </Grid>

      {(company?.id != 694) && (<Box width={1} mt={3}>
        <Grid container spacing={METRICS_SPACING}>
          <Grid item xs={12} md={(company?.enable_rewards_store === true) ? 4 : 6}>
            <InsightsCard
              title={t('insights.team.recognitionByProgram')}
              boxProps={{
                flex: 1,
                height: 1,
              }}
            >
              {!teamInsightsLoading ? (
                <RecognitionsByEvent
                  {...props}
                  recognitionsByEvent={recognitionsByEvent}
                  onActivityTypeChange={(activityType, isEmployee) =>
                    setFilters({
                      ...filters,
                      activity_of: isEmployee
                        ? ActivityType.Custom
                        : activityType,
                      employees_ids: isEmployee ? [activityType] : [],
                    })
                  }
                  teamMembers={insightsMeta?.my_team_members}
                  showViewModesFilter
                  showActivityTypesFilter
                />
              ) : (
                renderLoading()
              )}
            </InsightsCard>
          </Grid>

          {
            (company?.enable_rewards_store === true) && (
              <Grid item xs={12} md={4}>
                <InsightsCard
                  title={t('insights.team.rewardBudget', { period: t(selectedPeriod.props.id) })}
                  boxProps={{
                    flex: 1,
                    height: 1,
                  }}
                >
                  {!teamInsightsLoading ? (
                    <TeamInsightsRewardBudgetProgress {...props} />
                  ) : (
                    renderLoading()
                  )}
                </InsightsCard>
              </Grid>
            )}

          <Grid item xs={12} md={(company?.enable_rewards_store === true) ? 4 : 6}>
            <InsightsCard
              title={t('insights.team.recognition', { period: t(selectedPeriod.props.id) })}
              boxProps={{
                flex: 1,
                height: 1,
              }}
            >
              {!teamInsightsLoading ? (
                <TeamInsightsSparckProgressBar {...props} />
              ) : (
                renderLoading()
              )}
            </InsightsCard>
          </Grid>
          <Grid item xs={12}>
            <InsightsCard>
              {isMobile ? (
                <TeamInsightsMobile {...props} />
              ) : (
                <TeamInsightsSparckTable {...props} />
              )}
            </InsightsCard>
          </Grid>
        </Grid>
      </Box>
      )}

      {!insightsIsLoading && !isEmpty(insights) && (filters.survey || filters.pulse_survey) && filters.manager ? (
        <Box width={1} mt={3}>
          <Grid container spacing={METRICS_SPACING}>

            {
              /* Beheard Survey Reports Starts */

              (filters.survey && surveyHasMinimumParticipantsRequired) ? (
                [
                  <Grid item xs={12} key={1}>
                    {get(company, 'display_managers_teams_beheard_results') && (
                      <InsightsCard >
                        {(insightsIsLoading || teamInsightsLoading) ? (
                          renderLoading()
                        ) : (
                          <EngagementScoreComponent isTeamInsight={true} insightsAll={props.insightsAll} surveyCompanyDetails={surveyCompanyDetails}
                            surveyDetails={surveyDetails} surveyDepartmentDetails={surveyDepartmentDetails} surveyCompanyDetailsTemp={surveyCompanyDetailsTemp}
                            surveyDepartmentDetailsTemp={surveyDepartmentDetailsTemp} surveyTeamDetailsTemp={surveyTeamDetailsTemp}
                          />
                        )}
                      </InsightsCard>
                    )}

                    {get(company, 'display_managers_teams_beheard_results') && (
                      <InsightsCard
                        boxProps={{ flex: 1 }}
                      >
                        {insightsIsLoading ? (
                          renderLoading()
                        ) : (
                          <EngagementDriverScoresComponent isTeamInsight={true}
                            surveyDetails={surveyDetails} insightsAll={props.insightsAll} surveyCompanyDetails={surveyCompanyDetailsTemp} surveyDepartmentDetails={surveyDepartmentDetailsTemp} surveyTeamDetails={surveyTeamDetailsTemp}
                          />
                        )}
                      </InsightsCard>
                    )}

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Grid container direction='column' style={{ height: '100%' }}>
                        {get(company, 'display_managers_teams_beheard_results') && (
                          <InsightsCard
                            title={t('insights.team.topFiveAreas')}
                            boxProps={{ flex: 1 }}
                          >
                            {insightsIsLoading ? (
                              renderLoading()
                            ) : (
                              <TopFiveAreas
                                surveyDetails={{
                                  ...surveyDetails,
                                  sections_allowed: {
                                    top_5_action_areas: true,
                                    top_5_engagement_strengths: true,
                                    top_5_importance: true,
                                  },
                                }}
                                isFullReport={true}
                                addSurveyToCart={() => { }}
                                addSurveyToCartIsLoading={false}
                                hideImportanceSection
                              />
                            )}
                          </InsightsCard>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>,
                  <Grid item xs={12} key={2}>
                    <InsightsCard title={t('beheardReport.sectionTitles.topFiveRecommendations')}>
                      {!insightsIsLoading ? (
                        <BeheardReportRecommendations Description={'BeheardReportRecommendations'} surveyDetails={surveyDetails} isFullReport={true} />
                      ) : (
                        renderLoading()
                      )}
                    </InsightsCard>
                  </Grid>
                ]
              ) : (
                filters.survey && !surveyHasMinimumParticipantsRequired ? (
                  <Grid item xs={12}>
                    <MinimumParticipantsAlert isInFilter />
                  </Grid>
                ) : ("")
              )

              /* Beheard Survey Reports Ends */
            }

            {
              /* Pulse Survey Reports Starts */

              (filters.pulse_survey && pulseSurveyHasMinimumParticipantsRequired) ? (
                <Grid item xs={12}>
                  {get(company, 'display_managers_teams_pulse_survey_results') && (
                    <InsightsCard title={t('insights.team.pulseSurveyResults')}>
                      {!insightsIsLoading ? (
                        <PulseSurveyStatistics
                          pulseSurvey={pulseSurveyDetails}
                          verticalView
                        />
                      ) : (
                        renderLoading()
                      )}
                    </InsightsCard>
                  )}
                </Grid>
              ) : (
                filters.pulse_survey && !pulseSurveyHasMinimumParticipantsRequired ? (
                  <Grid item xs={12}>
                    <MinimumParticipantsAlert isInFilter />
                  </Grid>
                ) : ("")
              )

              /* Pulse Survey Reports Ends*/
            }

          </Grid>
        </Box>
      ) : insightsIsLoading ? (
        renderLoading()
      ) : (
        <Grid item xs={12}>
          <Box my={5} width={1}>
            <Typography
              variant='h6'
              color='text.secondary'
              align='center'
              paragraph
            >
              No data to display in Survey.
            </Typography>
            {!filters.survey || !filters.pulse_survey && (
              <Typography
                variant='h6'
                color='text.secondary'
                align='center'
                paragraph
              >
                {'Make sure you selected a survey in the filters above.'}
              </Typography>
            )}
            {(!user.roles.includes(UserRole.Manager) && filters.survey || filters.pulse_survey) && (
              <Typography
                variant='h6'
                color='text.secondary'
                align='center'
                paragraph
              >
                {t('insights.managerSelectionError')}
              </Typography>
            )}
          </Box>
        </Grid>
      )
      }
    </>
  )
}