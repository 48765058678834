import { useState } from 'react'

import {
  Box,
  Divider,
  Grid,
  Link,
  Theme,
  Toolbar,
  useMediaQuery,
} from '@mui/material'

import { Copyright, Sidebar, Topbar } from '../components'
import { AdminMode, SpoofMode } from 'components'
import { DRAWER_OPENED_WIDTH, TOP_LINE_TEAL_HEIGHT } from 'constants/app'
import { BadgesCountProvider, useCompany } from 'contexts'
import { LayoutType } from 'types/pageLayout'
import { isCompanySpoofedMode, isUserSpoofedMode } from 'utils/admin'
import MobileNavbar from 'layouts/components/mobile/navbar/MobileNavbar'

export default function BaseLayout({
  id,
  title,
  children,
  layoutProps,
}: LayoutType) {
  const { showTopbar, showSidebar, showMobileNavbar, showCopyright, fullWidth } = layoutProps

  const { company } = useCompany()!

  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false)

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'))
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.only('sm'))
  const isLaptop = useMediaQuery((theme: Theme) => theme.breakpoints.only('md'))
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))

  return (
    <>
      <Link
        href='#main-content'
        sx={{
          zIndex: 1900,
          background: '#FFF',
          padding: '0.5rem 1.5rem',
          borderRadius: '0 0 0.25rem 0.25rem',
          position: 'absolute',
          left: '0.5rem',
          transform: 'translateY(-120%)',
          transition: 'transform 325ms ease-in',
          '&:focus': {
            transform: 'translateY(0)',
          },
        }}
      >
        Skip Navigation
      </Link>

      <Box
        sx={{
          backgroundColor:
            isCompanySpoofedMode(company) || isUserSpoofedMode()
              ? 'error.main'
              : 'primary.main',
          height: TOP_LINE_TEAL_HEIGHT,
          width: '100%',
          position: 'sticky',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1099,
        }}
      />
      <Box sx={{ display: 'flex' }}>
        <BadgesCountProvider>
          {(isMobile || isTablet || showTopbar) && (
            <Topbar
              {...layoutProps}
              routeId={id}
              title={title}
              mobileDrawerOpen={mobileDrawerOpen}
              setMobileDrawerOpen={setMobileDrawerOpen}
              isMobile={isMobile}
              isTablet={isTablet}
              isLaptop={isLaptop}
              isDesktop={isDesktop}
            />
          )}
          {showSidebar && (
            <Sidebar
              {...layoutProps}
              mobileDrawerOpen={mobileDrawerOpen}
              setMobileDrawerOpen={setMobileDrawerOpen}
              isMobile={isMobile}
              isTablet={isTablet}
              isLaptop={isLaptop}
            />
          )}
          {isMobile && showMobileNavbar && (
            <MobileNavbar {...layoutProps} />
          )}
        </BadgesCountProvider>
        <Box
          id='main-content'
          component='main'
          sx={{
            width: showSidebar
              ? `calc(100% - ${DRAWER_OPENED_WIDTH + 40}px)`
              : '100%',
            flexGrow: 1,
            ...(!fullWidth && {
              p: isMobile ? 2 : 3,
            }),
            ...(fullWidth && {
              mb: 5,
            }),
            ...(showSidebar &&
              !isMobile &&
              !isTablet && {
              ml: 5,
            }),
            ...(showMobileNavbar && {
              mb: 10
            }),
          }}
        >
          {(isMobile || isTablet || showTopbar) && <Toolbar />}
          <Grid container justifyContent='center'>
            <Grid
              item
              sx={{
                maxWidth: fullWidth ? '100%' : '1280px',
                width: '100%',
                ...(!fullWidth &&
                  showSidebar && {
                  marginRight: {
                    xxl: `${DRAWER_OPENED_WIDTH + 40}px`,
                  },
                }),
              }}
            >
              {children}
            </Grid>

            {!showSidebar && showCopyright && (
              <Grid item xs={12} sx={{ mt: 5 }}>
                <Divider />

                <Grid container justifyContent='center' sx={{ mt: 5 }}>
                  <Copyright />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
      <AdminMode />
      <SpoofMode />
    </>
  )
}
