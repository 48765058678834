import { useState } from 'react'
import { Button, Grid, Link, useTheme, CircularProgress, useMediaQuery, Theme } from '@mui/material'
import { get } from 'lodash'
import { useIntl } from 'react-intl'
import { API } from 'core/requests'
import { SparckTable } from 'components'
import { getRewardStatusLabel } from 'constants/rewards'
import { RewardIntentionStatus, RewardType } from 'enums/reward'
import { capitalizeFirstLetter } from 'utils/app'
// import { formatToUSD } from 'utils/currency'
import { getDateLabel } from 'utils/tables/dataTypes'

export default function MyRewardsTable(props: any) {
  const {
    myRewards,
    pagination,
    setPagination,
    acceptReward,
    addRewardAmountToWallet,
    // setRedeemRewardDialog,
  } = props

  const intl = useIntl()
  const theme: any = useTheme()

  const [redeemRewardLoading, setRedeemRewardLoading] = useState(false)

  const handleRedeem = async (reward) => {
    if (reward.type === RewardType.Retailer && RewardIntentionStatus.Accepted) {
      const payload = {
        id: reward?.id,
        catalog_id: reward?.retailer_details?.key?.details?.catalog_id || null,
        product_id: reward?.retailer_details?.key?.product_id || null
      }
      setRedeemRewardLoading(true)
      redirectToRewardStore(payload)
    }
  }

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const redirectToRewardStore = async (payload: any) => {
    try {
      await API.get('ssoRewardCloudToken', null, {
        queryParams: {
          reward_id: payload?.id,
          catalog_id: payload?.catalog_id || "",
          product_id: payload?.product_id || ""
        }
      }).then((ssoData) => {
        if (isMobile) {
          window.location.replace(ssoData.url)
        } else {
          window.open(ssoData.url, '_blank')
        }
      })
    } catch (error) {
      console.error('Error fetching reward store URL:', error)
    } finally {
      setRedeemRewardLoading(false)
    }
  }

  return (
    <SparckTable
      slug='myRewards'
      data={myRewards}
      columns={[
        {
          title: intl.formatMessage({
            id: 'myRewards.columns.received',
          }),
          field: 'created_at',
          render: (rowData: any) => getDateLabel(rowData.created_at),
        },
        {
          title: intl.formatMessage({ id: 'type' }),
          field: 'type',
          render: (rowData: any) => capitalizeFirstLetter(rowData.type),
        },
        {
          title: intl.formatMessage({
            id: 'myRewards.columns.details',
          }),
          field: 'description',
          render: (rowData: any) =>
            rowData.type === RewardType.Company
              ? rowData?.product?.name
              : rowData?.retailer_details?.key?.details?.name,
        },
        {
          title: intl.formatMessage({ id: 'myRewards.columns.from' }),
          field: 'user_rewarding.name',
          render: (rowData: any) => rowData.user_rewarding.name,
        },
        {
          title: intl.formatMessage({ id: 'Points' }),
          field: 'value',
          render: (rowData: any) => Math.round(rowData.value),
        },
        {
          title: intl.formatMessage({ id: 'status' }),
          field: 'status',
          render: (rowData: any) => (
            <span
              style={{
                color:
                  rowData.status === RewardIntentionStatus.Waiting
                    ? theme.palette.text.veryLight
                    : theme.palette.primary.main,
                fontWeight:
                  rowData.status === RewardIntentionStatus.Waiting ? 500 : 700,
              }}
            >
              {rowData.status && getRewardStatusLabel[rowData.status]}
            </span>
          ),
        },
        {
          title: intl.formatMessage({
            id: 'myRewards.columns.actions',
          }),
          field: 'actions',
          render: (rowData: any) => (
            <>
              {rowData.status === RewardIntentionStatus.Waiting && (
                <Grid
                  container
                  spacing={2}
                  justifyContent='flex-start'
                  alignItems='center'
                >
                  {[
                    {
                      id: 'bank',
                      label: intl.formatMessage({
                        id: 'myRewards.columns.bank',
                      }),
                      lightColor: theme.palette.primary.light,
                      darkColor: theme.palette.primary.main,
                      actionRequest: addRewardAmountToWallet,
                    },
                    {
                      id: 'accept',
                      label: intl.formatMessage({
                        id: 'myRewards.columns.accept',
                      }),
                      lightColor: theme.palette.secondary.light,
                      darkColor: theme.palette.error.main,
                      actionRequest: acceptReward,
                    },
                  ].map((actionButton: any) => (
                    <Grid item xs={6} key={actionButton.id}>
                      <Button
                        key={actionButton.id}
                        variant='contained'
                        sx={{
                          background: `linear-gradient(135deg, ${actionButton.darkColor} 0%, ${actionButton.lightColor} 100%) !important`,
                          minWidth: 100,
                        }}
                        size='small'
                        fullWidth
                        onClick={() =>
                          actionButton.actionRequest({
                            id: get(rowData, 'id'),
                          })
                        }
                      >
                        {actionButton.label}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              )}
              {(rowData.type === RewardType.Company || rowData.type === RewardType.Retailer) &&
                rowData.status === RewardIntentionStatus.Accepted && (
                  <Button
                    color='secondary'
                    onClick={() =>
                      // setRedeemRewardDialog({ isOpen: true, data: rowData })
                      handleRedeem(rowData)
                    }
                  >
                    {redeemRewardLoading ? (
                      <CircularProgress color='primary' size={24} />
                    ) : (
                      intl.formatMessage({
                        id: 'myRewards.columns.clickToRedeem',
                      })
                    )}
                  </Button>
                )}

              {rowData?.product?.claim_url &&
                rowData?.type === RewardType.Retailer &&
                (rowData?.status === RewardIntentionStatus.Accepted ||
                  rowData?.status === RewardIntentionStatus.Redeemed) && (
                  <Link
                    href={rowData?.product?.claim_url}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {intl.formatMessage({
                      id: 'myRewards.redeemYourGiftCard',
                    })}
                  </Link>
                )}
            </>
          ),
        },
      ]}
      pagination={pagination}
      setPagination={setPagination}
    />
  )
}
