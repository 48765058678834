import { ChangeEvent, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material'

import { Props } from '../../Login.props'
import PasswordRules from 'components/PasswordRules'
import { ROUTES_PATHS } from 'core/routes'
import { t } from 'i18n'
import { isValidPassword } from 'utils/formValidation'
import { SPARCK_USER_TOKEN } from 'constants/app'

export default function LoginForm(props: Props) {
  const {
    loginState,
    setLoginState,
    loginMutation,
    forgotPasswordMutation,
    recoverPasswordMutation,
    history,
  } = props

  const [password, setPassword] = useState('')

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
    setLoginState({ ...loginState, password: e.target.value })
  }

  const handleSubmit = () => {
    recoverPasswordMutation.mutate(loginState, {
      onSuccess: (response: any) => {
        const email = response?.email

        if (email) {
          setLoginState({ ...loginState, email })

          loginMutation.mutate(
            { email, password },
            {
              onSuccess: (loginResponse: any) => {
                const token = loginResponse?.token
                if (token) {
                  localStorage.setItem(SPARCK_USER_TOKEN, token)

                  history.push(ROUTES_PATHS.dashboard)
                } else {
                  console.error('Token not found')
                }
              },
              onError: (error) => {
                console.error('Login Error:', error)
              },
            }
          )
        }
      },
    })
  }

  return (
    <>
      <Grid container justifyContent='center'>
        <Grid item xs={12}>
          <Typography variant='h5' color='text.secondary' align='center'>
            {loginState.token
              ? t('login.recoverPassword.setPassword')
              : t('login.recoverPassword.forgotPassword')}
          </Typography>
        </Grid>
      </Grid>

      <Box my={5}>
        {loginState.token && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant='body2'
                color='text.secondary'
                align='center'
                paragraph
              >
                {t('login.recoverPassword.enterNewPassword')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id='password'
                type='password'
                label={t('login.recoverPassword.formLabels.password')}
                variant='filled'
                data-cy='setNewPasswordField'
                value={password}
                onChange={handlePasswordChange}
                fullWidth
              />
            </Grid>

            <PasswordRules password={password} />
          </Grid>
        )}

        {!loginState.token && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant='body2'
                color='text.secondary'
                align='center'
                paragraph
              >
                {t('login.recoverPassword.enterEmail')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id='email'
                type='email'
                label={t('email')}
                variant='filled'
                data-cy='email'
                value={loginState.email}
                onChange={e =>
                  setLoginState({
                    ...loginState,
                    email: e.target.value,
                  })
                }
                fullWidth
              />
            </Grid>
          </Grid>
        )}
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          {loginState.token && (
            <Button
              color='primary'
              variant='contained'
              size='large'
              data-cy='setNewPasswordButton'
              onClick={handleSubmit}
              disabled={
                recoverPasswordMutation.isLoading || !isValidPassword(password)
              }
              fullWidth
            >
              {recoverPasswordMutation.isLoading ? (
                <CircularProgress size={24} />
              ) : (
                t('login.recoverPassword.setPasswordButton')
              )}
            </Button>
          )}

          {!loginState.token && (
            <Button
              color='primary'
              variant='contained'
              size='large'
              data-cy='submitFormButton'
              onClick={() => forgotPasswordMutation.mutate(loginState)}
              disabled={forgotPasswordMutation.isLoading}
              fullWidth
            >
              {forgotPasswordMutation.isLoading ? (
                <CircularProgress size={24} />
              ) : (
                t('login.recoverPassword.recoverPasswordButton')
              )}
            </Button>
          )}
        </Grid>

        <Grid item xs={12}>
          <Button
            color='primary'
            variant='outlined'
            size='large'
            data-cy='backToLoginButton'
            onClick={() => history.push(ROUTES_PATHS.login)}
            fullWidth
          >
            {t('login.recoverPassword.backToLoginButton')}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
