import { Box, Grid, Paper, Typography } from '@mui/material'

import sparckLogo from 'assets/images/sparck-logo-colored.png'
import wave05 from 'assets/images/waves/wave-05.png'
import { useUser } from 'contexts'
import { t } from 'i18n'

const LOGO_SIZE = 88

export default function OnboardingLanding() {
  const { user } = useUser()!
  return (
    <Grid container justifyContent='center'>
      <Grid item xs={12} sm={9} md={8} lg={6}>
        <Paper style={{ position: 'relative' }}>
          <img
            src={wave05}
            alt='wave'
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              width: '100%',
              minHeight: 120,
              zIndex: 1,
              borderRadius: '15px 0px 0px 15px'
            }}
          />
          <Box mt={5} py={5} px={3} width={1}>
            <Grid container justifyContent='center'>
              <Grid item style={{ zIndex: 999 }}>
                <Paper
                  elevation={4}
                  style={{
                    borderRadius: '100%',
                    padding: 24,
                  }}
                >
                  <Box
                    component='img'
                    src={sparckLogo}
                    alt='Sparck Logo'
                    sx={{
                      width: `${LOGO_SIZE}px`,
                      height: `${LOGO_SIZE}px`,
                      zIndex: 999,
                    }}
                  />
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Box width={1} mt={2} mb={4}>
                  <Typography
                    variant='h3'
                    fontWeight={600}
                    align='center'
                    color='text.primary'
                    paragraph
                  >
                    {t('onboarding.landing.welcomeAboard')}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Grid container justifyContent='center'>
                  <Grid item xs={12} sm={11} md={10} lg={9}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant='body1'
                          color='text.secondary'
                          paragraph
                        >
                          {t('onboarding.landing.paragraph1', {
                            name: user.first_name && user.first_name,
                          })}
                        </Typography>

                        <Typography
                          variant='body1'
                          color='text.secondary'
                          paragraph
                        >
                          {t('onboarding.landing.paragraph2')}
                        </Typography>

                        <Typography
                          variant='body1'
                          color='text.secondary'
                          paragraph
                        >
                          {t('onboarding.landing.paragraph3')}
                        </Typography>

                        <Typography
                          variant='body1'
                          color='text.secondary'
                          paragraph
                        >
                          {t('onboarding.landing.paragraph4')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}
