import React, { useState, useEffect, useRef } from 'react'

import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Tab,
  Tabs,
  Theme,
  useMediaQuery,
} from '@mui/material'
import { get } from 'lodash'

import { useIntl } from 'react-intl'
import { FetchOptions } from 'react-query/types/core/query'

import { MediaPicker, TextFieldWithMentions, UserAvatar } from 'components'
import { useUser } from 'contexts'
import { UserPermission } from 'enums/user'
import { t } from 'i18n'
import { Sparck } from 'pages'
import { PostMedia, PostState, PostType } from 'types'
import { useURLQuery } from 'utils/hooks'
import { MediaType } from 'components/MediaPicker'

type Props = {
  postState: PostState
  setPostState: React.Dispatch<React.SetStateAction<PostState>>
  publishPost: (post: PostType) => void
  publishPostStatus: {
    isLoading: boolean
  }
  uploadPostMediaStatus: {
    isLoading: boolean
  }
  fetchPosts: (
    options?: FetchOptions | undefined,
  ) => Promise<PostType[] | undefined>
  setPostsState: React.Dispatch<React.SetStateAction<PostType[]>>
  postMedia: PostMedia
  setPostMedia: React.Dispatch<React.SetStateAction<PostMedia>>
}

export default function FeedAction({
  postState,
  setPostState,
  publishPost,
  publishPostStatus,
  uploadPostMediaStatus,
  fetchPosts,
  setPostsState,
  setPostMedia,
}: Props) {
  const mediaPickerRef = useRef<any>(null)
  const textFieldMentionsRef = useRef<any>(null)

  const [interactionMode, setInteractionMode] = useState('message')

  const query: any = useURLQuery()
  const intl = useIntl()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const { user } = useUser()!

  useEffect(() => {
    if (query.get('user_to_recognize_id')) {
      setInteractionMode('recognize')
    }
  }, [query])

  return (
    <>
      <Paper>
        <Grid container>
          <Grid item xs={12}>
            <AppBar position='static' elevation={0}>
              <Tabs
                value={interactionMode}
                onChange={(event, newValue) => setInteractionMode(newValue)}
                aria-label='interaction tabs'
                variant='fullWidth'
                textColor='inherit'
                indicatorColor='secondary'
              >
                {user.permissions.includes(UserPermission.ManagePosts) && (
                  <Tab label={'Feed'} value='message' />
                )}
                {user.permissions.includes(
                  UserPermission.CreateFeedRecognition,
                ) && (
                    <Tab
                      label={t('feed.tabLabels.recognize')}
                      value='recognize'
                    />
                  )}
              </Tabs>
            </AppBar>
          </Grid>

          {interactionMode === 'message' &&
            user.permissions.includes(UserPermission.ManagePosts) ? (
            <Grid item xs={12}>
              <Box width={1} padding={2}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid
                      container
                      justifyContent='space-between'
                      alignItems='center'
                      spacing={2}
                    >
                      {!isMobile && (
                        <Grid item>
                          <UserAvatar
                            userId={get(user, 'sub')}
                            name={get(user, 'first_name')}
                            src={get(user, 'avatar.url')}
                            size={isTablet ? 32 : 40}
                            aria-controls='profile-feed-action'
                            aria-haspopup='true'
                          />
                        </Grid>
                      )}

                      <Grid item xs data-cy='postTextAndMentions'>
                        <TextFieldWithMentions
                          ref={textFieldMentionsRef}
                          onContentChange={(content, mentions) => {
                            setPostState({
                              ...postState,
                              content,
                              mentions,
                            })
                          }}
                          placeholder={intl.formatMessage({
                            id: 'feed.messagePlaceholder',
                          })}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Box py={2} width={1}>
                      <Grid container justifyContent='flex-end'>
                        <MediaPicker
                          ref={mediaPickerRef}
                          allowGifs
                          allowImagesAndVideos
                          onSelectMedia={media => {
                            setPostMedia({} as PostMedia)

                            if (media.file) return setPostMedia(media.file)

                            if (media.type === MediaType.Gif) {
                              return setPostState({
                                ...postState,
                                gif_id_on_provider: media.name ?? '',
                              })
                            }

                            setPostState({
                              ...postState,
                              external_media_url: media.src ?? '',
                            })
                          }}
                        />
                      </Grid>

                      <Grid container justifyContent='flex-end'>
                        <Button
                          variant='contained'
                          color='primary'
                          size='large'
                          data-cy='submitPostFormButton'
                          disabled={
                            !postState.content ||
                            publishPostStatus.isLoading ||
                            uploadPostMediaStatus.isLoading
                          }
                          onClick={() => {
                            publishPost(postState)

                            mediaPickerRef?.current?.clearSelectedMedia()
                            textFieldMentionsRef?.current?.clearContent()
                          }}
                        >
                          {publishPostStatus.isLoading ? (
                            <CircularProgress size={24} />
                          ) : (
                            t('feed.postButtonText')
                          )}
                        </Button>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          ) : (
            user.permissions.includes(UserPermission.CreateFeedRecognition) && (
              <Sparck
                fromFeed
                sendSparckCallback={async () => {
                  await setPostsState([])

                  setInteractionMode('message')
                  fetchPosts()
                }}
              />
            )
          )}
        </Grid>
      </Paper>
    </>
  )
}
