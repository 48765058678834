import { useWeglot } from 'react-weglot'
import LanguageIcon from '@mui/icons-material/Language'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import Popover from '@mui/material/Popover'
import Button from '@mui/material/Button'
import { Box, Theme, Typography, useMediaQuery } from '@mui/material'
import { FC, useEffect, useState } from 'react'

type OnboardingLanguagePickerProps = {
  onSelect?: (language: string) => void
}

const languageLabels = {
  en: 'English',
  es: 'Spanish',
}

const OnboardingLanguagePicker: FC<OnboardingLanguagePickerProps> = ({ onSelect }) => {
  const [lang, setLang] = useWeglot('wg_ca83f684e2bea1afe0dc71a735e3fe690', 'en')
  const [currentLabel, setCurrentLabel] = useState(languageLabels['en'])
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  useEffect(() => {
    setCurrentLabel(languageLabels[lang] || 'English')
  }, [lang])

  const handleLanguageChange = (newLang: string) => {
    setLang(newLang)
    if (onSelect) {
      onSelect(newLang)
    }
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: isMobile ? 10 : 16,
        right: isMobile ? 10 : 16,
        zIndex: 1000,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <PopupState variant="popover" popupId="language-switcher-popup">
        {(popupState) => (
          <div>
            <Button
              {...bindTrigger(popupState)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                backgroundColor: 'white',
                padding: 1,
                borderRadius: 2,
                boxShadow: 2,
                '&:hover': {
                  backgroundColor: 'grey.100',
                },
              }}
            >
              <img
                src={lang === 'en' ? 'https://flagcdn.com/w40/us.png' : 'https://flagcdn.com/w40/es.png'}
                alt={`${lang === 'en' ? 'United States' : 'Spain'} flag`}
                width="20"
                height="15"
              />
              <Typography
                variant="body2"
                sx={{ fontWeight: 500, textTransform: 'capitalize' }}
              >
                {currentLabel}
              </Typography>
              <LanguageIcon fontSize="small" />
            </Button>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: 1,
                  gap: 1,
                }}
              >
                <Button
                  onClick={() => {
                    handleLanguageChange('en');
                    popupState.close();
                  }}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    justifyContent: 'flex-start',
                  }}
                >
                  <img
                    src="https://flagcdn.com/w40/us.png"
                    alt="United States flag"
                    width="20"
                    height="15"
                  />
                  <Typography>English</Typography>
                </Button>
                <Button
                  onClick={() => {
                    handleLanguageChange('es')
                    popupState.close()
                  }}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    justifyContent: 'flex-start',
                  }}
                >
                  <img
                    src="https://flagcdn.com/w40/es.png"
                    alt="Spain flag"
                    width="20"
                    height="15"
                  />
                  <Typography>Spanish</Typography>
                </Button>
              </Box>
            </Popover>
          </div>
        )}
      </PopupState>
    </Box>
  )
}

export default OnboardingLanguagePicker
