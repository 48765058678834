import { Badge, Grid, IconButton, Popper, useTheme } from '@mui/material'
import { NotificationsOutlined as NotificationsIcon } from '@mui/icons-material'
import {
  bindPopper,
  bindToggle,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import { useHistory } from 'react-router-dom'

import { useBadgesCount } from 'contexts'
import { ROUTES_PATHS } from 'core/routes'
import { Notifications } from 'pages'

type Props = {
  notificationsCount: number | null
  redirectToPage?: boolean
  isMobile?: boolean
}

export default function TopbarNotifications({
  notificationsCount,
  redirectToPage,
  isMobile,
}: Props) {
  const theme: any = useTheme()
  const history = useHistory()
  const { fetchBadgesCount } = useBadgesCount()!

  const notificationsMenuState = usePopupState({
    variant: 'popper',
    popupId: `notificationsMenu`,
  })

  return (
    <Grid item sx={{ mr: 1 }}>
      <IconButton
        type="button"
        {...(redirectToPage && {
          onClick: () => history.push(ROUTES_PATHS.notifications),
        })}
        {...(!redirectToPage && {
          ...bindToggle(notificationsMenuState),
        })}
        sx={{
          padding: isMobile ? 0.5 : 1,
        }}
      >
        <Badge badgeContent={notificationsCount} color="error">
          <NotificationsIcon
            style={{
              fontSize: isMobile ? 30 : 32,
              color: theme.palette.text.secondary,
              cursor: 'pointer',
            }}
          />
        </Badge>
      </IconButton>

      <Popper
        style={{
          zIndex: 1201,
          width: 360,
          marginTop: '-4px',
        }}
        {...bindPopper(notificationsMenuState)}
      >
        <Notifications
          fetchBadgesCount={fetchBadgesCount}
          notificationsMenuState={notificationsMenuState}
        />
      </Popper>
    </Grid>
  )
}
