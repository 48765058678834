import { Drawer, Box, IconButton, Grid, Badge, BottomNavigationAction, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useTheme } from '@mui/material/styles'
import { useLocation } from 'react-router-dom'
import { useUser, useBadgesCount, useCompany } from 'contexts'
import { DRAWER_MENU_ROUTES, ROUTES_PATHS } from 'core/routes'
import { validateRoutePermission } from 'utils/app'
import { NavLink as RouterLink } from 'react-router-dom'
import LanguageSwitcher from './LanguageSwitcher'

const DrawerMenu = ({ open, onClose }) => {
  const theme = useTheme()
  const { user } = useUser()!
  const { badgesCount } = useBadgesCount()!
  const { company } = useCompany()!
  const location = useLocation()

  const mobileRoutes = DRAWER_MENU_ROUTES(company, badgesCount)
  const routes = mobileRoutes.routes
    .flatMap(route => {
      if (route.surveys) {
        return [{ ...route, isSurvey: true }]
      }
      return [route]
    })
    .filter(route => validateRoutePermission(user, route))

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          height: '70%',
          borderTopLeftRadius: 15,
          borderTopRightRadius: 15,
          overflow: 'hidden',
        },
      }}
    >
      <Box sx={{ position: 'relative', p: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Grid container spacing={2} justifyContent="flex-start">
          {routes.filter(route => route.surveys).map((route) => {
            const routePath =
              ROUTES_PATHS[route.id] !== undefined
                ? ROUTES_PATHS[route.id]
                : route.path;
            const isActive = location.pathname === routePath;

            return (
              <Grid item xs={12} key={route.id} sx={{ marginBottom: '10px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Grid container spacing={2} justifyContent="center" sx={{ flexWrap: 'wrap' }}>
                    {route.surveys
                      .filter((survey) => validateRoutePermission(user, survey))
                      .map((survey) => {
                        return (
                          <Grid
                            item
                            xs={6}
                            key={survey.id}
                            sx={{
                              textAlign: 'center',
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}
                          >
                            <BottomNavigationAction
                              sx={{
                                padding: 0,
                                minWidth: 'auto',
                                margin: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                              }}
                              icon={
                                <Box
                                  sx={{
                                    color: isActive
                                      ? theme.palette.primary.main
                                      : theme.palette.text.primary,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Badge
                                    badgeContent={survey.badgeCount || 0}
                                    color="error"
                                    sx={{
                                      '& .MuiBadge-badge': {
                                        right: -8,
                                        top: 8,
                                      },
                                    }}
                                  >
                                    <survey.Icon sx={{ fontSize: 30 }} />
                                  </Badge>
                                </Box>
                              }
                              component={RouterLink}
                              to={survey.path || ROUTES_PATHS[survey.id]}
                            />
                            <Typography variant="caption" sx={{ mt: 0.5, textAlign: 'center' }}>
                              {survey.title}
                            </Typography>
                          </Grid>
                        );
                      })}
                  </Grid>
                </Box>
              </Grid>
            )
          })}
          <Grid item xs={12} container direction="row" alignItems="center" justifyContent="center" marginBottom={10}>
            {routes.filter(route => !route.surveys).map((route) => {
              const routePath =
                ROUTES_PATHS[route.id] !== undefined
                  ? ROUTES_PATHS[route.id]
                  : route.path;
              const isActive = location.pathname === routePath;

              return (
                <Grid item xs={6} sm={4} md={3} key={route.id} sx={{ marginBottom: '10px' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mx: 'auto',
                      width: 'fit-content',
                    }}
                  >
                    <BottomNavigationAction
                      sx={{
                        padding: 0,
                        minWidth: 'auto',
                        margin: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                      icon={
                        <Box
                          sx={{
                            color: isActive
                              ? theme.palette.primary.main
                              : theme.palette.text.primary,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Badge
                            badgeContent={route.badgeCount || 0}
                            color="error"
                            sx={{
                              '& .MuiBadge-badge': {
                                right: -8,
                                top: 8,
                              },
                            }}
                          >
                            <route.Icon sx={{ fontSize: 30 }} />
                          </Badge>
                        </Box>
                      }
                      component={RouterLink}
                      to={ROUTES_PATHS[route.id] ?? route.path}
                    />
                    <Typography variant="caption" sx={{ mt: 0.5, textAlign: 'center' }}>
                      {route.title}
                    </Typography>
                  </Box>
                </Grid>
              )
            })}
            {company?.allow_internationalization && (
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 60,
                  right: 16,
                }}
              >
                <LanguageSwitcher />
              </Box>
            )}
          </Grid>

        </Grid>
      </Box>
    </Drawer>

  )
}

export default DrawerMenu
