import { useState } from 'react'

import {
  Box,
  Card,
  CardContent,
  Grid,
  CircularProgress,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import { Check as CheckIcon, Edit as EditIcon } from '@mui/icons-material'

import { userHasBeseenPermissions } from 'utils/user'
import { DialogTeleport } from 'components'
import { CompanyNotifications } from '..'
import { initialDialog } from 'constants/dialog'
import { get } from 'lodash'
import { UserPermission } from 'enums/user'
import { t } from 'i18n'

type CompanyOverviewProps = {
  company: any
  employeesCount: { count: number }
  isEmployeesCountLoading: boolean
  companyAdmins: any[]
  companyAdminsIsLoading: boolean
  user: any
  companyNotificationsDialog: any
  setCompanyNotificationsDialog: any
  handleSendEmail: any
  companyState?: any
  setCompanyState?: any
  handleCompanyAPIAction?: any
}

function CompanyOverviewSection({
  label,
  values,
  withEditButton = false,
  setEditMode,
}: any) {
  const [seeMore, setSeeMore] = useState(false)

  return (
    <Box mb={3}>
      <Box mb={1}>
        <Typography color='text.secondary' variant='body2' fontWeight={400}>
          {label}
        </Typography>
      </Box>

      <Grid container alignItems='center'>
        <Grid item xs='auto'>
          {values.slice(0, seeMore ? values.length : 3).map((value, index) => (
            <Box mb={1} key={value + index}>
              <Typography
                color='text.secondary'
                variant='body2'
                fontWeight='bold'
              >
                {value}
              </Typography>
            </Box>
          ))}
        </Grid>

        {values.length >= 3 && (
          <Grid item xs={12}>
            <Button
              color='primary'
              size='small'
              onClick={() => setSeeMore(!seeMore)}
              style={{ padding: 0 }}
            >
              {seeMore
                ? t('myCompany.companyOverview.seeLess')
                : t('myCompany.companyOverview.seeMore')}
            </Button>
          </Grid>
        )}

        {withEditButton && (
          <Grid item xs='auto'>
            <Box ml={1} mb={1} width={1}>
              <IconButton
                onClick={() => setEditMode && setEditMode(true)}
                size='small'
              >
                <EditIcon color='primary' />
              </IconButton>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default function CompanyOverview(props: CompanyOverviewProps) {
  const {
    user,
    company,
    companyAdmins,
    companyAdminsIsLoading,
    employeesCount,
    isEmployeesCountLoading,
    companyNotificationsDialog,
    setCompanyNotificationsDialog,
    handleSendEmail,
    companyState,
    setCompanyState,
    handleCompanyAPIAction,
  } = props

  const [editMode, setEditMode] = useState<boolean>(false)

  const renderLoadingOrContent = () => {
    if (companyAdminsIsLoading || isEmployeesCountLoading) {
      return (
        <Grid
          container
          justifyContent='center'
          alignItems='center'
          style={{ height: '100%' }}
        >
          <CircularProgress size={48} />
        </Grid>
      )
    }

    const renderCompanyNameSection = () =>
      editMode ? (
        <TextField
          id='name'
          name='name'
          label={t('myCompany.companyOverview.textFieldLabel')}
          variant='outlined'
          value={companyState.name}
          required
          onChange={event =>
            setCompanyState({ ...companyState, name: event.target.value })
          }
          fullWidth
          margin='normal'
          InputProps={{
            endAdornment: (
              <InputAdornment position='start'>
                <IconButton
                  onClick={() => {
                    handleCompanyAPIAction(companyState)
                    setEditMode(false)
                  }}
                  size='large'
                >
                  <CheckIcon color='primary' />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <CompanyOverviewSection
          label={t('myCompany.companyOverview.sectionLabels.name')}
          values={[get(company, 'name')]}
          setEditMode={setEditMode}
          withEditButton
        />
      )

    return (
      <>
        {renderCompanyNameSection()}
        <CompanyOverviewSection
          label={t('myCompany.companyOverview.sectionLabels.numberOfEmployees')}
          values={[employeesCount?.toString()]}
        />
        {(user.permissions ?? []).includes(
          UserPermission.ViewAdministrators,
        ) && (
            <CompanyOverviewSection
              label={t(
                'myCompany.companyOverview.sectionLabels.companyAdministrators',
              )}
              values={(companyAdmins ?? []).map(admin => admin.full_name)}
            />
          )}
        {(user.permissions ?? []).includes(UserPermission.SendEmails) &&
          userHasBeseenPermissions(user) && (
            <Grid container>
              <Grid item xs={12}>
                <Button
                  variant='outlined'
                  color='primary'
                  onClick={() =>
                    setCompanyNotificationsDialog({
                      isOpen: true,
                      data: company,
                    })
                  }
                >
                  {t('myCompany.companyOverview.sendBulkMessage')}
                </Button>
              </Grid>
            </Grid>
          )}

        <DialogTeleport
          dialogTitle={t('myCompany.companyOverview.dialogTitle')}
          dialogAction={null}
          dialogSize='sm'
          dialogOpen={companyNotificationsDialog.isOpen}
          handleDialogClose={() => setCompanyNotificationsDialog(initialDialog)}
          isFullWidth
        >
          <CompanyNotifications company={company} handleSendEmail={handleSendEmail} />
        </DialogTeleport>
      </>
    )
  }

  return (
    <Card style={{ height: '100%' }}>
      <CardContent>
        <Typography color='text.primary' variant='h5'>
          {t('myCompany.companyOverview.cardTitle')}
        </Typography>
        <br />
        <br />
        {renderLoadingOrContent()}
      </CardContent>
    </Card>
  )
}
