import { lighten, useTheme } from '@mui/material'
import {
  Payment as InvoicesIcon,
  PictureAsPdf as PdfIcon,
} from '@mui/icons-material'
import { useIntl } from 'react-intl'

import { CompanyAdminLayout } from 'components'
import { getCurrencyLabel, getDateLabel } from 'utils/tables/dataTypes'

export default function Invoices() {
  const theme = useTheme()
  const intl = useIntl()

  return (
    <CompanyAdminLayout
      slug='invoices'
      title={intl.formatMessage({ id: 'invoices.title' })}
      description={intl.formatMessage({ id: 'invoices.description' })}
      color={lighten(theme.palette.error.main, 0.2)}
      requestUrl='invoices'
      tableProps={{
        actions: [
          {
            icon: () => <PdfIcon />,
            disabled: () => false,
            tooltip: () => intl.formatMessage({ id: 'invoices.invoicePDF' }),
            onClick: (_, rowData) => window.open(rowData.invoice_pdf),
          },
          {
            icon: () => <InvoicesIcon />,
            tooltip: () =>
              intl.formatMessage({ id: 'invoices.proceedToPayment' }),
            disabled: (rowData: any) => rowData.paid,
            onClick: (_, rowData) => window.open(rowData.hosted_invoice_url),
          },
        ],
        columns: [
          {
            title: 'ID',
            field: 'number',
            editable: 'never',
          },
          {
            title: intl.formatMessage({ id: 'invoices.fields.invoiceDate' }),
            field: 'created',
            render: (rowData: any) => getDateLabel(rowData.created * 1000),
            editable: 'never',
          },
          {
            title: intl.formatMessage({ id: 'invoices.fields.receiptNumber' }),
            field: 'receipt_number',
            render: (rowData: any) => <p>{rowData.receipt_number || '--'}</p>,
            editable: 'never',
          },
          {
            title: intl.formatMessage({ id: 'description' }),
            field: 'description',
            editable: 'never',
          },
          {
            title: intl.formatMessage({ id: 'status' }),
            field: 'status',
            render: (rowData: any) => (
              <p
                style={{
                  color: rowData.paid
                    ? theme.palette.primary.dark
                    : theme.palette.secondary.dark,
                }}
              >
                {rowData.paid
                  ? intl.formatMessage({ id: 'invoices.fields.paid' })
                  : intl.formatMessage({ id: 'statuses.pending' })}
              </p>
            ),
            editable: 'never',
          },
          {
            title: intl.formatMessage({ id: 'invoices.fields.amount' }),
            align: 'right',
            field: 'total',
            render: (rowData: any) => getCurrencyLabel(rowData.total / 100),
            editable: 'never',
          },
        ],
        deleteActionProps: {
          hidden: () => true,
        },
      }}
      IconHeader={InvoicesIcon}
    />
  )
}
