import { forwardRef } from "react"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export const PhoneInputPicker = forwardRef<any, any>(function PhoneInputPicker(
  props,
  ref,
) {
  const { onChange, value, ...other } = props

  return (
    <PhoneInput
      {...other}
      ref={ref}
      value={value ?? ''}
      onChange={value => {
        onChange({
          target: {
            name: props.name,
            value: value,
          },
        })
      }}
      country={'us'}
      preferredCountries={['br', 'ca', 'gb', 'ph', 'us']}
      international
      withCountryCallingCode
      placeholder=''
      inputProps={{
        ...other.inputProps,
        ref,
      }}
      inputStyle={{
        width: '100%',
        height: '30px',
        fontSize: '16px',
        border: 'none',
        paddingLeft: '48px',
        color: '#464C53',
        fontFamily: 'poppins',
        fontWeight: '400'
      }}
      buttonStyle={{
        border: 'none',
        backgroundColor: 'transparent',
        marginTop: 4
      }}
      dropdownStyle={{
        margin: -1,
        borderRadius: 15,
        fontFamily: 'poppins',
        fontSize: '14px'
      }}
      searchStyle={{
        fontFamily: 'poppins',
        fontSize: '12px'
      }}
      enableSearch={true}
      searchPlaceholder="Search Country"
    />
  )
})
