import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Drawer,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { NavLink as RouterLink, Link } from 'react-router-dom'

import { Copyright } from '..'
import {
  TopbarNotifications,
  TopbarShoppingCart,
  TopbarTakeAction,
} from '../topbar'
import sparckFullColored from 'assets/images/sparck-full-colored.png'
import sparckFullColoredDarkMode from 'assets/images/sparck-full-colored-dark-mode.png'
import { AdminCompaniesSelect, LanguagePicker } from 'components'
import { DRAWER_OPENED_WIDTH } from 'constants/app'
import {
  useBadgesCount,
  useCompany,
  useDarkMode,
  useSidebarExpanded,
  useUser,
} from 'contexts'
import { ROUTES_PATHS, SIDEBAR_ROUTES, SparckRouteType } from 'core/routes'
import { SidebarGroup } from 'enums/sidebar'
import { validateRoutePermission } from 'utils/app'

export default function Sidebar({
  showCopyright,
  showTopbar,
  showSidebar,
  mobileDrawerOpen,
  setMobileDrawerOpen,
  isMobile,
  isTablet,
  isLaptop,
}) {
  const { user } = useUser()!
  const { badgesCount } = useBadgesCount()!
  const { darkMode } = useDarkMode()!

  return (
    <nav style={{ position: 'relative' }}>
      <Drawer
        sx={{
          zIndex: 1300,
          position: 'relative',
          width: DRAWER_OPENED_WIDTH,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: DRAWER_OPENED_WIDTH,
            boxSizing: 'border-box',
          },
        }}
        open={mobileDrawerOpen || (!isMobile && !isTablet)}
        onClose={() => setMobileDrawerOpen(false)}
        variant={isMobile || isTablet ? 'temporary' : 'permanent'}
        anchor='left'
        elevation={0}
        PaperProps={{
          sx: {
            ml: isMobile || isTablet ? 0 : 5,
            boxShadow: '0px 0px 24px rgba(110, 120, 130, 0.1)',
            borderRadius: isMobile || isTablet ? 0 : '0px 0px 36px 36px',
            height: isMobile || isTablet ? '100%' : `calc(100% - 2.5%)`,
            borderRight: 0,
          },
        }}
      >
        <Grid
          container
          direction='column'
          justifyContent='space-between'
          sx={{ height: 1 }}
        >
          <Grid item xs='auto'>
            <Link to={ROUTES_PATHS.dashboard}>
              <Grid
                container
                justifyContent='center'
                position='relative'
                sx={{ mt: 2.5, mb: 3 }}
              >
                <Box
                  component='img'
                  src={
                    darkMode === 'dark'
                      ? sparckFullColoredDarkMode
                      : sparckFullColored
                  }
                  alt='Sparck logo'
                  sx={{ width: '50%' }}
                />
              </Grid>
            </Link>
          </Grid>

          {user.sparck_administrator && (
            <Grid item xs='auto'>
              <AdminCompaniesSelect />
            </Grid>
          )}

          {showTopbar && (isMobile || isTablet || isLaptop) && (
            <Grid item xs="auto" mt={2}>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                {badgesCount?.take_actions ? (
                  <Grid item xs="auto" mb={1}>
                    <Grid container justifyContent="center">
                      <TopbarTakeAction takeActionsCount={badgesCount?.take_actions} isMobile />
                    </Grid>
                  </Grid>
                ) : null}

                {location.pathname.match("survey") && (
                  <Grid item xs="auto">
                    <LanguagePicker />
                  </Grid>
                )}

                <Grid item xs="auto">
                  <TopbarNotifications
                    notificationsCount={badgesCount?.unread_notifications}
                    redirectToPage
                    isMobile
                  />
                </Grid>

                <Grid item xs="auto">
                  <TopbarShoppingCart
                    user={user}
                    shoppingCartItemsCount={badgesCount?.shopping_cart_items}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}

          {showSidebar && (
            <Grid item xs style={{ overflowY: 'auto' }}>
              <SidebarLinks user={user} badgesCount={badgesCount} />
            </Grid>
          )}

          {showCopyright && (
            <Grid item xs='auto' sx={{ zIndex: 999 }}>
              <Box
                sx={{
                  py: 2,
                  boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                }}
              >
                <Copyright />
              </Box>
            </Grid>
          )}
        </Grid>
      </Drawer>
    </nav>
  )
}

const useStyles = makeStyles(theme => ({
  routerLink: {
    color: theme.palette.text.secondary,
    '& $icon': {
      color: theme.palette.text.secondary,
    },
  },

  active: {
    color: theme.palette.secondary.main,
    '& span': {
      fontWeight: 'bold',
    },
    '& $icon': {
      color: theme.palette.secondary.main,
    },
  },
}))

function SidebarLinks({ user, badgesCount }) {
  const { sidebarExpanded, setSidebarExpanded } = useSidebarExpanded()!

  const { company } = useCompany()!

  const getSectionBadge = {
    [SidebarGroup.Sparck]: 0,
    [SidebarGroup.General]:
      badgesCount?.open_beheard_surveys + badgesCount?.open_pulse_surveys,
    [SidebarGroup.Management]: 0,
    [SidebarGroup.Content]:
      badgesCount?.inappropriate_contents + badgesCount?.suggestions,
    [SidebarGroup.Others]: badgesCount?.invoices,
  }

  const handleChange =
    (group: SidebarGroup) =>
      (event: React.SyntheticEvent, isExpanded: boolean) => {
        const newExpanded = isExpanded
          ? [...sidebarExpanded, group]
          : sidebarExpanded.filter(
            sidebarExpandedItem => sidebarExpandedItem !== group,
          )

        setSidebarExpanded([...newExpanded])
      }

  const routes: (SparckRouteType | any)[] = []
  const sidebarRoutes = SIDEBAR_ROUTES(user, company)

  Object.keys(sidebarRoutes).forEach(section => {
    routes[section] = sidebarRoutes[section].filter(route =>
      validateRoutePermission(user, route),
    )
  })

  return (
    <Box data-cy='drawer'>
      {Object.keys(routes).map(
        (section: any) =>
          routes[section].length > 0 && (
            <Accordion
              key={section}
              expanded={sidebarExpanded.includes(section)}
              onChange={handleChange(section)}
              disableGutters
              elevation={0}
              TransitionProps={{ unmountOnExit: false }}
            >
              <AccordionSummary
                data-cy='accordionSummary'
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-${section}-content`}
                id={`panel-${section}-header`}
              >
                <Badge
                  badgeContent={
                    (!sidebarExpanded.includes(section) &&
                      getSectionBadge[section]) ||
                    0
                  }
                  color='error'
                  sx={{
                    '& .MuiBadge-badge': {
                      right: -16,
                      top: 16,
                      padding: '0 4px',
                    },
                  }}
                >
                  <Typography
                    variant='body2'
                    color={'text.light'}
                    fontWeight={500}
                    sx={{ my: 1, ml: 1 }}
                  >
                    {section.toUpperCase()}
                  </Typography>
                </Badge>
              </AccordionSummary>
              <AccordionDetails sx={{ p: '0px 0px 8px' }}>
                <Grid container>
                  <Grid item xs={12}>
                    {routes[section].map((route: SparckRouteType) => (
                      <SidebarMenuItem
                        route={route}
                        key={route.id}
                        badgesCount={badgesCount}
                      />
                    ))}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          ),
      )}
    </Box>
  )
}

function SidebarMenuItem({ route, badgesCount }) {
  const classes = useStyles()

  const getRouteBadge = {
    ['beheardSurvey']: badgesCount?.open_beheard_surveys,
    ['pulseSurveys']: badgesCount?.open_pulse_surveys,
    ['inappropriateContent']: badgesCount?.inappropriate_contents,
    ['suggestions']: badgesCount?.suggestions,
    ['invoices']: badgesCount?.invoices,
  }

  return (
    <RouterLink
      activeClassName={classes.active}
      className={classes.routerLink}
      to={ROUTES_PATHS[route.id] ?? (route.path as string)}
      key={route.path as string}
      strict
      exact
    >
      <ListItem
        button
        disableGutters
        key={route.path as string}
        sx={{ pl: 3, fontSize: '0.8rem', width: '100%' }}
      >
        <Grid container alignItems='center'>
          <Grid item xs='auto'>
            {route.Icon && (
              <Tooltip title={route.title} enterDelay={250}>
                <ListItemIcon
                  style={{
                    color: 'inherit',
                    minWidth: 48,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {<route.Icon style={{ fontSize: '1.5rem' }} />}
                </ListItemIcon>
              </Tooltip>
            )}
          </Grid>

          <Grid item xs>
            <Badge
              badgeContent={getRouteBadge[route.id] || 0}
              color='primary'
              sx={{
                '& .MuiBadge-badge': {
                  right: -16,
                  top: 12,
                  padding: '0 4px',
                },
              }}
            >
              <ListItemText
                primaryTypographyProps={{
                  sx: {
                    fontSize: '0.85rem',
                    fontWeight: 500,
                  },
                }}
              >
                {route.title}
              </ListItemText>
            </Badge>
          </Grid>
        </Grid>
      </ListItem>
    </RouterLink>
  )
}
