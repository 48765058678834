import { useState } from 'react'

import {
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
} from '@mui/material'
import { Send as SendIcon } from '@mui/icons-material'

import { initialDialog } from 'constants/dialog'
import { companyNotifications } from 'constants/notifications'
import { t } from 'i18n'

export default function CompanyNotifications({ company, handleSendEmail }) {
  const [confirmationDialog, setConfirmationDialog] =
    useState<any>(initialDialog)
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <List>
            {companyNotifications.map(companyEmail => (
              <ListItem key={companyEmail.id}>
                <ListItemText
                  primary={t(companyEmail.name)}
                  secondary={
                    <Typography variant='body1' color='text.secondary'>
                      {t(companyEmail.description)}
                    </Typography>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge='end'
                    aria-label='send'
                    onClick={() =>
                      setConfirmationDialog({
                        isOpen: true,
                        data: companyEmail,
                      })
                    }
                    size='large'
                  >
                    <SendIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
            <Dialog
              open={confirmationDialog.isOpen}
              onClose={() => setConfirmationDialog(initialDialog)}
              aria-labelledby='confirm-email'
              aria-describedby='confirm-email'
            >
              {confirmationDialog.data && (
                <>
                  <DialogTitle id='confirm-email'>
                    {t('myCompany.companyNotifications.dialogTitle')} "
                    {t(confirmationDialog.data.name)}"?
                  </DialogTitle>
                  <DialogActions>
                    <Button
                      onClick={() => setConfirmationDialog(initialDialog)}
                      color='primary'
                    >
                      {t('cancel')}
                    </Button>
                    <Button
                      onClick={() =>
                        handleSendEmail(
                          confirmationDialog.data.endpoint,
                          confirmationDialog.data.getPayload(company),
                        )
                      }
                      color='secondary'
                    >
                      {t('myCompany.companyNotifications.send')}
                    </Button>
                  </DialogActions>
                </>
              )}
            </Dialog>
          </List>
        </Grid>
      </Grid>
    </>
  )
}
