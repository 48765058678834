export default {
  onboarding: {
    ratingLabels: {
      notApplicable: 'Not Applicable',
      notAtAllImportant: 'Not at all Important',
      slightlyImportant: 'Slightly Important',
      important: 'Important',
      veryImportant: 'Very Important',
      extremelyImportant: 'Extremely Important',
    },
    header: {
      profile_questions: {
        title: 'You are uniquely special!',
        description:
          'We would love to learn more about the things that make you tick. Below are a few questions to get you started. If you change your mind, you can always update your answers on your personal profile page. Let’s dive in!',
      },
      rating_and_simple_select: {
        title: 'We want to know what makes YOU smile!',
        description:
          'Let’s start with the different forms of recognition that mean the most to you following a job well done. Then we’ll quickly look at your communication preferences with your manager.',
      },
      reward: {
        title: "It's reward time!",
        description:
          'We’re halfway there. Ready for some fun? Let’s take a look at some of your organization rewards. Which ones are important to you?',
      },
      event: {
        title: 'What makes you feel appreciated at work?',
        description1:
          'Rewards are great, but we know the recognition experience is just as important. Every person is unique in how they feel appreciated. We want to know what that looks like for you using the recognition areas below.',
        description2:
          'To get started, follow the column sequence from left to right and select your preferences. Don’t worry! You can always make edits later if your preferences change. Have fun!',
      },
      choice_group: {
        title: "It's all about style, baby!",
        description:
          'Here’s the final step! As you probably just experienced on the last page, recognition preferences change based on what you are being recognized for in the workplace. However, do you know what your primary appreciation style is? Most don’t. If you don’t know, your manager and colleagues won’t know either. Take this quick quiz to determine what type of appreciation fits you best. Drum roll please...let’s end with a bang and uncover your Sparck Appreciation Style!',
      },
    },
    onboardingProgress: 'Your Onboarding Progress:',
    landing: {
      welcomeAboard: 'Welcome Aboard!',
      paragraph1:
        'Hello, {name}. Welcome to the Sparck onboarding experience! This won’t take long, and the results will lead to some pretty cool stuff on the other end.',
      paragraph2:
        'The purpose of this process is to determine how you - yes, you as an individual - like to be recognized for your amazing accomplishments and hard work. We can’t wait to learn about what’s most important to you in the workplace.',
      paragraph3:
        'It should only take about 5 – 10 minutes (unless you want it to take longer!) Not too bad, right? Just remember, you need to finish the survey in one go. But don’t worry, you can always change your answers later on.',
      paragraph4:
        'Thank you so much for taking the time. We can’t wait to hear what makes you smile!',
    },
    pleaseMakeSelection: 'Please make a selection...',
    recognitionColumns: {
      recognitionAreas: 'Recognition Areas',
      recognitionMeaningful: 'Would recognition be meaningful?',
      publicSetting: 'In a public setting?',
      byWho: 'By who?',
      how: 'How?',
    },
    pagination: {
      previous: 'Previous',
      imReady: "I'm ready!",
      backToProfile: 'Back to Profile',
      finish: 'Finish',
      next: 'Next',
    },
    finish: {
      congratulations: 'CONGRATULATIONS!',
      completedOnboarding: "You've completed the onboarding process!",
      thankYou: 'Thank you so much for taking the time. We hope you had fun!',
      revealRecognitionStyle:
        "Now we'll take you to your profile page to reveal your Sparck Appreciation Style!",
      takeMeToMyDashboard: 'Take Me To My Dashboard!',
    },
  },
  feed: {
    commentManagement: {
      edit: 'Edit',
      save: 'Save',
      delete: 'Delete',
    },
    tabLabels: { post: 'Post', recognize: 'Recognize!' },
    messagePlaceholder: 'Write your message here...',
    postButtonText: 'Post',
    like: 'Like',
    likes: 'Likes',
    comment: 'Comment',
    comments: 'Comments',
    commentPlaceholder: 'Write your comment here...',
    hasBeen: 'has been',
    haveBeen: 'have been',
    recognized: 'Recognized!',
    multipleUsersRecognized: 'Recognized!',
    reportPost: 'Report this post',
    suggestion: {
      placeholder: 'Type your suggestion here...',
      reviewMessage:
        'This suggestion will be sent to the organization administrator for review.',
      makePrivate: 'Make this suggestion anonymous',
      submitIdeas: 'Submit your ideas',
    },
    feedValueLabels: {
      purpose: 'Our Purpose',
      values: 'Our Core Values',
      recognitionAreas: 'Our Recognition Areas',
      suggestionBox: 'Suggestion Box',
    },
    notDefined: 'This organization has no {value} defined yet.',
    loadAllPosts: 'Load All Posts',
    endOfPosts: "That's impressive! You've reached the end",
    removePost: 'Remove Post',
    thisPost: 'this post',
    commentInfo: { updated: 'Updated' },
    noPermissionToViewPosts: "You don't have permission to view posts.",
    noPosts:
      'There are no posts yet. Be the first person to recognize someone!',
    snackbar: {
      success: {
        suggestionSubmitted: 'Your suggestion was successfully submitted!',
      },
    },
  },
  giphy: {
    placeholder: 'What do you want to search?',
    dialog: { title: 'Select your Giphy!' },
  },
  profile: {
    tabs: {
      labels: {
        about: 'About {userName}',
        personal: 'Personal',
        professional: 'Professional',
        myRewards: 'Rewards',
        me: 'Me',
      },
    },
    profileItemLabels: {
      position: 'Position',
      generalSettings: 'General Settings',
      visibilitySettings: 'Visibility Settings',
      personalInterests: 'Personal Interests',
      hireDate: 'Hire Date',
      location: 'Location',
      reportsTo: 'Reports To',
      professionalInterests: 'Professional Interests',
      dateOfBirth: 'Date of Birth',
      positionPlaceholder: 'e.g. Senior Engineer',
      preferredPronouns: 'Preferred Pronouns',
      preferredPronounsPlaceholder: 'e.g. He/Him',
      howShouldRefer: 'How should we refer to you?',
      customPreferredPronouns: 'Custom Preferred Pronouns',
      preferredNotification: 'Notification Delivery Preference',
      sms: 'SMS',
      all: 'Both (Email/SMS)',
      mfa: 'Multi-Factor Authentication',
    },
    pronouns: { he: 'He/Him', she: 'She/Her', they: 'They/Them' },
    profileVisibilityLabels: {
      everyone: 'Everyone',
      managersOnly: 'Managers Only',
    },
    profileStrength: 'Profile Strength',
    impact: {
      myImpact: 'My Impact',
      userImpact: "{userName}'s Impact",
      totalRecognitions: { total: 'Total', recognitions: 'Recognitions' },
      progressBarLegend: { sent: 'Sent', received: 'Received' },
    },
    profileRecognitionStyle: {
      title: 'Sparck Appreciation Style',
      takeSurvey: 'Take Survey',
      showMe: {
        title: 'Show Me',
        descriptionSeeLess:
          'You appreciate it when your hard work is acknowledged with a meaningful, thoughtful gift or organization reward that aligns with your hobbies, interests, or goals. For you, it’s important to know that the work you do brings value to your employer and colleagues.',
        descriptionSeeMore:
          'You find joy in knowing your dedication and commitment is being recognized.  It goes beyond just your work, though.  You appreciate when people take the time to get to know you as a person and not just an employee. It means more to you to receive a gift reflecting your personal interests after achieving a quarterly goal than hearing verbal recognition. After a late night working on a project, you’d love to have your colleague or manager surprise you with your favorite beverage as a “pick me up”. It makes you feel valued for your efforts. This doesn’t mean you want gifts piled up at your desk.  It’s the genuine gifts that reflect your career goals or compliment your personal hobbies that make you feel best. It means your colleagues and manager have taken the time to get to know you personally',
      },
      celebrateWithMe: {
        title: 'Celebrate With Me',
        descriptionSeeLess:
          'Gifts, although we all love them, may mean less to you than the environment you work in.  You enjoy working with people who are caring and compassionate, who enjoy showing their appreciation in a way that you may see on a sports team.',
        descriptionSeeMore:
          'You love to give and receive virtual fist bumps and high fives while social distancing for a job well done! When greeted in the morning, an elbow tap “hello” goes a long way rather than a quick greeting in the hallway when passing by. When you hit a large goal individually or as a team, you find meaningful connections with your colleagues when they take the time to post zoom high-five icons all around to celebrate the success. You know that you are making an impact when your manager gives you a thumbs up for your accomplishments.  This makes you feel seen and you know your efforts are making a difference.',
      },
      connectWithMe: {
        title: 'Connect With Me',
        descriptionSeeLess:
          'You’re a “people person” to your core. Relationships matter to you! Working with others makes you happy, and you find tremendous satisfaction when bouncing ideas off others.  You find that your enjoyment at work is elevated when you build meaningful connections in the workplace and have a social outlet.',
        descriptionSeeMore:
          "It isn’t just about having people around.  Sure you may be social but it comes down to the quality of the time that you spend together. Someone present and in the moment during your conversations, instead of  looking at email or their phone, tells you that they genuinely care. During meetings with your manager you know that you are an important part of the team when they understand what's important to you, are actively listening, and give you one-on-one time. The best way to reward your hard work is to be a part of a team project and collaborate with your peers. And of course, all work and no play is boring - a celebratory team outing or team building activity are the events that create an organization culture that brings you the greatest happiness.",
      },
      tellMe: {
        title: 'Tell Me',
        descriptionSeeLess:
          'You’re someone who values words of appreciation in the workplace. Praise is important to you. You like to know that your efforts and your accomplishments have been recognized. Hearing a sincere “thank you” or “well done!” means more to you than a reward or high-five.',
        descriptionSeeMore:
          'These affirmations from management and your peers prove to you that your hard work and time is making a difference and contributing to the purpose of the organization. It isn’t just about words, but the sincerity behind the words. It is most meaningful to you when you understand the “why” behind the recognition, as this shows they really know you and understand the time and commitment you have put in. After completing a big project or going above and beyond for an important organization initiative, it means the most when a peer or your manager stops by your desk or sends an email to thank you personally. When you’re recognized unexpectedly, it reinforces you are in a workplace that truly values you. Meaningful words in a variety of forms from people throughout the organization equate to an organization culture that shows you are appreciated and a valuable part of the team!',
      },
      supportMe: {
        title: 'Support Me',
        descriptionSeeLess:
          'You are a team player and know that you can accomplish more together than alone. That doesn’t necessarily mean that you are more drawn to collaborative projects, but working alongside colleagues that are willing to lend a helping hand is important to you. You feel valued when others offer their support when you have a heavy workload, tight deadlines to meet, or are falling behind.',
        descriptionSeeMore:
          'This shows you that they appreciate you as more than just an employee but as a person - they care about you and what you need to be successful.  A work environment that not only recognizes accomplishments but provides the support to hit those accomplishments is one that you value most. When people go above and beyond their daily tasks to jump on a project with you or just check in on you during a stressful time you know your contributions matter. For you, it goes beyond the workplace.  You appreciate it when people notice that you may be having a rough day or dealing with a personal matter outside of work and offer help, whether that be taking a bit of your workload off your hands or simply being there to talk. These acts of kindness show you that you are appreciated and a valuable part of the team!',
      },
      trustMe: {
        title: 'Trust Me',
        descriptionSeeLess:
          'You are a natural-born leader and thrive when you are trusted with increased responsibilities. While some may enjoy the comfort of their daily tasks, you really hit your stride when allowed to spearhead new initiatives. You feel most appreciated when those around you notice your hard work and reward you with a chance to showcase more of your strengths. You consider it a great honor to have others trust you with high profile projects and much of your workplace confidence stems from being given these opportunities.',
        descriptionSeeMore:
          'It’s important to note that your personal drivers are geared toward growth opportunities, so the adage of tasks without the increased responsibility is not what you are looking for. You want to sharpen your skills and expand your abilities so an investment in your personal and professional development is always a treat! Nothing makes you happier at work than knowing people trust you to lead important strategies for the organization. An environment that allows you to stretch your wings is ideal. These experiences show you that you are appreciated and a valuable part of the team.',
      },
      undefined: {
        title: 'What is your Appreciation Style?',
        descriptionSeeLess:
          'We want to know you better! Take our survey and check out your appreciation style.',
        noStyleYet: "{userName} doesn't have a Appreciation Style yet.",
      },
      seeOnboarding: 'See Onboarding',
      editPreferences: 'Edit Preferences',
      seeLess: 'Read Less',
      seeMore: 'Read More',
      seeAllMyStyles: 'See all my styles',
    },
    recognitionMemories: {
      title: 'Recognition Memories',
      titleSingleMemory: 'Recognition Memory',
      noRecognitionMemories: {
        youDontHave: "You don't have {title} yet",
        userDoesntHave: "{userName} doesn't have {title} yet",
      },
      received: 'Received',
      sent: 'Sent',
      viewAll: 'View All',
      viewGivenUserRecognitions: "View {userName}'s Public Recognitions",
    },
    changeProfilePicture: {
      dialogTitle: 'Change your profile picture!',
      dragAndDrop:
        'Drag and drop your beautiful profile picture or just click here!',
      selectPicture: 'Select your profile picture',
      savePicture: 'Save Profile Picture',
    },
    snackbar: {
      success: {
        informationUpdated: 'Your information was updated successfully!',
        profilePictureUpdated: 'Your profile picture was successfully updated!',
      },
      error: { userNotFound: 'User not found' },
    },
  },
  sparcks: {
    sparckMemories: {
      header: {
        firstLine: "In case you've forgotten...",
        secondLine: "You're amazing!",
      },
      card: { privateSparck: 'This is a private Sparck' },
      viewSparck: 'View Sparck',
      sent: 'Sent',
      received: 'Received',
      noSparckMemories: 'No recognitions yet.',
      onlyPublicSparcksMessage:
        "You're only being shown the public recognitions.",
      public: 'Public',
      private: 'Private',
    },
    sparck: {
      header: 'Who are we going to recognize today?',
      selectPeopleToRecognize: 'Select the people you want to recognize below',
      viewAll: 'View All',
      usersToRecognize: 'Users to Recognize',
      formLabels: {
        name: 'I want to Recognize...',
        event: 'What are we celebrating?',
      },
      advancedSearchOptions: 'Advanced Search Options',
      selectUsersFirst: 'Select users to recognize first',
      noRecognitionEventFound: 'No recognition event found for selected users',
      advancedSearchDialogTitle: 'Advanced Search',
      makeMeaningfull: 'What will make this most meaningful to',
      personalize: 'Personalize Your Recognition',
      needSomeIdeas: 'Need Some Ideas?',
      sparckMessagePlaceholder: 'Write a message to {userName}...',
      sparckMentionWarning: {
        title: 'Privacy concern, be careful!',
        description:
          'You are @ mentioning someone in a private message. Doing this will give the @ mentioned person the ability to read the private message. If you DO NOT want them to view the message, please remove the @ mentioned person from the message.',
        actionLabel: 'Got it!',
      },
      selectedUsers: 'selected users',
      andMore: '{users} and {selectedCount} more...',
      makePrivate: 'Make This Private',
      preview: 'Preview',
      send: 'Send',
      prefers: 'prefers to be recognized',
      prefersNot: { prefers: 'prefers', notToBe: 'not to be recognized' },
      inPublic: 'in public',
      inPrivate: 'in private',
      by: 'by',
      in: 'in a',
      noPreferences: 'has not specified preferences for',
      include: 'Include',
      rewardOptions: {
        reward: 'Organization Reward',
        rewardNGC: 'Retailer Reward',
      },
      selectedReward: 'Selected Reward',
      chooseAnother: 'Choose Another',
      selectedRecognitionEventAutomaticBankWarning:
        "The Recognition Event selected has a reward amount that will be automatically banked to the recipient's Reward Bank.",
      giftCardRewardDetails: 'Gift Card Reward Details',
      virginExperiencesRewardDetails: 'Virgin Experiences Reward Details',
      selectOption: 'Select a',
      rewardFilterLabels: { all: 'All' },
      noRewards: 'There are no',
      sparckIdeas: {
        header: 'Here are some Sparck Ideas!',
        fieldLabel: 'Message Category',
      },
      sparckRewardsListFilterInfo:
        'Results are being filtered based on the max. amount of the selected recognition event: {maxAmount}',
      allRewards: 'All Rewards',
      favoritesOnly: 'Favorites Only',
      rewardsSearch: 'Rewards Search',
      searchForAReward: 'Search for a reward...',
      noRewardsFound: 'No rewards found.',
      sparckConfirmGiftCardRewardDetails: {
        selectGiftCardAmount: 'Select a Gift Card amount',
        recommendedGiftCardAmount: 'Recommended Gift Card amount',
        selectADifferentValueAmount: 'or select a different value amount',
      },
      sparckConfirmVirginExperiencesrewardDetails: {
        chooseARewardOptionBellow: 'Choose a reward option below:',
        confirmSelectedReward: 'Confirm selected reward',
      },
    },
    sparckDisplayAnswer: {
      yourResponseWas: 'Your response was:',
      clickAnyMessage: 'Click on any message below to send a quick answer',
      thankYou: 'Thank you!',
      youAreTooKind: "You're too kind!",
      wow: 'Wow!',
      typeCustomResponse: 'Or type a custom response',
      placeholder: 'Thank you so much for your recognition!',
      sendYourAnswer: 'Send your answer!',
    },
    sparckDisplayComponent: {
      goBack: 'Go back',
      noAuthorizationToViewSparck:
        'You do not have authorization to view this Sparck.',
      returnToDashboard: 'Return to Dashboard',
      youHave: "You've",
      has: 'has',
      beenRecognized: 'been Recognized!',
      weAreSoHappy: "We're so happy",
      forYou: ' for you',
      congratulations: 'Congratulations!',
      private: 'private',
      messageFrom: 'A {private} message from',
      revealYourGift: 'Click below and reveal your gift!',
    },
    snackbar: {
      success: {
        youSparcked: 'Sparck sent successfully!',
        responseSent: 'Your response was sent successfully!',
      },
    },
  },
  people: {
    connect: 'Connect with your Colleagues',
    searchFieldPlaceholder:
      'Search for names, emails, departments, business units and so on...',
    favoritesLabel: 'My Favorites only',
    tooltip: { add: 'Add to favorites', remove: 'Remove from favorites' },
    viewProfile: 'View profile',
    search: 'Search',
    infoButtons: { recognize: 'Recognize' },
  },
  language:{
    chooselanguage:'Välj ditt språk'
  },
  insights: {
    company: {
      title: 'Organization Insights',
      link: 'Go to Team Insights',
      recognitionActivity: 'Our Recognition Activity',
      recognitionByProgram: 'Recognition by Event',
      pulseSurveys: 'Pulse Surveys',
      engagementScore: 'Engagement Score',
      engagementDrivers: 'Engagement Drivers',
      topFiveAreas: 'Top 5 Areas',
    },
    team: {
      title: 'Team Insights',
      link: 'Go to Organization Insights',
      recognitionByProgram: 'Recognition by Event',
      engagementScore: 'Engagement Score',
      engagementDrivers: 'Engagement Drivers',
      topFiveAreas: 'Top 5 Areas',
      rewardBudget: "My Team's Reward Budget",
      recognition: 'Team Recognition',
      pulseSurveyResults: 'Pulse Survey Results',
      rewardBudgetProgressLabels: { spent: 'Spent', total: 'Total' },
      sparckTable: {
        titles: {
          myRecognitionReport: 'My Recognition Activity',
          myTeamRecognitionReport: "My Team's Recognition Activity",
        },
        picture: 'Picture',
        recognitionsSent: 'Recognitions Sent',
        rewardsSent: 'Rewards Sent',
        lastRecognitionSent: 'Last Recognition Sent',
        never: 'Never',
        action: 'Action',
        recognizeNow: 'Recognize Now',
        recognitionsReceived: 'Recognitions Received',
        recognitionReadiness: 'Recognition Readiness',
      },
    },
    filters: {
      labels: {
        period: 'Period',
        beheardSurvey: '#BeHeardSurvey',
        pulseSurveys: 'Pulse Surveys',
        managers: 'Managers',
        loadingText: 'Loading...',
      },
    },
    noData: 'No data to display.',
    managerSelectionError:
      'Make sure you selected a Manager in the filters above.',
    sparcksProgressLabels: { sent: 'Sent', goal: 'Goal' },
  },
  periodLabels: {
    allTime: 'All time',
    thisYear: 'This year',
    thisQuarter: 'This Quarter',
    thisMonth: 'This Month',
  },
  coreValues: {
    title: 'Core Values',
    headerDescription:
      'These are the key behaviors that will bring your culture to life! Learn more {link}.',
    descriptionCreateMode:
      'Let’s add a core value to your main newsfeed! Get started below. Check out an example {link}',
    example: 'Example',
    validations: { descriptionIsRequired: 'Description is required' },
  },
  externalRewardsUpload: {
    title: 'Upload Your External Reward Information',
    description:
      'External rewards are rewards provided to employees outside of the Sparck system. If you would like to track your external rewards as part of the Sparck budget you can use our Excel template to upload your external rewards. The template is dynamically generated specifically for your organization and includes your specific Recognition Events.',
    selectAppropriateEvents:
      'Please be sure to select the appropriate Recognition Events so we can track your budget accurately.',
    downloadExcelTemplate: 'Download Excel template',
  },
  inappropriateContent: {
    title: 'Inappropriate Content',
    description:
      'Below you will find all reported instances of inappropriate content and reported by your employees and different action you can take on each one.',
    contentAccepted: 'This content was accepted!',
    contentRemoved: 'This content was removed!',
    contentPrivate: 'This content is now private!',
    reporter: 'Reporter',
    contentCreator: 'Content Creator',
    hasInappropriateText: 'Has Inappropriate Japanese',
    hasInappropriateImage: 'Has Inappropriate Image',
    hasInappropriateVideo: 'Has Inappropriate Video',
    createdAt: 'Created At',
    goToPage: 'Go to Page',
    allowContent: 'Allow Content',
    removeContent: 'Remove Content',
    makeContentPrivate: 'Make Content Private (only for Sparcks)',
    thisContent: 'this content',
  },
  onboardingQuestions: {
    types: {
      choiceGroup: 'Choice Group',
      event: 'Event',
      rating: 'Rating',
      simpleSelect: 'Simple Select',
      multipleSelect: 'Multiple Select',
    },
    title: 'Onboarding Questions',
    description:
      'Your employees just entered Sparck. These are the questions they will see first in our Onboarding section!',
    questionStatement: 'Question Statement',
    type: 'Type of Question',
    displayToAllCompanies: 'Display this question to all organizations',
    alternatives: 'Alternatives',
    alternative: 'Alternative',
    actions: 'Actions',
    validations: {
      statementIsRequired: 'Question statement is required',
      typeIsRequired: 'Type of question is required',
    },
  },
  rewards: {
    title: 'Rewards',
    description:
      'A variety of reward types based on your unique culture. Get creative and have some fun!',
    fields: {
      rewardName: 'Reward Name',
      monetaryValue: 'Monetary Value',
      limitedQuantityReward: 'There is a limited quantity for this reward',
      inventoryQuantity: 'Inventory Quantity',
    },
    manageReward: {
      validations: {
        suggestionRequired: 'Suggestion is required',
        rewardNameRequired: 'Reward Name is required',
        monetaryValueRequired: 'Monetary Value is required',
        minimumInventoryQuantity:
          'Inventory Quantity must be greater than zero',
        maximumInventoryQuantity: 'Inventory Quantity must be lesser than 9999',
        inventoryQuantityRequired: 'Inventory Quantity is required',
      },
    },
  },
  profileQuestions: {
    title: 'Profile Questions',
    description:
      'They are personal and professional questions to include in your employees’ profiles to uncover how each person ticks.',
    questionsSelectedFromLibrary: 'Questions selected from library!',
    selectFromLibrary: 'Select from Questions Library',
    questions: 'Questions',
    fields: {
      statement: 'Statement',
      personal: 'Personal',
      professional: 'Professional',
    },
    validations: {
      statementRequired: 'Statement is required',
      questionTypeRequired: 'Question Type is required',
    },
  },
  recognitions: {
    title: 'Recognition Events',
    description:
      "It's an achievement and/or behavior you want to reinforce and recognize to elevate performance and your unique culture.",
    types: {
      customRecognitionProgram: 'Custom Recognition Event',
      workiversary: 'Workiversary',
      lifeEvent: 'Life Event',
      coreValues: 'Core Values',
      peerToPeer: 'Peer-to-Peer',
      spotBonus: 'Spot Bonus',
      peerNominatedAward: 'Peer Nominated Award',
      managerNominatedAward: 'Manager Nominated Award',
      employeeOfTheMonth: 'Employee of the Month',
      employeeOfTheYear: 'Employee of the Year',
    },
    frequencies: {
      monthly: 'Monthly',
      quarterly: 'Quarterly',
      semiAnnually: 'Semi-Annually',
      annually: 'Annually',
      lifetime: 'Lifetime',
    },
    approved: {
      directManager: 'Direct Manager',
      companyAdministrator: 'Organization Administrator',
    },
    milestones: {
      everyYear: 'Every Year',
      fixed: 'Fixed',
      customMilestone: 'Custom Milestone',
    },
    groups: {
      peers: 'Peers',
      manager: 'Manager',
      executiveTeam: 'Executive Team',
      ceo: 'CEO',
    },
    settings: {
      aPersonalNote: 'A Personal Note',
      aPersonalPhoneCall: 'A Personal Phone Call',
      aPersonalText: 'A Personal Text',
      inPerson: 'In Person',
      staffMeeting: 'Staff Meeting',
      companyEvent: 'Organization Event',
      groupEmail: 'Group Email',
      companyDashboard: 'Organization Dashboard',
      socialMediaShoutout: 'Social media shoutout',
    },
    attributes: {
      workiversaryYear: 'Workiversary Year',
      lifeEvent: 'Life Event',
      coreValue: 'Core Value',
    },
    icons: {
      anniversary: 'Anniversary',
      teamWork: 'Team Work',
      innovation: 'Innovation',
      strategic: 'Strategic',
      mentorTeach: 'Mentor/Teach',
      training: 'Training',
      creative: 'Creative',
      newHome: 'New Home',
      havingABaby: 'Having a Baby',
      graduation: 'Graduation',
      retirement: 'Retirement',
      technicalDevelopment: 'Technical Development',
      exceededGoals: 'Exceeded Goals',
      fun: 'Fun',
      celebration: 'Celebration',
      bugFix: 'Bug Fix',
      newFamilyMember: 'New Family Member',
    },
    welcome: {
      title: 'Welcome! Need our wizard help?',
      description:
        'You are here because you want to create the perfect experience for your employees with a custom recognition event. We offer a lot of basic and advanced configurations. Do you want us to guide you through this process?',
      dontAskAgain: "Don't ask me again",
      noThanks: 'No, thanks. I got this.',
      showMeTheRopes: 'Yes, show me the ropes, please!',
    },
    fields: {
      program: 'Event',
      programIcon: 'Event Icon',
      programIconPlaceholder: 'My Very Special Recognition',
      setCustomTimeframe: 'Set a Custom Timeframe',
      setPeriod: 'Set Period',
      startDate: 'Start Date',
      endDate: 'End Date',
      period: 'Period',
      howManyTimesPerPeriod: 'How many times per period per employee?',
      tooltips: {
        recurrenceLimit:
          'This is the maximum number of times this recognition event can be used per employee in the selected period.',
        recurrenceLimitPerEmployee:
          'What is the limit that a same employee can receive this recognition event?',
        maximumRewardAmountPerRecognition:
          'If there are multiple reward amounts, include the maximum budget per reward so you can reward up to that dollar amount.',
      },
      limitPerPeriodCheckbox:
        'Limit the number of employees who can be recognized in a single period',
      limitPerEmployee: 'Limit employees per period',
      allowRewards: 'Allow Rewards for this Recognition Event',
      allowOrganizationalRewards: 'Allow Organizational Rewards',
      allowRetailerRewards: 'Allow Retailer Rewards',
      canAcceptOnly: 'Can Accept Only',
      canAcceptToBankOnly: 'Can Accept to Bank Only',
      canAcceptAndBank: 'Can Accept and Bank',
      canAcceptOnlyTooltip:
        'Recipient of reward can only accept the reward. They can’t save the value of the reward to their Sparck bank.',
      canAcceptToBankOnlyTooltip:
        'Recipient of reward can only add the value of the reward to their Sparck bank.',
      canAcceptAndBankTooltip:
        'Recipient of reward can either accept the reward or add the value of the reward to their Sparck bank.',
      budgetPerReward: 'Budget per Reward',
      setMilestoneBonus: 'Set Milestone Bonus (per event occurrence)',
      setMilestoneBonusTooltip:
        'Make sure you selected Yearly availability to unlock Milestone settings',
      wayToGoTeam: 'Way to go, Team!',
      extraMile: 'When our team goes the extra mile',
      whoCanRecognize: 'Who can Recognize',
      whoCanBeRecognized: 'Who can be Recognized',
      hasReward: 'Offers Reward',
      rewardAmount: 'Reward Amount',
      timeframe: 'Timeframe',
      hasRequiresApprovalBy: 'Requires Approval?',
      requiresApprovalBy: 'Approval From',
      allowGroupRecognition: 'Allow Group Recognition',
      whoIsThisEventFor: 'Who is this Event for?',
      whichRolesCanSend: 'Which Roles can send Recognitions for this Event?',
      whichDemographicsCanReceive:
        'Which Demographics can receive Recognitions for this Event?',
    },
    groupRecognitionsBudgetImpact:
      "Group recognitions will not impact an individual recipient's budget.",
    groupRecognitionsBudgetImpactTooltip:
      "Group recognitions will not impact an individual recipient's budget. So if a recipient has $10 remaining in their budget for the period and receive a $50 group reward, they will still have $10 remaining in their individual budget for the period.",
    rewardConfigurations: 'REWARD CONFIGURATIONS',
    budgetImpact: 'BUDGET IMPACT',
    budgetPerReward: 'Budget Per Reward',
    frequency: 'Frequency',
    progressBarLabels: { used: 'Used', event: 'Event', remaining: 'Remaining' },
    employeesTargeted: 'Employees Targeted',
    total: 'TOTAL',
    year: 'year',
    tabLabels: {
      general: 'General',
      advanced: 'Advanced',
      tooltip: 'There are required fields for this section',
    },
    formSteps: {
      general: {
        title: 'General Info',
        description:
          'Set the Name of your Recognition Event, the Icon you want to associate with (visible in Recognition Posts in Feed, for example) and a description to better ilustrate why this recognition event is so awesome!',
      },
      whoIsThisEventFor: {
        title: 'Who is this Recognition Event for?',
        description:
          'Below you can select specific demographics for who this Recognition Event is for. If this is an organization wide event, you can simply click the next button without making any changes to this screen.',
      },
      whoCanRecognize: {
        title: 'Who can send Recognitions for this Event?',
        description:
          'Below you can select specific user roles that are allowed to send Recognitions for this event. If all roles are allowed to send recognitions for this event, you can leave the Roles field blank.  Otherwise, select specific roles and sending will be limited to only those roles.',
      },
      whoCanReward: {
        title: 'Who can Reward for this Event?',
        description:
          "Previously you let us know who is allowed to send recognitions for this event. Now we need to know what roles are allowed to reward for this event. Please note, you can't select a different role than was selected in the Who can send Recognitions for this event? screen. If you set Who can send Recognition… as everyone, then you can set Who can Reward... to everyone (by leaving the field blank) or to a specific role. However, if you set Who can send Recognition... to “Employees” then you can't set Who can Reward... to Managers because they aren't also in the Who can send Recognition... screen.",
      },
      timeframe: {
        title: 'Timeframe',
        description:
          'Is this a fixed recognition event or does it have a lifespan? Here it is possible to set a limited time for this recognition event to exist.',
      },
      availability: {
        title: 'Availability',
        description:
          'How many times should this recognition event be available per certain period? 10 times each month? 4 times quarterly? Once a year?',
      },
      nomination: {
        title: 'Nominations',
        description:
          'This screen allows you to set whether an employee should be nominated for this Recognition Event and what the nomination period will be.',
      },
      rewards: { title: 'Rewards', description: 'Rewards details' },
      milestones: { title: 'Milestones', description: 'Milestones details' },
      approval: {
        title: 'Approval Flow',
        description: 'Approval Flow details',
      },
    },
    next: 'Next',
    back: 'Back',
    finish: 'Finish',
    milestonesTable: {
      columns: { yearNumber: 'Year Number', rewardAmount: ' Reward Amount' },
    },
    validations: {
      iconIsRequired: 'Icon is required',
      startDateIsRequired: 'Start Date is required',
      minimumEndDate: 'End Date should not be before Start Date',
      recurrenceIsRequired: 'Recurrence is required',
      limitIsRequired: 'Limit is required',
      endDateIsRequired: 'End Date is required',
      budgetPerRewardIsRequired: 'Budget per Reward is required',
    },
    manageRecognitionsTitle: 'Recognition Events',
    whatSpecialEvent:
      'What special Recognition Event do you want to bring to your organization?',
    recognitionEvents: 'Recognition Events',
  },
  suggestions: {
    title: 'Suggestions',
    description:
      'Here are the opinions and contributions of your employees for your organization.',
    fields: {
      date: 'Date',
      from: 'From',
      suggestion: 'Suggestion',
      haveYouReplied: 'Have you replied?',
    },
    settings: { reviewed: 'Reviewed', implemented: 'Implemented' },
    manageSuggestion: 'Manage Suggestion',
    replyWasSent: 'Your reply was sent!',
    accept: 'Accept',
    decline: 'Decline',
    confirm: 'Confirm',
    suggestion: 'Suggestion',
    answer: 'Answer',
    typeReplyForSuggestion: 'Type your reply for this suggestion',
    sendReply: 'Send reply',
    moveForwardWithSuggestion:
      'Are you going to move forward with this suggestion?',
    areYouSure: 'Are you sure?',
    whereSuggestionChatBegins:
      'This is where this suggestion chat begins. The first message below is the suggestion.',
    typeYourReplyHere: 'Type your reply here...',
  },
  takeAction: {
    title: 'Take Action',
    description:
      "It's time to personalize your recognition! These people were recognized yet it would mean the most to hear from you. View recognition details and how to take action below.",
    filters: { open: 'Open', complete: 'Complete' },
    setAReminder: 'Employee Recognition Reminder',
    setReminder: 'Set a Reminder',
    fields: {
      whoWasRecognized: 'Who Was Recognized',
      byWhom: 'By Whom',
      recognitionEvent: 'Recognition Event',
      details: 'Details',
      viewMessage: 'View Message',
      recognizeThemThisWay: 'Recognize Them This Way',
      reminderDate: 'Reminder Date',
    },
    recognize: 'Recognize!',
    addReminder: 'Add Reminder',
    postComment: 'Post a Comment',
    markAsComplete: 'Mark as Complete',
    delete: 'Delete',
    snackbar: {
      success: {
        actionCompleted: 'This action was completed!',
        actionDisregarded: 'This action was disregarded',
      },
    },
  },
  budgets: {
    companyRewardBudgetBreakdown: {
      budgetBreakdownFilters: {
        businessUnit: 'Business Unit',
        employee: 'Employee',
        region: 'Region',
        territory: 'Territory',
      },
    },
  },
  budget: {
    budgetReports: {
      rewardsReport: {
        title: 'Rewards Report',
        description:
          'This report provides a listing of all rewards given to employees from the first of the year, the amount of the reward (value), and who they came from. Click the Download Report button below to download an Excel version of this report.',
      },
      budgetActivityReport: {
        title: 'Budget Activity Report',
        description:
          'This report identifies the budget amount spent and amount remaining for the current period for each employee in the organization. Click the Download Report button below to download an Excel version of this report.',
      },
      downloadReport: 'Download Report',
    },
    budgetDetails: {
      tabLabels: {
        general: 'General',
        externalRewardsUpload: 'External Rewards Upload',
        reports: 'Reports',
      },
    },
    budgetDetailsBody: {
      budgetSettings: '{limitType} Budget Settings',
      labels: {
        distributionType: 'Distribution Type',
        proRate: 'Prorate',
        rolloverPerPeriod: 'Rollover Per Period',
        rolloverAnnually: 'Rollover Annually',
        amountForEachEmployee: 'Amount for each employee',
      },
      breakdownBy: 'Breakdown by',
      noSettings: 'There are currently no settings for this distribution type.',
    },
    budgetDetailsHeader: {
      budgetSettings: 'Budget Settings',
      yearTypeHeaderCard: {
        title: 'Budget Calendar Type',
        description:
          'You can set the Budget Calendar to a traditional calendar (January - December) or a Fiscal Year calendar where you set the start date of your Fiscal year',
      },
      periodHeaderCard: {
        title: 'Period',
        description:
          'Your budget will restart at the beginning of each period.',
        period: 'Period',
      },
      budgetForNewEmployeesHeaderCard: {
        title: 'Budget for New Employees',
        description:
          'Dollar amount new employees will receive, if set to automatic.',
      },
      budgetType: 'Budget Type',
      amount: 'Amount',
    },
    manageBudgetDistributionDemographics: {
      breakdownBy: 'Breakdown by {selectedDemographic}',
      selectedDemographic: 'Demographic',
      labels: { distributeEvenly: 'Distribute Evenly', amount: 'Amount' },
      byDemographic: 'By Demographic',
      byEmployee: 'By Employee',
      formHelperText:
        'Use this section to automatically calculate amounts for the amount fields below',
    },
    manageBudgetDistributionFlat: {
      amountFieldLabel:
        'What amount do you want to allocate to each employee for a spending limit?',
    },
    manageBudgetDistributionIndividual: {
      amountFieldLabel: 'Amount',
      selectIndividuals: 'Select Individuals',
      dialogTitle: 'Select Individuals',
      noEmployeesFound:
        'No employees were found with the current search criteria.',
      labels: {
        nameOrTitle: 'Name/Title',
        demographics: 'Demographics',
        roles: 'Roles',
      },
      search: 'Search',
    },
    manageBudgetForm: {
      title: 'Welcome to the Budget Setup Screen',
      description:
        "Let's begin by determining how your organization will implement your recognition budget. You can choose a spending limit based budget, a receiving limit based budget or both!",
    },
    manageBudgetGeneralForm: {
      title: 'General Settings',
      labels: {
        yearType: 'Budget Calendar Type',
        startDate: 'Start Date',
        endDate: 'End Date',
        budgetPeriod: 'Budget Period',
        budgetTypeForNewEmployees: 'Budget Type for New Employees',
        amountPerNewEmployee: 'Amount per New Employee',
      },
      invalidDate: 'Invalid Date',
    },
    manageBudgetLimitTypes: {
      setRules: 'Set {title} rules',
      spendingLimitSection: {
        title: 'Spending Based Limit',
        description:
          'You can select which employees (all or just a few) have a budget to spend on organizational employees and how much that budget is.',
      },
      receivingLimitSection: {
        title: 'Receiving Based Limit',
        description:
          'You can select which employees (all or just a few) are allowed to receive rewards and what the total budget for those rewards are.',
      },
    },
    manageBudgetProrate: { prorateType: 'Prorate Type' },
    manageBudgetReceivingForm: { title: 'Receiving Rules' },
    manageBudgetRollover: {
      labels: {
        allowRolloverForUnusedRewardBudget:
          'Allow employees to roll-over unused reward budget from one period to the next in the same calendar or fiscal year.',
        allowRolloverFromOneYearToAnother:
          'Allow employees to roll-over from one budget year to the next.',
      },
    },
    manageBudgetSpendingForm: { title: 'Spending Rules' },
    manageBudget: {
      formHandlerTitle: 'Budget configuration',
      description: 'Budget description',
      budgetFormTitle: 'Budget',
      validations: {
        periodRequired: 'Period is required',
        startDate: {
          required: 'Start Date is required',
          invalidDate: 'Invalid Date',
        },
      },
    },
    budgetPeriods: {
      monthly: 'Monthly',
      quarterly: 'Quarterly',
      semiAnnually: 'Semi-annually',
      annually: 'Annually',
    },
    budgetProrateTypes: {
      noBudgetInPeriod: 'Employee receives no budget in current period',
      remainingFullMonths: 'Prorate based on remaining full months in period',
      remainingMonthsWithCurrent:
        'Prorate based on remaining months in period (including current)',
    },
    budgetDistributionTypes: {
      flat: {
        label: 'Flat',
        descriptionSpending:
          'All employees receive the same amount to spend on other employees.',
        descriptionReceiving:
          'All employees receive the exact same amount per period.',
      },
      individual: {
        label: 'Individual',
        descriptionSpending:
          'Set Spending Rules by Individuals (e.g., John Doe, Jane Doe)',
      },
      demographics: {
        label: 'Demographics',
        descriptionReceiving:
          'Set Receiving Rules by Demographics (e.g., Department, Location)',
      },
    },
    budgetNewEmployeesBudgetTypes: { automatic: 'Automatic', manual: 'Manual' },
    budgetYearTypes: { fiscal: 'Fiscal', calendar: 'Calendar' },
    updateBudgetSettings: 'Update Budget Settings',
    updateBudgetSettingsSnackbarSuccess:
      'Budget Settings have been successfully updated.',
  },
  companyReporting: {
    sectionDataTitles: {
      recognitionsSent: 'Recognitions Sent',
      unusedRewards: 'Unused Rewards',
      feedMessagesCount: 'Feed Messages Count',
      numberOfComments: 'Number of Comments',
    },
    reportSectionLabels: {
      topActiveUsers: 'Top Active Users',
      noRecognitionsPerPeriod: 'No Recognitions Per Period',
      financialReport: 'Financial Report',
    },
    formLabels: { section: 'Section' },
    filters: { period: 'Period' },
    financialReportColumnHeaders: {
      recognitionDate: 'Recognition Date',
      recipientName: 'Recipient Name',
      recognitionEvent: 'Recognition Event',
      rewardType: 'Reward Type',
      location: 'Location',
      giverName: 'Giver Name',
    },
    noRecognitionsByPeriod: {
      header: 'Users not recognized in this period:',
      columnHeaders: {
        role: 'Role',
        manager: 'Manager',
        allTimeRecognitions: 'All Time Recognitions',
        lastRecognition: 'Last Recognition',
      },
    },
    topActiveUsersColumnHeaders: {
      recognitionsSent: 'Recognitions Sent',
      messagesSent: 'Messages Sent',
      commentsPosted: 'Comments Posted',
      total: 'Total',
    },
  },
  sparckPreview: {
    title: 'This is what {userName} will see',
    theSparckedPerson: 'the Sparcked Person',
  },
  sparckReward: {
    fixed: 'Fixed',
    range: 'Range',
    options: '{name} options',
    giftCardMustBeWithin:
      'Gift Card must be within range and increment of {value}',
    giftCardCantBeLess: "Gift Card value can't be less than {value}",
    giftCardCantBeGreater: "Gift Card value can't be greater than {value}",
    selectGiftCardBetween:
      'Select a Gift Card amount between {minimumValue} and {maximumValue}',
    recognitionProgramMaxBudget:
      'The max. budget for this recognition event is {value}',
    confirmAmount: 'Confirm {amount}',
  },
  myRewards: {
    walletDescription:
      'When you reject a reward and bank it, you are putting the value of that reward into your wallet for later use. When you are ready to use those funds, simply click the Purchase Gift button below to view the rewards catalog and select the item you want to purchase. Remember you can select any amount up to the available balance of your wallet for your purchase. Have fun shopping!',
    wishlistDescription:
      "Like certain rewards more than others? Add them to your wishlist so they are easily accessible to you as well as people recognizing you. To add items to your wishlist, click the Add Items To My Wishlist button below, identify the items you like and click the heart icon. It's as simple as that!",
    tabLabels: {
      home: 'Home',
      myRewards: 'My Rewards',
      myPurchases: 'My Purchases',
    },
    youHaveNewRewards: 'You have {count} new rewards!',
    total: 'Total {name}',
    cardHeaders: {
      rewardsFrom: '{name} Rewards',
      availableToUse: 'available to use',
      myWallet: 'My Reward Bank',
      myWishlist: 'My Wishlist',
    },
    addMore: 'Add More',
    addItemsToWishlist: 'Add items to my wishlist',
    list: {
      title: 'My Rewards',
      visualizationModes: { cards: 'Cards', list: 'List' },
    },
    redeem: 'Redeem {name}',
    catalog: {
      title: 'Rewards Catalog',
      noItems: 'There are no',
      tabLabels: {
        rewards: "Organization's Rewards",
        rewardsNGC: 'Retailer Rewards',
      },
      makeWishListPublic:
        'Make my wishlist public (other users can see my wishlist)',
    },
    columns: {
      received: 'Received',
      details: 'Details',
      from: 'From',
      actions: 'Actions',
      bank: 'Bank',
      accept: 'Accept',
      clickToRedeem: 'Click to redeem this reward',
    },
    purchaseGift: 'Purchase Gift',
    myGiftCardPurchases: 'My Gift Cards Purchases',
    redeemYourGiftCard: 'Redeem your Gift Card',
    myRewardsPurchasesTable: {
      columnTitles: {
        giftCard: 'Gift Card',
        brand: 'Brand',
        amount: 'Amount',
        acceptedAt: 'Accepted At',
      },
      noPurchases: 'There are no purchased gift cards yet.',
    },
    snackbar: {
      success: {
        acceptedReward:
          'You have successfully accepted your reward.  Please check your email for instructions on collecting your reward.',
        bankedReward: 'You banked this reward!',
      },
    },
  },
  sparckAppreciationAssessment: {
    landing: {
      title: 'Sparck Appreciation Style Quiz',
      description:
        'We are so excited to work with you to uncover your primary appreciation style in the workplace. It takes about 60 seconds and you will get access to your results right away. This is just the beginning to better understand how you feel valued at work. Get started with just your email so we can send you your Sparck Style. The rest is optional. Have fun!',
    },
    questions: {
      title: 'Uncover Your Sparck Appreciation Style',
      description:
        'This quick quiz will help you determine your primary appreciation style in the workplace. Don’t overthink it, pick the option that fits you best. Let the fun begin!',
      questionTitle: 'It is more meaningful to me at work when...',
    },
    results: {
      title: 'Your Sparck Appreciation Style Results',
      sparckAppreciationStyle: 'Sparck Appreciation Style',
      checkYourEmail:
        'Check your email for the full results! Don’t forget to check your spam folder if you don’t see it.',
    },
    labels: {
      companyName: 'Organization Name',
      gender: 'Gender',
      generation: 'Generation',
      currentPosition: 'Current Position',
      email: 'Email (required)',
      getStarted: 'Get Started',
      preferredLanguage: 'Preferred Language',
      send: 'Show me my results!',
      contactInfo: "Let's get Started!",
      demographicInfo: 'Want to share more?',
      demographicInfoTooltip:
        'This information is completely confidential and only used for aggregated appreciation studies at Sparck',
    },
    currentPositions: {
      entryLevel: 'Entry Level',
      midLevel: 'Mid Level',
      seniorLevel: 'Senior Level',
      executive: 'Executive',
    },
    generations: {
      babyBoomer: 'Baby Boomer (Born 1946 - 1964)',
      generationX: 'Generation X (Born 1965 - 1980)',
      generationY: 'Generation Y (Born 1981 - 1996)',
      generationZ: 'Generation Z (Born 1997 - 2012)',
      generationAlpha: 'Generation Alpha (Born 2013 - 2025)',
    },
    validations: {
      requiredEmail: 'Email is required',
      validEmail: 'Enter a valid email',
    },
  },
}
