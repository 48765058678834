import { useState, useMemo, useCallback } from 'react'
import {
  Box,
  Button,
  Grid,
  Typography,
  Pagination,
  useTheme,
  TextField,
  MenuItem,
} from '@mui/material'
import { get } from 'lodash'
import { differenceInDays } from 'date-fns'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router'

import { UserAvatar } from 'components'
import { ROUTES_PATHS } from 'core/routes'

const ITEMS_PER_PAGE = 5

export default function TeamInsightsMobile({ insights, insightsMeta }) {
  const [page, setPage] = useState(1)
  const [sparcksType, setSparcksType] = useState('my_recognitions')
  const history = useHistory()
  const theme = useTheme()
  const intl = useIntl()

  const mySparcks = useMemo(
    () => get(insights, `recognitions.${sparcksType}`, []),
    [insights, sparcksType],
  )

  const paginatedData = useMemo(() => {
    const startIndex = (page - 1) * ITEMS_PER_PAGE
    return mySparcks.slice(startIndex, startIndex + ITEMS_PER_PAGE)
  }, [mySparcks, page])

  const handlePageChange = (_, value) => setPage(value)

  const MAX_NUMBER_OF_DAYS_UNTIL_RECOGNITION = useMemo(
    () =>
      insightsMeta?.recognition_frequency?.manager_level ??
      insightsMeta?.recognition_frequency?.company_level,
    [insightsMeta],
  )

  const userMustBeRecognizedUrgently = useCallback(
    data => {
      if (data?.last_recognition_sent_at) {
        return (
          differenceInDays(
            new Date(),
            new Date(data.last_recognition_sent_at),
          ) > MAX_NUMBER_OF_DAYS_UNTIL_RECOGNITION
        )
      }

      return (
        differenceInDays(
          new Date(),
          new Date(insightsMeta?.applications?.beseen?.created_at),
        ) > MAX_NUMBER_OF_DAYS_UNTIL_RECOGNITION
      )
    },
    [insightsMeta, MAX_NUMBER_OF_DAYS_UNTIL_RECOGNITION],
  )

  return (
    <Box sx={{ padding: 2 }}>
      <Box mb={4}>
        <TextField
          select
          id="sparcksType"
          variant="outlined"
          value={sparcksType}
          onChange={event => setSparcksType(event.target.value)}
          fullWidth
          sx={{ marginBottom: 2 }}
        >
          {[
            {
              id: 'my_recognitions',
              label: intl.formatMessage({
                id: 'insights.team.sparckTable.titles.myRecognitionReport',
              }),
            },
            {
              id: 'my_team_recognitions',
              label: intl.formatMessage({
                id: 'insights.team.sparckTable.titles.myTeamRecognitionReport',
              }),
            },
          ].map(type => (
            <MenuItem key={type.id} value={type.id}>
              {type.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      {paginatedData.length === 0 ? (
        <Typography
          variant="h6"
          color="textSecondary"
          align="center"
          sx={{ marginTop: 4 }}
        >
          {intl.formatMessage({ id: 'insights.team.noDataAvailable', defaultMessage: 'No records to display' })}
        </Typography>
      ) : (
        <>
          <Grid container spacing={3} sx={{ justifyContent: 'center', rowGap: 2, pl: 3 }}>
            {paginatedData.map((item, index) => (
              <Grid
                item
                key={index}
                sx={{
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: 2,
                  boxShadow: theme.shadows[1],
                  padding: 2,
                }}
              >
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <UserAvatar
                      userId={get(item, 'id')}
                      name={item.first_name}
                      src={get(item, 'avatar.url')}
                      size={45}
                    />
                  </Grid>

                  <Grid item xs>
                    <Grid container spacing={1}>
                      {sparcksType === 'my_recognitions' && (
                        <>
                          <Grid item xs={6}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              <Typography variant="body2" sx={{ fontSize: '0.75rem' }} fontWeight="bold">
                                {get(item, 'rewards_sent')}
                              </Typography>
                              <Typography variant="body2" sx={{ fontSize: '0.65rem' }} align="center">
                                {intl.formatMessage({
                                  id: 'insights.team.sparckTable.rewardsSent',
                                })}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              <Typography variant="body2" sx={{ fontSize: '0.75rem' }} fontWeight="bold">
                                {get(item, 'last_recognition_sent_at')
                                  ? intl.formatDate(new Date(item.last_recognition_sent_at))
                                  : intl.formatMessage({
                                    id: 'insights.team.sparckTable.never',
                                  })}
                              </Typography>
                              <Typography variant="body2" sx={{ fontSize: '0.65rem' }} align="center">
                                {intl.formatMessage({
                                  id: 'insights.team.sparckTable.lastRecognitionSent',
                                })}
                              </Typography>
                            </Box>
                          </Grid>
                        </>
                      )}

                      {sparcksType === 'my_team_recognitions' && (
                        <>
                          <Grid item xs={6}>
                            <Typography variant="body2" sx={{ fontSize: '0.75rem' }} fontWeight="bold" align="center">
                              {get(item, 'total_recognitions_received')}
                            </Typography>
                            <Typography variant="body2" sx={{ fontSize: '0.65rem' }} align="center">
                              {intl.formatMessage({
                                id: 'insights.team.sparckTable.recognitionsReceived',
                              })}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body2" sx={{ fontSize: '0.75rem' }} fontWeight="bold" align="center">
                              {get(item, 'relationship_strength')}%
                            </Typography>
                            <Typography variant="body2" sx={{ fontSize: '0.65rem' }} align="center">
                              {intl.formatMessage({
                                id: 'insights.team.sparckTable.recognitionReadiness',
                              })}
                            </Typography>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    key={index}
                    sx={{
                      width: '100%',
                      overflow: 'hidden',
                    }}
                  >
                    <Box sx={{ overflow: 'hidden' }}>
                      <Typography
                        fontWeight="bold"
                      >
                        {get(item, 'full_name')}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontSize: '0.75rem',
                          wordBreak: 'break-word',
                          lineHeight: '1.2rem',
                        }}
                      >
                        {get(item, 'email')}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>

                <Button
                  color={userMustBeRecognizedUrgently(item) ? 'error' : 'primary'}
                  size="small"
                  sx={{
                    m: 3,
                    backgroundColor: userMustBeRecognizedUrgently(item)
                      ? '#fce4ec'
                      : theme => theme.palette.primary.main,
                    width: '70%',
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: 5,
                    mx: 'auto',
                  }}
                  onClick={() =>
                    history.push(
                      `${ROUTES_PATHS.sparck}?user_to_recognize_id=${item.id}`,
                    )
                  }
                >
                  {intl.formatMessage({
                    id: 'insights.team.sparckTable.recognizeNow',
                  })}
                </Button>
              </Grid>
            ))}
          </Grid>

          <Box mt={4} display="flex" justifyContent="center">
            <Pagination
              count={Math.ceil(mySparcks.length / ITEMS_PER_PAGE)}
              page={page}
              onChange={handlePageChange}
              color="primary"
            />
          </Box>
        </>
      )}
    </Box>
  )
}
