import { useState } from 'react'
import { Grid, Paper, useMediaQuery, useTheme } from '@mui/material'
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined'

import {
  ProfileBackground,
  ProfileChangePicture,
  ProfileHeader,
  ProfileTabs,
} from '../../components'

import {
  PROFILE_BACKGROUND_HEIGHT_DESKTOP,
  PROFILE_BACKGROUND_HEIGHT_MOBILE,
} from 'constants/app'
import { FloatingButton } from 'components'
import { ROUTES_PATHS } from 'core/routes'

export default function ProfileComponent(props: any) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const [tab, setTab] = useState(props.tabIndex)
  const [editMode, setEditMode] = useState(false)

  const profileBackgroundHeight = isMobile
    ? PROFILE_BACKGROUND_HEIGHT_MOBILE
    : PROFILE_BACKGROUND_HEIGHT_DESKTOP

  return (
    <Grid container>
      <Grid item xs={12}>
        <ProfileBackground profileBackgroundHeight={profileBackgroundHeight} />
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          zIndex: 999,
          marginTop: `${profileBackgroundHeight - profileBackgroundHeight * 0.16
            }px`,
        }}
      >
        <Grid container>
          <Grid sx={{ display: { xs: 'block', sm: 'none' } }}>
            <FloatingButton
              icon={<TuneOutlinedIcon />}
              openModal={false}
              tooltip={'Update your Recognition Preferences'}
              redirectPath={ROUTES_PATHS.onboarding} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={11}
            lg={10}
            mx="auto"
            sx={{
              maxWidth: { xs: '100%', sm: '720px', md: '960px', lg: '1440px' },
            }}
          >
            <Paper
              elevation={tab === 0 ? 0 : 1}
              sx={{
                bgcolor: tab === 0 ? 'inherit' : 'background.paper',
              }}
            >
              <ProfileHeader
                {...props}
                editMode={editMode}
                setEditMode={setEditMode}
              />

              <Grid container>
                <Grid item xs={12}>
                  <ProfileTabs
                    {...props}
                    tab={tab}
                    setTab={setTab}
                    editMode={editMode}
                    setEditMode={setEditMode}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <ProfileChangePicture {...props} />
      </Grid>
    </Grid>
  )
}
