import { cyan } from '@mui/material/colors'
import { RedeemOutlined as RewardsIcon } from '@mui/icons-material'

import ManageReward from './manageReward/ManageReward'
import { CompanyAdminLayout } from 'components'
import { UserPermission } from 'enums/user'
import { getRewardDescription, getRewardName } from 'utils/reward'
import { useIntl } from 'react-intl'

export default function Rewards() {
  const intl = useIntl()

  return (
    <CompanyAdminLayout
      slug='rewards'
      title={intl.formatMessage({ id: 'rewards.title' })}
      description={intl.formatMessage({ id: 'rewards.description' })}
      color={cyan['A700']}
      requestUrl='rewards'
      customHeaderActions={[]}
      tableProps={{
        columns: [
          {
            title: intl.formatMessage({ id: 'reward' }),
            field: 'name',
            editable: 'never',
            render: getRewardName,
            customFilterAndSearch: (value, rowData) =>
              getRewardName(rowData)
                .toLowerCase()
                .includes(value.toLowerCase()),
          },
          {
            title: intl.formatMessage({
              id: 'description',
            }),
            field: 'description',
            editable: 'never',
            render: getRewardDescription,
          },
        ],
        hasStatusSwitch: true,
      }}
      isReward={true}
      managementPermission={UserPermission.ManageRewards}
      ManagementForm={ManageReward}
      IconHeader={RewardsIcon}
    />
  )
}
