import { useEffect } from 'react'
import { get } from 'lodash'
import { useSnackbar } from 'notistack'
import { useMutation, useQuery } from 'react-query'

import { PulseSurveysComponent } from './components'
import { usePulseSurveys } from 'contexts/PulseSurveysContext'
import { API } from 'core/requests'

export default function PulseSurveys({ preview }) {
  const { pulseSurveys, setPulseSurveys } = usePulseSurveys()!
  const { enqueueSnackbar } = useSnackbar()

  const { refetch: fetchPulseSurveys } = useQuery(
    'myPulseSurveys',
    () =>
      API.get('pulseSurveys', null, {
        queryParams: {
          my_surveys: true,
        },
      }),
    {
      onSuccess: (response: any) => {
        setPulseSurveys([...response])
      },
    },
  )

  const answerPulseSurveyMutation = useMutation((payload: any) =>
    API.update('answerPulseSurvey', payload),
  )

  const finishPulseSurveyMutation = useMutation(
    (payload: any) => API.update('finishPulseSurvey', payload),
    {
      onSuccess: (response: any) => {
        if (response.success && response.message) {
          enqueueSnackbar(response.message, { variant: 'success' })
        }
        fetchPulseSurveys()
      },
    },
  )

  useEffect(() => {
    fetchPulseSurveys()
  }, [fetchPulseSurveys])

  return (
    <PulseSurveysComponent
      pulseSurveys={
        preview
          ? [preview]
          : pulseSurveys.filter(
            survey => get(survey, 'participation.status') !== 'finished',
          )
      }
      finishPulseSurvey={finishPulseSurveyMutation.mutate}
      finishPulseSurveyStatus={finishPulseSurveyMutation.isLoading}
      answerPulseSurvey={answerPulseSurveyMutation.mutate}
      preview={preview}
    />
  )
}
