import { Box, Grid } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

import sparckLogo from 'assets/images/sparck-full-colored.png'
import { ROUTES_PATHS } from 'core/routes'

type Props = {
  isMobile?: boolean
}

export default function TopbarLogo({ isMobile }: Props) {
  return (
    <RouterLink to={ROUTES_PATHS.dashboard}>
      <Grid container alignItems="center" style={{ display: 'inherit' }}>
        <Box
          height={isMobile ? 35 : 40}
          component="img"
          src={sparckLogo}
          alt="Sparck logo"
        />
      </Grid>
    </RouterLink>
  )
}
