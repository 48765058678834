import { useQuery, UseQueryResult } from 'react-query'
import { API } from 'core/requests'

export const useFetchSuggestions = (): UseQueryResult<any[], Error> => {
  return useQuery('rewardsSuggestions', () =>
    API.get('suggestions', null, {
      queryParams: {
        types: 'reward',
      },
    }),
  )
}
