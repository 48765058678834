import { useState } from 'react'
import {
  AppBar,
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Grid,
  IconButton,
  useTheme,
} from '@mui/material'
import { CardGiftcardOutlined } from '@mui/icons-material'
import { Menu as MenuIcon } from '@mui/icons-material'
import { useBadgesCount, useCompany, useUser } from 'contexts'
import { MOBILE_NAVBAR_ROUTES, ROUTES_PATHS } from 'core/routes'
import { validateRoutePermission } from 'utils/app'
import { NavLink as RouterLink, useLocation } from 'react-router-dom'
import DrawerMenu from './DrawerMenu'

export default function MobileNavbar() {
  const theme = useTheme()
  const { user } = useUser()!
  const { badgesCount } = useBadgesCount()!
  const { company } = useCompany()!
  const location = useLocation()
  const [drawerOpen, setDrawerOpen] = useState(false)

  const mobileRoutes = MOBILE_NAVBAR_ROUTES(company)
  const routes = mobileRoutes.routes
    .flatMap((route) => {
      return [route]
    })
    .filter((route) => {
      return user && validateRoutePermission(user, route)
    })

  const toggleDrawer = (open) => {
    setDrawerOpen(open)
  }

  return (
    <>
      <Grid item sx={{ backgroundColor: 'background.default', padding: 0 }}>
        <AppBar
          position="fixed"
          sx={{
            top: 'auto',
            bottom: 0,
            boxShadow: '0px 0px 24px rgba(110, 120, 130, 0.25)',
            backgroundColor: 'background.default',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            padding: 0,
          }}
        >
          <BottomNavigation
            showLabels
            sx={{
              backgroundColor: theme.palette.background.default,
              boxShadow: 'none',
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              padding: 0,
              margin: 0,
              justifyContent: 'space-between',
            }}
          >
            <Box ml={2}>
              <IconButton onClick={() => toggleDrawer(!drawerOpen)}>
                <Badge
                  variant="dot"
                  color="error"
                  invisible={
                    !badgesCount ||
                    (badgesCount?.open_pulse_surveys === 0 && badgesCount?.open_beheard_surveys === 0)
                  }
                  sx={{
                    '& .MuiBadge-dot': {
                      width: 8,
                      height: 8,
                      borderRadius: '50%',
                      top: 0,
                      right: 0,
                      transform: 'translate(50%, -50%)',
                      position: 'absolute',
                    },
                  }}
                >
                  <MenuIcon />
                </Badge>
              </IconButton>
            </Box>
            {routes.map((route) => {
              const routePath =
                ROUTES_PATHS[route.id] !== undefined
                  ? ROUTES_PATHS[route.id]
                  : route.path;
              const isActive = location.pathname === routePath

              return (
                <Box
                  key={route.id}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: 1,
                  }}
                >
                  <BottomNavigationAction
                    sx={{
                      padding: 0,
                      minWidth: 'auto',
                      margin: 0,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                    icon={
                      <Box
                        sx={{
                          color: isActive
                            ? theme.palette.primary.main
                            : theme.palette.text.primary,
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Badge
                          badgeContent={route.badgeCount || 0}
                          color="error"
                          sx={{
                            '& .MuiBadge-badge': {
                              right: -8,
                              top: 8,
                            },
                          }}
                        >
                          <GetIcon routeId={route.id} routes={routes} />
                        </Badge>
                      </Box>
                    }
                    component={RouterLink}
                    to={ROUTES_PATHS[route.id] ?? route.path}
                  />
                </Box>
              )
            })}
          </BottomNavigation>
        </AppBar>
      </Grid>
      <DrawerMenu open={drawerOpen} onClose={() => toggleDrawer(false)} />
    </>
  )
}

const GetIcon = ({ routeId, routes }) => {
  if (routeId === 'myRewards') {
    return (
      <HighlightedIcon
        icon={<CardGiftcardOutlined sx={{ color: 'white', fontSize: 22 }} />}
      />
    )
  }

  const matchedRoute = routes.find(
    (route) =>
      route.id === routeId)
  if (!matchedRoute) return null

  const IconComponent = matchedRoute.Icon
  return IconComponent ? <IconComponent sx={{ fontSize: 22 }} /> : null
}

const HighlightedIcon = ({ icon }) => (
  <Box
    sx={{
      width: 40,
      height: 40,
      borderRadius: '50%',
      background: (theme) =>
        `linear-gradient(90deg, ${theme.palette.warning.main} 0%, ${theme.palette.secondary.dark} 100%)`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    {icon}
  </Box>
)
