export const adminNotifications = [
  {
    id: 1,
    name: 'welcome.welcomeCompany.title',
    description: 'welcome.welcomeCompany.description',
    endpoint: 'sendWelcomeNotification',
  },
]

export const companyNotifications = [
  {
    id: 1,
    name: 'welcome.welcomeAllEmployees.title',
    description: 'welcome.welcomeAllEmployees.description',
    endpoint: 'sendWelcomeNotificationToEmployees',
    getPayload: company => ({ id: company.id, only_not_invited_yet: false }),
  },
  {
    id: 2,
    name: 'welcome.welcomeNewEmployees.title',
    description: 'welcome.welcomeNewEmployees.description',
    endpoint: 'sendWelcomeNotificationToEmployees',
    getPayload: company => ({ id: company.id, only_not_invited_yet: true }),
  },
]

export const employeeNotifications = [
  {
    id: 1,
    name: 'welcome.welcomeEmployee.title',
    description: 'welcome.welcomeEmployee.description',
    endpoint: 'sendWelcomeNotificationToEmployee',
    getPayload: employee => ({ id: employee.id }),
  },
  // {
  //   id: 2,
  //   name: 'welcome.betaTeamInviteEmail.title',
  //   description: 'welcome.betaTeamInviteEmail.description',
  //   endpoint: 'sendBetaTeamInvite',
  //   getPayload: employee => ({ id: employee.id }),
  // },
  {
    id: 3,
    name: 'welcome.resendSurvey.title',
    description: 'welcome.resendSurvey.description',
    endpoint: 'resendSurveyToEmployee',
    getPayload: employee => ({ email: employee.email }),
  },
  {
    id: 4,
    name: 'welcome.resendPulseSurvey.title',
    description: 'welcome.resendPulseSurvey.description',
    endpoint: 'resendPulseSurveyToEmployee',
    getPayload: employee => ({ company_id: employee.id, email: employee.email }),
  },
]
